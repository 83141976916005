
pos.store.factory("noteService", function($http, session,globalData, globalFunctions){

	var addNote = function(comments,	noteDate,	noteType,	subject, callback){
		globalFunctions.v65http("/customer/index.cfm?method=notes.addNote",{comments:comments,
		noteDate:noteDate,
		noteType:noteType,
		subject:subject,
		customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, null, 0);
	}

	var deleteNote = function(noteID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=notes.deleteNote",{noteID:noteID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}


	var editNote = function(comments,	noteDate,	noteID,	noteType,	subject, callback){
		globalFunctions.v65http("/customer/index.cfm?method=notes.editNote",	{comments:comments,
		noteDate:noteDate,
		noteID:noteID,
		noteType:noteType,
		subject:subject,
		customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listNotes = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=notes.listNotes", {page:page, maxRows:maxRows,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var viewNote = function(noteID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=notes.viewNote",{noteID:noteID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	return{
		addNote:	addNote,
		deleteNote:	deleteNote,
		editNote:	editNote,
		listNotes:	listNotes,
		viewNote:	viewNote
	};
});