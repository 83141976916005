pos.customerManager.factory("customerProductService", function($http, session, globalData, globalFunctions){

	var listProducts = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=products.listProducts", {page:page, maxRows:maxRows,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 30000);
	}


	return{
		listProducts:	listProducts
	};
});