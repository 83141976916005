pos.customerManager.controller("customerManager", function($document, $scope, $rootScope, session, globalData, globalFunctions, orderService, loginService, $state, $animate, $location, customerService){
	$rootScope.currentManager = {};
	$rootScope.currentManager.sectionTitle = "";
	$rootScope.currentManager.subSectionTitle = "";
	$scope.enableSearch = false;


	//Logic
	loginService.aboutPOS(function(){
		$rootScope.$emit("notificationListen", true);
	});

	requestReceiptPrinters();

	//Functions
	$scope.currentPage = function(){
		return $state.current.controller;
	};

	$scope.$on('formLoading', function(event, data){
		$scope.loading = data;
	});
	
	$scope.flagAction = function(path){
		$location.path(path);
	}
});


pos.customerManager.controller("viewCustomer", function($scope, $rootScope, session, globalData, globalFunctions, orderService, loginService, $state, $animate){
	$scope.customer = session.customer;
});

