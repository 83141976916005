
pos.store.factory("customerManagerService", function($http, session, globalData, globalFunctions){
	
	var listSalesAssociates = function(callback){
		globalFunctions.v65http("/customer/index.cfm?method=customer.listSalesAssociates",{customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	};

	return{
		listSalesAssociates:	listSalesAssociates
	};
});