pos.factory("paymentService", function(session, globalData, globalFunctions, $timeout, $rootScope, Rollbar){
	
	var initiatePaymentRequest = function(amountTendered,paymentTerminalKey,transactionType,orderNumber,refundOrderNumber,callback){
		var timestamp = Date.now();
		globalFunctions.v65http("/index.cfm?method=paymentTerminals.initiatePaymentRequest", {amountTendered:amountTendered,paymentTerminalKey:paymentTerminalKey,transactionType:transactionType,orderNumber:orderNumber,refundOrderNumber:refundOrderNumber,ts:timestamp}, function(response){
			(callback)(response);
		}, null, 15000, 0, null, function(){
			Rollbar.error("Payment Service - error in initiatePaymentRequest");
			globalFunctions.alertMessage("modal","error","Error retrieving payment information from USAePay",null);
		});
	};
	
	var getPaymentStatus = function(paymentRequestKey,callback){
		var loadTime = 3000, //Load the data every 3 seconds
		loadPromise; //Pointer to the promise created by the Angular $timeout service
		const callLimit = 40;
		var numberOfCalls = 0;
		
		getData = function() {
			var timestamp = Date.now();
			globalFunctions.v65http("/index.cfm?method=paymentTerminals.getPaymentStatus", {paymentRequestKey:paymentRequestKey,ts:timestamp}, function(response){
				try {
					if(numberOfCalls === callLimit){
						Rollbar.debug("Payment Service Hit Polling Limit",response);
						cancelNextLoad();
						connectionErrorMessage();
					}
					numberOfCalls +=1;
					console.log(numberOfCalls);
					
					var transactionStatus = response.paymentStatusResponse.status;
					var pendingTransactionStatuses = ['sent to device', 'completing payment', 'sending to device', 'processing payment'];
					var knownTransactionStatuses = ['sent to device', 'completing payment', 'sending to device', 'processing payment','transaction complete','transaction canceled', 'transaction failed']
					
					// Log all unknown statuses to rollbar:
					if(knownTransactionStatuses.indexOf(transactionStatus) < 0) {
						var rollbarDebugInfo = {
							transactionStatus: transactionStatus,
							paymentResponse: response
						};
						Rollbar.debug("Payment Service - Unknown Transaction Status",rollbarDebugInfo);
					}
					
					if(pendingTransactionStatuses.indexOf(transactionStatus) >= 0){
						nextLoad();
					} else {
						cancelNextLoad();
						if(transactionStatus == 'transaction complete' && response.paymentStatusResponse.transaction.result != "Declined"){
							(callback)(response);
						} else {
							if(response.paymentStatusResponse.transaction.error == "emvConnectionError"){
								connectionErrorMessage();
							} else {
								Rollbar.debug("Payment Service - Transaction Error Called",response);
								globalFunctions.alertMessage("modal","error",response.paymentStatusResponse.transaction.error,null);
							}
						}
					}
				} catch (error) {
					var rollbarDebugInfo = {
						response: response,
						error: error.message
					};
					Rollbar.error("Payment Service - error in getPaymentStatus",rollbarDebugInfo);
					connectionErrorMessage();
				}
			}, null, 15000, 3, null, function(){
				connectionErrorMessage();
			})
		};
	
		cancelNextLoad = function() {
			$timeout.cancel(loadPromise);
		};
	
		nextLoad = function() {
			//Always make sure the last timeout is cleared before starting a new one
			cancelNextLoad();
			loadPromise = $timeout(getData, loadTime);
		};
	
		// Start polling the data from the server
		getData();
		
		// Kill this process when it is no longer needed (e.g. modal closed)
		$rootScope.$on('cancelTimer', function(event, data){
			cancelNextLoad();
		});
	};
	
	var connectionErrorMessage = function(){
		Rollbar.debug("Payment Service - Connection Warning Called");
		globalFunctions.alertMessage("modal","warning","We’re having trouble connecting to the terminal. Click 'Retry' to check on the payment status.",null);
	}
	
	var cancelPaymentRequest = function(paymentRequestID,callback){
		globalFunctions.v65http("index.cfm?method=paymentTerminals.cancelPaymentRequest", {paymentRequestID:paymentRequestID}, function(response){
			(callback)(response)
		}, null, null, 0);
	}

	return{
		initiatePaymentRequest:initiatePaymentRequest,
		getPaymentStatus:getPaymentStatus,
		cancelPaymentRequest:cancelPaymentRequest
	};
});