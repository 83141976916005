
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length,c.length);
    }
    return "";
}

var deviceType = getCookie("DEVICETYPE");


function barCodeScan(scanData){
	//When barcode is scanned, this is called.
	angular.element(document).scope().$emit('cartLoading', true);
	angular.element(document).injector().get('cartService').addScanToCart(scanData, function(response){
		if(response.scanResult == "MultiProduct"){
			angular.element(document).scope().$emit('modalOpen',  { template:"multiProduct", modalData:response.products});
		}
		angular.element(document).injector().get('checkoutService').listPaymentTypes(function(response){
			angular.element(document).scope().$emit('cartLoading', false);
		})
	});
}

function cardSwipe(cardSwipe){
	// test = angular.element(document).scope();
	// console.log(test.driversLicense);

	switch(swipeAction){
		case "creditCard":
			angular.element(document).scope().$emit('chargeLoading', true);
			amountTendered = angular.element($(".chargeCol")).scope().getAmountTendered();
			angular.element(document).injector().get('checkoutService').swipeCreditCard(amountTendered, cardSwipe, function(response){
				angular.element(document).scope().$emit('modalOpen',{
					template:response.nextModal,
					modalData:{
						amountTendered:amountTendered,
						orderTenderID:response.orderTenderID
					}
				},
				{
					amountTendered:amountTendered
				});
				angular.element(document).scope().$emit('chargeLoading', false);
			});
		break;

		case "creditCardForm":
			var currentForm = angular.element("[name=formElements]").parent().scope();
			currentForm.$emit('modalLoading', true);
			angular.element(document).injector().get('creditCardService').swipeCreditCard(cardSwipe, function(response){
				currentForm.$emit('modalLoading', false);
				currentForm.formData.cardNumber = response.creditCard.cardNumber;
				currentForm.formData.cardExpiryMo = response.creditCard.cardExpiryMo;
				currentForm.formData.cardExpiryYr = response.creditCard.cardExpiryYr;
				currentForm.formData.nameOnCard  = response.creditCard.nameOnCard;
			});
			swipeOn("creditCardForm");
		break;

		case "driversLicense":
			var currentForm = angular.element("[name=formElements]").parent().scope();
			currentForm.$emit('modalLoading', true);
			angular.element(document).injector().get('customerService').swipeDriversLicense(cardSwipe, function(response){
				currentForm.$emit('modalLoading', false);
				currentForm.formData.address = response.driversLicense.address;
				currentForm.formData.city = response.driversLicense.city;
				currentForm.formData.firstName = response.driversLicense.firstName;
				currentForm.formData.lastName  = response.driversLicense.lastName;
				currentForm.formData.stateCode = response.driversLicense.stateCode;
				currentForm.formData.zipCode = response.driversLicense.zipCode;
				currentForm.toBeFilled = 1;
			});
			swipeOn("driversLicense");
		break;
		
		
		case "giftCard":
			var scope = angular.element($("[ng-controller=giftCard]")).scope(),
			amountTendered = angular.element($(".chargeCol")).scope().getAmountTendered();
			
			scope.modalActionDefaults(scope.formData);
			
			scope.$emit('modalLoading',true);
			angular.element(document).injector().get('checkoutService').swipeGiftCard(amountTendered, cardSwipe, function(response){
				scope.$emit('modalLoading',false);
				scope.amountDueCents = window.location.href.includes('refundOrder') ? response.refund.refundHeader.amountDueCents : response.order.orderHeader.amountDueCents;
				if(Number(scope.amountDueCents) == 0){
					scope.openModal(response.nextModal,{orderTenderID:response.orderTenderID,amountTendered:scope.modalData.amountTendered});
				}else{
					angular.element(document).scope().$emit('modalError', {
						alertLevel:response.swipeGiftCard.alertLevel,
						alertMessage:response.swipeGiftCard.alertMessage
					})
					scope.closeModal();
				}
			});
			swipeOn("giftCard");
		break;
	}
}

function cashDrawerOpened(){
	//Callback when cash drawer is opened.
}

function cardIOScanCompleted(cardData){

	var cardData = {
		cardNumber:cardData.card,
		cardExpiryMo:cardData.expiryMonth,
		cardExpiryYr:cardData.expiryYear,
		cvv2:cardData.cvv
	},
  	amountTendered = angular.element(document.getElementsByClassName('chargeCol')).scope().getAmountTendered();

	angular.element(document).scope().$emit('chargeLoading', true);
	angular.element(document).injector().get('checkoutService').cameraCaptureCard(cardData.cardNumber,cardData.cardExpiryMo,cardData.cardExpiryYr,cardData.cvv, function(response){
		angular.element(document).scope().$emit('modalOpen',  { template:response.nextModal,modalData:{orderTenderID:response.orderTenderID,amountTendered:amountTendered}});
		angular.element(document).scope().$emit('chargeLoading', false);
	});
}

// VIVO PAY integration - Start
function toggleVivoPayInEmulateMode()
{
	angular.element($("[ng-controller=charge]")).scope().toggleVivoPayInEmulateMode();
}

// Indicating when the device is connected
function deviceConnected(value){
	console.log('Vivo Pay Connected', value.deviceName)
	angular.element(document.getElementsByClassName('vivoClass')).scope().deviceName = value.deviceName;
	angular.element(document.getElementsByClassName('vivoClass')).scope().inactive = true;
	angular.element(document.getElementsByClassName('vivoClass')).scope().processingChip = false;
	// Validation to avoid chip transaction when the device is power on in other moment of the transaction
	if (angular.element(document.getElementsByClassName('vivoClass')).scope().modalOpen === true) {
		angular.element(document.getElementsByClassName('vivoClass')).scope().chipTransaction();
	}
}
// Indicating when the device is disconnected
function deviceDisconnected(value){
	console.log('Vivo Pay Disconnected', value.deviceName)
	if(angular.element(document.getElementsByClassName('vivoClass')).scope().deviceName) {
		angular.element(document.getElementsByClassName('vivoClass')).scope().deviceName = "";
	}
	angular.element(document.getElementsByClassName('vivoClass')).scope().inactive = true;
	angular.element(document.getElementsByClassName('vivoClass')).scope().processingChip = false;
}
// Indicating messages that are coming from device
function deviceMessage(value){
	// Showing messages in modal
	if(angular.element(document.getElementsByClassName('vivoClass')).scope().deviceMessage) {
		angular.element(document.getElementsByClassName('vivoClass')).scope().deviceMessage = value.deviceMessage;
	} else {
		angular.element(document.getElementsByClassName('vivoClass')).scope().deviceMessage = "Please wait ...";
	}
	// Handling warning and errors
	var scopeCharge = angular.element($("[ng-controller=charge]")).scope();

	if (value.deviceMessage.toUpperCase().indexOf('DECLINED') !== -1 && scopeCharge.alertMessage === '') {
		scopeCharge.alertLevel = "error";
		scopeCharge.alertMessage = value.deviceMessage;
	} else if (value.deviceMessage.toUpperCase().indexOf('TIMED OUT') !== -1 && scopeCharge.alertMessage === '') {
		scopeCharge.alertLevel = "warning";
		scopeCharge.alertMessage = value.deviceMessage;
	} else if (value.deviceMessage.toUpperCase().indexOf('NO DEVICE FOUND') !== -1 && scopeCharge.alertMessage === '') {
		scopeCharge.alertLevel = "warning";
		scopeCharge.alertMessage = 'EMV Reader not found. Please make sure that the EMV device you have selected from Device Settings matches the one you are trying to use';
	} else if (value.deviceMessage.toUpperCase().indexOf('TRANSACTION FAILED') !== -1 && scopeCharge.alertMessage === '') {
		scopeCharge.alertLevel = "warning";
		scopeCharge.alertMessage = 'Please do not remove the card until the device beeps and the transaction is completed';
	} else if (value.deviceMessage.toUpperCase().indexOf('CONNECTION FAILED') !== -1 && scopeCharge.alertMessage === '') {
		scopeCharge.alertLevel = "warning";
		scopeCharge.alertMessage = 'Please make sure that the EMV device is turned on and make sure that the EMV device you have selected from Device Settings matches the one you are trying to use';
	} else if (value.deviceMessage.toUpperCase().indexOf('UNSUPPORTED') !== -1 && scopeCharge.alertMessage === '') {
		scopeCharge.alertLevel = "error";
		scopeCharge.alertMessage = value.deviceMessage;
	} else if (value.deviceMessage.toUpperCase().indexOf('TRANSACTION FAILED') !== -1 && scopeCharge.alertMessage === '') {
		scopeCharge.alertLevel = "error";
		scopeCharge.alertMessage = value.deviceMessage;
	} else if (value.deviceMessage.toUpperCase().indexOf('TRANSACTION CANCELLED') !== -1 && scopeCharge.alertMessage === '') {
		scopeCharge.alertLevel = "warning";
		scopeCharge.alertMessage = value.deviceMessage;
	}

	if (scopeCharge.alertLevel == 'warning' || scopeCharge.alertLevel == 'error') {
		setTimeout(cancelVivoTerminal(value.deviceMessage), 1500);
	}
}

// Indicating logs that are coming from device
function deviceOutputLogs(value) {
	if (value.deviceOutput) {
		console.log('Vivo Pay Output Log', value.deviceOutput)
	}
}

// Scan VivoPay Terminal
function scanVivoTerminal(data){
	console.log('Starting Scan ....')
	console.log(data);
	if (angular.element($("[ng-controller=charge]")).scope().vivoPayInEmulateMode) {
		deviceConnected({deviceName: "to BT Reader"});
	} else {
		location.href="vin65://beginVivoIOSScan?"+data;
	}
}
// Send data to VivoPay Terminal
function processVivoTerminal(data){
	console.log('Starting Chip ....')
	console.log(data);

	// setTimeout(5*1000);
	// return deviceMessage({deviceMessage: "DECLINED"});

	if (angular.element($("[ng-controller=charge]")).scope().vivoPayInEmulateMode) {

		// angular.element(document).injector().get('$rootScope').$on('$stateChangeStart', function (event, toState, toParams) {
		// 	console.log('initialized stopper');
		// 	angular.forEach($http.pendingRequests, function (request) {
		// 		console.log('found request');
		// 		console.log(request);
		// 		$http.abort(request);
		// 	});
		// });

		angular.element(document).injector().get('checkoutService').payWithVivoTerminalBeginChipEmulate(
			data,
			function(response){
				vivoTerminalCompleted(response.apiResult);
			}
		);
	} else {
		location.href="vin65://beginVivoIOSChip?"+data;
	}
}

// Cancel VivoPay Terminal
function cancelVivoTerminal(message, skipCloseModal){

	if (typeof message == 'undefined') message = "Canceled from VivoPay Terminal";
	var transactionData = { errors: { general: [message] } };
	vivoTerminalCompleted(transactionData);

	if (typeof skipCloseModal != "undefined" && skipCloseModal == false){
		var scopeVivo = angular.element($("[ng-controller=payWithVivo]")).scope();
		scopeVivo.closeModal();
	}

	location.href="vin65://beginVivoIOSCancel";
}
// Handle response from VivoPay Terminal
function vivoTerminalCompleted(transactionData){

	if (typeof transactionData.transaction == "undefined") {
		var scope = angular.element($("[ng-controller=charge]")).scope();
		var scopeVivo = angular.element($("[ng-controller=payWithVivo]")).scope();

		if (Object.values(transactionData.errors)[0]) {
			scope.alertLevel = "error";

			if (Object.values(transactionData.errors)[0][0] && Object.values(transactionData.errors)[0][0].indexOf('transaction_api_id') !== -1) {
				transactionData.message ='Transaction has already been processed at the payment gateway';

				angular.element(document).injector().get('checkoutService').payWithVivoTerminalAlreadyProcessed(
					{orderTenderID: scopeVivo.orderTenderID, orderID: scopeVivo.orderID},
					function(response){
						if (response.payWithVivoTerminalAlreadyProcessed.isSuccessful) {
							vivoTerminalCompleted(response.apiResult);
							return;
						}
					}
				);
				return;
			} else {
				transactionData.message = Object.values(transactionData.errors)[0][0];
			}
		}
		scope.alertMessage = "Please, re attempt in a few minutes or try with other payment method.";
		scope.hasErrorDetails = true;
		scope.alertMessageDetails = transactionData.message;

		transactionData.successful = false;
		if (transactionData.transaction_api_id != scopeVivo.orderTenderID) {
			transactionData.transaction_api_id = scopeVivo.orderTenderID;
		}

		return handleVivoPayResponse(transactionData);
	}

	var apiResults = transactionData.transaction;
	apiResults.successful = false;

	if (apiResults.status_id < 300) {
		apiResults.successful = true;
	}
	return handleVivoPayResponse(apiResults);
}

// Handle success scenario
function handleVivoPayResponse(apiResults) {
	var amountTendered = angular.element($(".chargeCol")).scope().getAmountTendered();
	var scopeVivo = angular.element($("[ng-controller=payWithVivo]")).scope();
	scopeVivo.$emit('modalLoading',true);

	angular.element(document).injector().get('checkoutService').payWithVivoTerminalComplete(
		apiResults,
		scopeVivo.amountDue,
		scopeVivo.tip,
		scopeVivo.orderTenderID,
		function(response){
		scopeVivo.updateVivoTerminal();

		if (apiResults.successful == false) {
			handleVivoPayError(apiResults);
		}

		if (response.nextModal) {
			scopeVivo.openModal(response.nextModal, {
				orderTenderID: response.orderTenderID,
				amountTendered: amountTendered,
				authCode: response.authCode,
				cardNumber: response.cardNumber,
				cardType: response.cardType,
			});
		}
		scopeVivo.closeModal();
	});
}
// Handle error scenario
function handleVivoPayError(apiResults) {
	// Close modal and display decline error on charge screen
	var reason = typeof apiResults.message != 'undefined' ? apiResults.message : 'Unknown reason';
	if (typeof apiResults.reason_code_id != "undefined") {
		reason = reasonCodeMap(apiResults.reason_code_id);
	} else {
		reason = apiResults.message;
	}
	var scope = angular.element($("[ng-controller=charge]")).scope();
	scope.alertLevel = "error";
	scope.alertMessage = reason;
}
// Reason codes mappings
function reasonCodeMap(code) {
	const reasonCodes = new Map(
	[
		["0","N/A"],
		["1000","CC - Approved"],
		["1001","AuthCompleted"],
		["1002","Forced"],
		["1003","AuthOnly Declined"],
		["1004","Validation Failure (System Run Trx)"],
		["1005","Processor Response Invalid"],
		["1200","Voided"],
		["1201","Partial Approval"],
		["1240","Approved, optional fields are missing (Paya ACH only)"],
		["1500","Generic Decline"],
		["1510","Call"],
		["1518","Transaction Not Permitted - Terminal"],
		["1520","Pickup Card"],
		["1530","Retry Trx"],
		["1531","Communication Error"],
		["1540","Setup Issue, contact Support"],
		["1541","Device is not signature capable"],
		["1588","Data could not be de-tokenized"],
		["1599","Other Reason"],
		["1601","Generic Decline"],
		["1602","Call"],
		["1603","No Reply"],
		["1604","Pickup Card - No Fraud"],
		["1605","Pickup Card - Fraud"],
		["1606","Pickup Card - Lost"],
		["1607","Pickup Card - Stolen"],
		["1608","Account Error"],
		["1609","Already Reversed"],
		["1610","Bad PIN"],
		["1611","Cashback Exceeded"],
		["1612","Cashback Not Available"],
		["1613","CID Error"],
		["1614","Date Error"],
		["1615","Do Not Honor"],
		["1616","NSF"],
		["1617","Exceeded Withdrawal Limit"],
		["1618","Invalid Service Code"],
		["1619","Exceeded activity limit"],
		["1620","Violation"],
		["1621","Encryption Error"],
		["1622","Card Expired"],
		["1623","Renter"],
		["1624","Security Violation"],
		["1625","Card Not Permitted"],
		["1626","Trans Not Permitted"],
		["1627","System Error"],
		["1628","Bad Merchant ID"],
		["1629","Duplicate Batch (Already Closed)"],
		["1630","Batch Rejected"],
		["1631","Account Closed"],
		["1640","Required fields are missing (ACH only)"],
		["1641","Previously declined transaction (1640)"],
		["1650","Contact Support"],
		["1651","Max Sending - Throttle Limit Hit (ACH only)"],
		["1652","Max Attempts Exceeded"],
		["1653","Contact Support"],
		["1654","Voided - Online Reversal Failed"],
		["1655","Decline (AVS Auto Reversal)"],
		["1656","Decline (CVV Auto Reversal)"],
		["1657","Decline (Partial Auth Auto Reversal)"],
		["1658","Expired Authorization"],
		["1659","Declined - Partial Approval not Supported"],
		["1660","Bank Account Error, please delete and re-add Account Vault"],
		["1661","Declined AuthIncrement"],
		["1662","Auto Reversal - Processor can't settle"],
		["1663","Manager Needed (Needs override transaction)"],
		["1664","Account Vault Not Found: Sharing Group Unavailable"],
		["1665","Contact Not Found: Sharing Group Unavailable"],
		["1701","Chip Reject"],
		["1800","Incorrect CVV"],
		["1801","Duplicate Transaction"],
		["1802","MID/TID Not Registered"],
		["1803","Stop Recurring"],
		["1804","No Transactions in Batch"],
		["1805","Batch Does Not Exist"]
	]);
	return reasonCodes.get(code.toString()) ? 'The transaction was not successful. ' + code + ' ' + reasonCodes.get(code.toString()) : "Unknown Reason Code " + code;
}
// VIVO PAY integration - END

function cardIOScanCancelled(){
	// Called when the user cancels the card io box
}

function onReceiveReceiptPrinters(printers){
	//Fired When looking for printers.
	// var printers =[
	// 	{"model_name":"TSP143 (STR_T-001)","mac_address":"00:11:62:07:17:a3","port_name":"TCP:192.168.1.83","index":0},
	// 	{"model_name":"TSP650","mac_address":"00:12:22:03:15:a4","port_name":"TCP:192.168.1.83","index":1}
	// ]	

	if(deviceType != "Desktop"){
		if(printers){
			printers.push({"model_name":"Beta Receipt Print","mac_address":"Air Print","port_name":"TCP:000.000.000.001","index":999});
		}else{
			printers = [{"model_name":"Beta Receipt Print","mac_address":"Air Print","port_name":"TCP:000.000.000.001","index":999}];
		}
	}

	//Change Printer Naming conventions
	var i;
	for(i = 0; i < printers.length; i++){
	    printers[i].modelName = printers[i]['model_name'];
	    printers[i].macAddress = printers[i]['mac_address'];
	    delete printers[i].model_name;
	    delete printers[i].mac_address;
	}

	angular.element(document).injector().get('settingsService').printersInit(printers);
}

function statusMessage(message){
	console.log(message);

	switch (message){
		case "cardProgressProcessing":
			// angular.element(document).scope().$emit('chargeLoading', true);
		break;

	}
}

function setPushToken(data){
	console.log(data);
}

function swipeOn(action){
	swipeAction = action;
	setTimeout(function(){
		beginSwipe();
	},1);
}	

function swipeOff(){
	// console.log(appMode)
	setTimeout(function(){
		cancelSwipe();
	},1);
}

function requestReceiptPrinters(callback){
	console.log("Receipt Printers Requested");

	setTimeout(function(){
		requestReceiptPrintersURL();
	},1000);


	setTimeout(function(){
		if(callback){
			(callback)();
		}
	},1200);
}

// Check if a new cache is available on page load.
window.addEventListener('load', function(e) {
	window.applicationCache.addEventListener('updateready', function(e) {
		if (window.applicationCache.status == window.applicationCache.UPDATEREADY) {
			// Browser downloaded a new app cache.
			localStorage.setItem('ngStorage-manifestUpdate',1);
		} else {
			// Manifest didn't changed. Nothing new to server.
		}
	}, false);
}, false);


function openCashDrawerApp(index) {
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().openCashDrawer();
	}else{
		location.href="vin65://openCashDrawer"+index;
	}
}

function appRestart(){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().restart();
	}else{
		location.href="vin65://restart";
	}
}

function beginSwipe(){
	if(deviceType == "Desktop"){ 
		angular.element(".desktopActions").scope().beginSwipe();
	}else{
		location.href="vin65://beginSwipe";
	}
}

function cancelSwipe(){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().cancelSwipe();
	}else{
		location.href="vin65://cancelSwipe";
	}
}

function requestReceiptPrintersURL(){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().requestReceiptPrinters();
		printers =[
				{"model_name":"Receipt Printer","mac_address":"Desktop","port_name":"TCP:000.000.000.000","index":0},
			];
		onReceiveReceiptPrinters(printers);
	}else{
		location.href="vin65://requestReceiptPrinters";
	}
}

function rotateLandscape(){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().rotateLandscape();
	}else{
		location.href="vin65://rotateLandscape";
	}
}

function rotatePortrait(){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().rotatePortrait();
	}else{
		location.href="vin65://rotatePortrait";
	}
}


function printPDF(url){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().printPDF(url);
	}else{
		location.href="printpdf://"+url+"&secure=1";
	}
}

function starPrint(url, index){
	console.log(url);
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().printPDF(url);
	}else{
		if(index == 999){
			location.href="printpdf://"+url+"&secure=1";
		}else{
			if(window.location.protocol != "https:"){
				location.href="starprint"+index+"://"+url;
			}else{
				location.href="starprint"+index+"://"+url+"&secure=1";
			}
		}
	}
}


function startBarcodeScan(){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().startBarcodeScan();
	}else{

	}	
}

function restart(){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().restart();
	}else{
		location.href="vin65://restart";
	}
}

function openSafari(url){
	if(deviceType == "Desktop"){
		angular.element(".desktopActions").scope().openSafari(url);
	}else{
		location.href="openSafari://"+url;
	}
}
