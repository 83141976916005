pos.controller("login", function($scope, $rootScope, $location, $localStorage, $timeout, $cookies, globalData, globalFunctions, loginService, auth0Service, $stateParams, pinSessionService){

	//Variables
	$scope.loading = false;
	$scope.masterAdminForm = false;
	$scope.pinViewState = 'regularPinLoginCheck';
	$scope.pinLengthPlaceholder = 4;
	$scope.storage = $localStorage;
	$scope.websites;
	$scope.cookies = $cookies;
	$scope.formData = {};
	$scope.k1Admin = 0;
	$scope.setupPinInfo = {};
	
	//Logic
	
	//Check CF params for formData
	if($stateParams['cfAttrs']){
		$timeout(function () {
			$scope.loading = true;
			var loginData = JSON.parse(window.atob($stateParams['cfAttrs']));
			loginService.decryptLoginData(loginData, function(response){
				$scope.loginCheck(response.decryptLoginData.loginData);
			});
		},100);
	}

	//Listeners

	$rootScope.$on('loginNotice', function(event, data){
		$scope.loginNotice = {};
		$scope.loginNotice.active = true;
		$scope.loginNotice.alertMessage = data.alertMessage;
		$scope.loginNotice.alertLevel = data.alertLevel;
		$scope.loading = false;
	});
	
	$rootScope.$on('loginLoading', function(event, data){
		$scope.loading = data;
	});

	//Functions
	$scope.init = function() {
		if(pinSessionService.cookieExists('posPinWebsiteID')){
			var websiteID = pinSessionService.getCookie('posPinWebsiteID');
			loginService.pinsEnabledForWebsite(websiteID, function(response){
				if(!response.websiteHasPinsTurnedOn){
					pinSessionService.deleteSessionCookies();
				}
			});
		}
		if(auth0IsEnabled() && typeof auth0.authToken !== 'undefined'){
			$scope.loginCheck({authToken: auth0.authToken});
		}
	};
	
	$scope.logoURLPath = function() {
		if(vin65.features.rebranding){
			return "/v2/assets/images/winedirect-logo.png";
		}
		return "/v2/assets/images/logo2.png";
	};
	
	$scope.logoClass = function() {
		if(vin65.features.rebranding){
			return "winedirect-logo";
		}
		return "logo";
	};
	
	$scope.pinLoginEnabled = function() {
		return pinSessionService.pinsEnabled();
	};
	
	$scope.pinWebsiteName = function() {
		return pinSessionService.getCookie('posPinWebsiteName');
	};
	
	$scope.getPinViewState = function(){
		return $scope.pinViewState;
	};
	
	$scope.setupPin = function() {
		$scope.setPinViewState('setupNewPinLoginCheck');
	};

	$scope.resetPinLogin = function() {		
		$scope.loginNotice = {};
		$scope.setupPinInfo = {};
		pinSessionService.deleteSessionCookies();
		$scope.toggle('masterAdminForm', false);
		delete $scope.cookies.V65USER;
		$rootScope.sendGA('POS PINs', 'Change Store', 'Click');
		if(auth0IsEnabled()){
			$scope.logout();
		}
	};
	
	$scope.regions = [
		{
			region:"development",
			urls:['pos.vin65.io']
		},
		{
			region:"V65USWestVPC",
			urls:['pos.vin65.com','pos.uswest.vin65.com']
		},
		{
			region:"V65USWest2VPC",
			urls:['pos.vin65.com','pos.uswest.vin65.com']
		},
		{
			region:"V65AUS",
			urls:['pos.aus.vin65.com']
		}
	];

	$scope.determineRegion = function(){
		var region = "";

		$scope.regions.map(function(currentValue){
			//Find the current region
			if(currentValue.urls.indexOf(location.hostname) > -1){
				region = currentValue.region;
			}
		});

		if(!region.length || region == "development"){
			region = "NA";
		}

		return region;
	}

	$scope.redirectRegion = function(intendedRegion,loginData){ 
		var regionURL;

		$scope.regions.map(function(currentValue){
			//Build Region URL
			if(intendedRegion == currentValue.region){
				regionURL = "https://"+currentValue.urls[0] +"/index.cfm#/login/"+loginData;
			}
		});

		//Send user to proper location		
		if(intendedRegion !== $scope.determineRegion() && $scope.determineRegion() !== "NA"){
			location.href = regionURL;
		}else{
			if(localStorage.getItem("ngStorage-manifestUpdate") == 1){
				localStorage.setItem("ngStorage-manifestUpdate", 0);
				location.href="/index.cfm#/store";
			}else{
				$location.path("/store");
			}
		}

	};

	internalDatabases = [
		{ databaseIP:"0.0.0.0",   urls:["pos.vin65.com","pos.uswest.vin65.com"] },

		//SQL 2016:
		//US
		{ databaseIP:"vin65-mssql-us-prod-master.cd5m1jw9n9ma.us-west-2.rds.amazonaws.com", urls: ["pos1.vin65.com"]},
		{ databaseIP:"vin65-mssql-us-prod-1.cd5m1jw9n9ma.us-west-2.rds.amazonaws.com", urls: ["pos1.vin65.com"]},
		{ databaseIP:"vin65-mssql-us-prod-2.cd5m1jw9n9ma.us-west-2.rds.amazonaws.com", urls: ["pos1.vin65.com"]},
		{ databaseIP:"vin65-mssql-us-prod-3.cd5m1jw9n9ma.us-west-2.rds.amazonaws.com", urls: ["pos2.vin65.com"]},
		{ databaseIP:"vin65-mssql-us-prod-4.cd5m1jw9n9ma.us-west-2.rds.amazonaws.com", urls: ["pos3.vin65.com"]},
		{ databaseIP:"vin65-mssql-us-prod-5.cd5m1jw9n9ma.us-west-2.rds.amazonaws.com", urls: ["pos4.vin65.com"]},

		{ databaseIP:"10.5.1.11", urls:["pos1.vin65.com"] },
		{ databaseIP:"10.5.1.21", urls:["pos1.vin65.com"] },
		{ databaseIP:"10.5.1.31", urls:["pos2.vin65.com"] },
		{ databaseIP:"10.5.1.41", urls:["pos3.vin65.com"] },
		{ databaseIP:"10.5.1.51", urls:["pos4.vin65.com"] },

		//AUS
		{ databaseIP:"vin65-rds-prod-aus-1.cfxloydeyyad.ap-southeast-2.rds.amazonaws.com", urls:["pos.aus.vin65.com"] },
		
		//Staging
		{ databaseIP:"10.8.1.20", urls:["pos.vin65.io"] }
	];

	determineCurrentDatabase = function(){
		var databaseIP = '';

		internalDatabases.map(function(currentValue){
			//Find the current database
			if(currentValue.urls.indexOf(location.hostname) > -1){
				databaseIP = currentValue.databaseIP;
			}
		});
		
		// Development check
		if(!databaseIP.length || databaseIP == "10.8.1.20" || $location.search().overrideServer == 1){
			databaseIP = "NA";
		}
		
		return databaseIP;
	}

	redirectByDatabase = function(databaseIP,loginData){
		var results = {
			redirectHostname: '',
			redirectURL: '',
			redirectNeeded: false
		};
		
		internalDatabases.map(function(currentValue){
			//Build Region URL
			if(databaseIP == currentValue.databaseIP){
				results.redirectURL = "https://"+currentValue.urls[0]+"/index.cfm#/login/"+loginData;
				results.redirectHostname = currentValue.urls[0];
			}
		});

		// Send user to proper location
		if(databaseIP !== determineCurrentDatabase() && determineCurrentDatabase() !== "NA"){
			results.redirectNeeded = true;
		}
		
		return results;
	};
	
	$scope.toggle = function(element, boolean){
		elements = element.split(',');
		elements.map(function(element){
			$scope[element] = boolean;
		});
		$scope.loginNotice = {};
	};
	
	$scope.setPinViewState = function(state){
		$scope.pinViewState = state;
		$scope.loginNotice = {};
		$scope.loading = false;
	};
	
	$scope.setPinLengthPlaceholder = function(isMasterAdmin){
		$scope.pinLengthPlaceholder = (isMasterAdmin) ? 5 : 4;
	};
	
	$scope.getPinLengthPlaceholder = function(isMasterAdmin){
		return $scope.pinLengthPlaceholder;
	};
	
	$scope.getEmployeePinPlaceholder = function(){
		if($scope.getPinViewState() === 'saveNewPin'){
			return $scope.getPinLengthPlaceholder() + " Digits";
		}
		return "Enter your PIN";
	};
	
	$scope.isMasterAdminFormEnabled = function(){
		return $scope.masterAdminForm;
	};
	
	setSessionVariables = function(response){
		$scope.user = response.user;
		$scope.k1Admin = response.k1Admin;
	};
	
	requirePinLogIn = function(){
		if($scope.pinLoginEnabled()){
			$scope.loading = false;
		}
		return $scope.pinLoginEnabled();
	};
	
	listWebsites = function(response){
		loginService.listWebsites(function(response){
			$scope.loading = false;
			$scope.toggle('masterAdminForm', true);
			$scope.websites = response.websites;
			$scope.activeWebsite = globalFunctions.findInObject($scope.websites,"activeWebsite", 1)[0].websiteID;
			$scope.formData.websiteID = $scope.activeWebsite;
			$scope.loginNotice = {};
		});
	};
	
	websiteContactPinCheck = function(){
		return new Promise(function(resolve, reject){
			loginService.fetchPinForWebsiteContact($scope.setupPinInfo.websiteID, $scope.setupPinInfo.websiteContactID, function(response){
				(response.websiteContactHasPin) ? resolve() : reject();
			});
		});
	};
	
	initializePinSession = function(response){
		websiteContactPinCheck().then(function(){
			pinSessionService.init(response).then(function(){
				$scope.loading = false;
			});
		}).catch(function(){
			pinSessionService.init(response).then(function(){
				$scope.setPinLengthPlaceholder(response.masterAdmin);
				$scope.setPinViewState('saveNewPin');
			});
		});
	};

	$scope.loginCheck = function(formData){
		$scope.loading = true;
		formData.password = btoa(formData.password);

		loginService.loginCheck(formData, function(response){
			if(vin65.features.posRedirects){
				var internalIP = response.internalIP;
				loginService.encryptLoginData(formData, function(response){
					var loginData = window.btoa(JSON.stringify(response.encryptLoginData.loginData));
					// Check to see if we need to redirect
					var results = redirectByDatabase(internalIP,loginData);
					
					if(results.redirectNeeded && location.hostname !== results.redirectHostname) {
						location.href = results.redirectURL;
						return false;
					}
				});
			}
			
			$scope.setupPinInfo = {
				websiteID: response.websiteID,
				websiteContactID: response.websiteContactID
			};
			if(response.websiteHasPinsTurnedOn){
				$scope.loginNotice = {};
				initializePinSession(response);
			} else {
				setSessionVariables(response);
				// If the user is not a domain or basic admin, show the website picker.
				if(response.masterAdmin === 1){
					listWebsites(response);
				} else {
					$scope.login(response.websiteID);
				}
			}
		});
	};
	
	$scope.pinSetupLoginCheck = function(formData){
		$scope.loading = true;
		formData.password = btoa(formData.password);
		
		loginService.loginCheck(formData, function(response){
			var canChangePin = response.canChangePin === 1;
			$scope.setupPinInfo = {
				websiteID: response.websiteID,
				websiteContactID: response.websiteContactID,
				masterAdmin: response.masterAdmin
			};
			loginService.fetchPinForWebsiteContact(response.websiteID, response.websiteContactID, function(response){
				if(response.websiteContactHasPin && canChangePin){
					$scope.setPinViewState('adminAlreadyHasPin');
					$scope.loading = false;
				} else if(response.websiteContactHasPin){
					$scope.setPinViewState('regularUserAlreadyHasPin');
					$scope.loading = false;
				} else {
					$scope.setPinLengthPlaceholder($scope.setupPinInfo.masterAdmin);
					$scope.setPinViewState('saveNewPin');
					$scope.loginNotice = {};
					$scope.loading = false;
				}
			});
		});
	};
	
	$scope.pinSaveAndLogin = function(formData){
		$scope.loading = true;
		loginService.savePin($scope.setupPinInfo.websiteID, $scope.setupPinInfo.websiteContactID, formData.pinCode, formData.confirmPinCode, function(response){
			$scope.setupPinInfo = {};
			setSessionVariables(response);
			$scope.login(response.websiteID);
		});
	};
	
	$scope.pinLoginCheck = function(formData){
		$scope.loading = true;
		var pinLoginCheckSuccessful = false;
		loginService.pinLoginCheck(formData, function(response){
			setSessionVariables(response);
			$scope.login(response.websiteID);
			
			$rootScope.sendGA('POS PINs', 'Pin Login - Successful', '');
			pinLoginCheckSuccessful = true;
		});
		setTimeout(function(){ 
			if (!pinLoginCheckSuccessful) {
				$rootScope.sendGA('POS PINs', 'Pin Login - Invalid PIN', '');
			}
		}, 5000);
	};

	$scope.login = function(websiteID){
		// encode formData
		loginService.encryptLoginData($scope.formData, function(response){
			var loginData = window.btoa(JSON.stringify(response.encryptLoginData.loginData));

			if($scope.storage.rememberMe === true){
				$scope.storage.rememberUsername = $scope.formData.username;
			}

			loginService.loadWebsite(websiteID, function(response){
				localStorage.setItem("websiteID", websiteID);
				localStorage.setItem("datasource", response.datasource);

				//Validate Region
				if(vin65.features.posRedirects){
					if(localStorage.getItem("ngStorage-manifestUpdate") == 1){
						localStorage.setItem("ngStorage-manifestUpdate", 0);
						location.href="/index.cfm#/store";
					}else{
						$location.path("/store");
					}
				} else {
					$scope.redirectRegion(response.region,loginData);
				}
			});
		});
	};
	
	auth0LogoutIframe = function(logoutURL){
		return '<iframe id="auth0Logout" width="0" height="0" frameBorder="0" src="' + logoutURL + '"></iframe>';
	};
	
	$scope.logout = function(){
		$scope.$session.$reset();
		loginService.logout(function(response){
			if(auth0IsEnabled()){
				$scope.logoutIframe = auth0LogoutIframe(response.logout.auth0LogoutURL);
				auth0Service.logoutIframePromise().then(function(){
					globalFunctions.clearSession();
					location.href = "/";
				});
			} else {
				globalFunctions.clearSession();
				location.href = "/";
			}
		});
	};

	$scope.rememberMe = function(rememberMe){
		if(rememberMe === true){
			$scope.storage.rememberMe = false;
			$scope.storage.rememberUsername = "";
		}else{
			$scope.storage.rememberMe = true;
			$scope.storage.rememberUsername = $scope.formData.username;
		}
	};

	$scope.selectWebsite = function(websiteID){
		$scope.loading = true;
		// If the feature flag is turned on for pins and the user is not a k1 admin.
		if($scope.k1Admin === 0){
			// Check if pins are enabled for the website.
			loginService.pinsEnabledForWebsite(websiteID, function(response){
				// If pins are turned on for the website, add the cookies to the browser.
				if(response.websiteHasPinsTurnedOn){
					$scope.loginNotice = {};
					initializePinSession(response);
					$rootScope.sendGA('POS PINs', 'List Websites', '');
				} else {
					$scope.login(websiteID);
				}
			});
		} else {
			$scope.login(websiteID);
		}
	};
	
	auth0IsEnabled = function() {
		return vin65.features.auth0.posLogin;
	};
});
