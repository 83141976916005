pos.customerManager.controller("orders", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.orders.listOrders";
	$scope.viewRoute = "storeManager.order.viewOrder";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("listCustomerOrders", function($scope, $rootScope, globalData, globalFunctions, orderService, $stateParams){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Orders";
	$scope.orders = {};
	$scope.customerID = $stateParams.customerID;
	$scope.filter = $stateParams.filter;
	
	//Functions
	$scope.listOrders = function(page, showPending){
		$scope.$emit('formLoading', true);

		if(showPending){
			orderStatusID = "";
			$scope.shownPending = 1
		}else{
			orderStatusID = null;
			$scope.shownPending = 0
		}
		
		var requestParams = {
			customerID: $scope.customerID,
			orderStatusID: orderStatusID,
			page: page,
			maxRows: 20
		}
		
		switch ($scope.filter) {
			case 'quarantined':
				requestParams.orderStatusID='6AD94024-9065-463E-9E81-5A4603536801';
				break;
			case 'pickup':
				requestParams.orderShippingStatusID='NotYetPickedUp';
				break;
		}

		orderService.listOrders(requestParams,function(response){
			$scope.$emit('formLoading', false);
			$scope.orders = response.orders;
		});
	}

	$scope.viewOrder = function(){
		$rootScope.viewedFromCustomerArea = 1;
	}

	$scope.showHidePending = function(status){
		$scope.listOrders(1, status);
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		globalFunctions.scrollTo('top');
		$scope.listOrders(page);
	}

	//Listeners

	//Logic
	$scope.listOrders(1);
});