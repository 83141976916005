pos.customerManager.controller("notes", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.notes.listNotes";
	$scope.viewRoute = "customerManager.viewCustomer.notes.viewNote";
	$scope.addRoute = "customerManager.viewCustomer.notes.addNote";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("listNotes", function($scope, $rootScope, globalData, globalFunctions, noteService){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Notes";
	$scope.notes = {};

	//Functions
	$scope.listNotes = function(page){
		$scope.$emit('formLoading', true);
		noteService.listNotes(page, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.notes = response.notes;
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		globalFunctions.scrollTo('top');
		$scope.listNotes(page);
	}

	//Listeners

	//Logic
	$scope.listNotes(1);
});


pos.customerManager.controller("viewNote", function($scope, $rootScope, globalData, globalFunctions, noteService, $stateParams, $controller, $state){
	//Variables
	$scope.$emit('formLoading', true);
	$rootScope.currentManager.subSectionTitle = "View Note";
	$scope.note = {};

	//Functions
	$scope.deleteNote = function(noteID){
		$scope.$emit('formLoading', true);
		noteService.deleteNote(noteID, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}

	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		noteService.editNote($scope.formData.comments,	$scope.formData.noteDate,	$stateParams.noteID,	$scope.formData.noteType,	$scope.formData.subject, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}

	//Listeners

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController

	noteService.viewNote($stateParams.noteID, function(response){
		$scope.$emit('formLoading', false);
		$scope.note = response.note;
		$scope.formData = response.note;
	});
});

pos.customerManager.controller("addNote", function($scope, $rootScope, globalData, globalFunctions, noteService, $controller, $state){
	//Variables
	$rootScope.currentManager.subSectionTitle = "Add Note";
	$scope.$emit('formLoading', false);

	//Functions
	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		noteService.addNote($scope.formData.comments,	$scope.formData.noteDate,	$scope.formData.noteType,	$scope.formData.subject, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}


	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController
});