pos.controller("globalController", function($scope, $cookies, $rootScope, session, storage, globalData, globalFunctions, loginService, auth0Service, cartService, checkoutService, settingsService, customerService, $timeout, $location, $sce, $state, $document, cacheService){

	//Variables
	$scope.mainMenuOpen = false;
	$scope.modalOpen = false;
	$scope.loading = false;
	$scope.documentHeight = $document.height();
	$rootScope.cartOpen = false;
	$scope.$storage = storage;
	$scope.$session = session;
	$scope.posProfile = session.posProfile;
	$scope.globalData = globalData;
	$scope.cookies = $cookies;
	$scope.deviceType = $scope.cookies.DEVICETYPE;
	//Logic
	
	//Listeners

	$rootScope.$on('iosNotice', function(event, data){
		switch(data.alertLevel){
			case "changeNotice":
				$scope.changeNotice = {};
				$scope.changeNotice.active = true;
				$scope.changeNotice.alertMessage = data.alertMessage;
				$scope.changeNotice.alertLevel = data.alertLevel;
			break;
		}
	});

	$rootScope.$on('globalError', function(event, data){
		$scope.globalError = {};
		$scope.globalError.active = true;
		$scope.globalError.alertMessage = data.alertMessage;
		$scope.globalError.alertLevel = data.alertLevel;
		$scope.globalError.errorData = data.response;
		$scope.$apply();
	});
	
	$rootScope.$on('loadingNotice', function(event, data){
		$scope.loadingMessage = $scope.renderHTML(data.loadingMessage);
		$scope.loadingButton = data.loadingButton;
		$scope.loadingButtonType = data.loadingButtonType;
	});
	
	$rootScope.$on('dismissMessage', function(){
		$scope.dismissMessage();
	});

	$rootScope.$on('globalLoading', function(event, data){
		$scope.loading = data;
	});

	//Functions

	$scope.emailError = function(errorData){
		globalFunctions.emailError("JSON",errorData.response, errorData.headers, errorData.url, errorData.time);
	};

	//Additional Order Info from a non checkout area
	$scope.additionalOrderDataFromOutsideCheckout = function(){
		checkoutService.listAdditionalOrderData(function(){
			$scope.openModal("v2/views/partials/modals/additionalOrderData", {outsideCheckout:1});
		});
	};


	//Mobile Cart Toggle
	$scope.cartToggle = function(isOpen){
		$rootScope.cartOpen = isOpen;
	};
	
	//Dismiss loading message
	$scope.dismissMessage = function(){
		globalFunctions.alertMessage("loadingNotice", null, "")
		$rootScope.$emit("cartLoading", false);
		$rootScope.$emit("globalLoading", false);
		$rootScope.$emit("loginLoading", false);
	};

	//Reload on global error
	$scope.problemReload = function(){
		location.reload();
	};

	//Detects for menu
	$scope.activeRoute = function(route){
		if($state.current.name.indexOf(route) != -1){
			return true;
		}
	};

	//Angular Render HTML
	$scope.renderHTML = function(html){
		return $sce.trustAsHtml(html);
	};

	//Drivers licence for desktop
	$scope.driversLicenseOn = function(){
		globalFunctions.swipe("driversLicense", true);
	};

	//Credit Card form for desktop
	$scope.creditCardFormOn = function(){
		globalFunctions.swipe("creditCardForm", true);
	};
	
	//Drivers licence for desktop
	$scope.giftCardOn = function(){
		globalFunctions.swipe("giftCard", true);
	};

	$scope.reloadPOS = function(){
		$rootScope.$emit("globalLoading", true);
		$scope.toggleMenu();
		cartService.init(null,function(){
			cacheService.reset();
			globalFunctions.clearSession();
			$timeout(function(){
				location.reload();
			},300);
		});
	};

	$scope.reloadCustomer = function(){
		if(session.customer.customerID){
			customerService.selectCustomer(session.customer, function(){
				//Callback
			});
		}
	};

	$scope.openModal = function(template, modalData){
		if(template){
			$rootScope.$emit('modalOpen',  { template:template, modalData:modalData});
		}else{
			$rootScope.$emit('closeModal');
		}
	};

	$scope.toggleMenu = function(){
		$scope.mainMenuOpen = $scope.mainMenuOpen === false ? true : false;
	};

	$scope.closeMenu = function(){
		$scope.mainMenuOpen = false;
	};

	$scope.toggleNotificationSidebar = function(){
		if($scope.mainMenuOpen === true){
			$scope.toggleMenu();
		}
		$rootScope.$emit("toggleNotificationSidebar");
	};
	
	$scope.logout = function(){
		$rootScope.$emit('cartLoading', true);
		$scope.$session.$reset();
		loginService.logout(function(response){
			if(vin65.features.auth0.posLogin){
				$scope.logoutIframe = '<iframe id="auth0Logout" width="0" height="0" frameBorder="0" src="' + response.logout.auth0LogoutURL + '"></iframe>';
				auth0Service.logoutIframePromise().then(function(){
					globalFunctions.clearSession();
					location.href = "/";
				});
			} else {
				globalFunctions.clearSession();
				location.href = "/";
			}
		});
	};
});


pos.controller("store", function($scope, $rootScope, session, globalData, globalFunctions, loginService, settingsService, $timeout){
	//Variables
	$scope.order = session.order;
	$scope.chargeOpen = false;
	
	//Logic
	if(Object.keys(session.posProfile).length === 0){
		$scope.logout();
	} else {
		loginService.aboutPOS(function(){
			$rootScope.$emit("notificationListen", true);
			globalFunctions.validatePaymentTerminal(globalData.posInfo.paymentGateway, globalData.posInfo.hasOldEMV);
			globalFunctions.validateEMVTerminal(globalData.posInfo.hasEMV);
			// globalFunctions.validateNewZeamsterSettings(globalData.posInfo.hasEMV, function() {
			// 	$scope.openModal('v2/views/partials/modals/deviceSettings');
			// });
		});
	}
	
	requestReceiptPrinters();

	//Listeners
	$scope.$watchCollection('order', function(){
		$timeout(function(){
			globalFunctions.calculateCartHeight();
		},100);
	});

	$rootScope.$on('cartLoading', function(event, data){
		$rootScope.cartLoading = data;
	});
	
	$rootScope.$on('chargeOpen', function(event, data){
		$rootScope.chargeOpen = data;
		$scope.chargeOpen = data;
	});
});


pos.controller("mainMenu", function($scope, $localStorage, $rootScope, session, globalData, globalFunctions, loginService, customerService, $state, $location, $timeout, $animate, pinSessionService){
	//Variables
	$scope.customer = session.customer;
	$scope.order = session.order;
	$scope.posInfo = globalData.posInfo;
	$scope.posDeviceProfile = session.posDeviceProfile;
	$scope.printerSettings = globalData.printerSettings; //ForCash Drawer
	$scope.$storage = $localStorage;


	//Logic

	$animate.enabled(false, angular.element(".mainMenu"));	


	//Functions
	$scope.adminPanel = function(){
		globalFunctions.v65http("/index.cfm?method=global.siteadmin", null, function(response){
			openSafari(response.siteadminLink.trim());
		});
	};
	
	$scope.pinsEnabled = function(){
		return pinSessionService.pinsEnabled();
	};

	$scope.goToPage = function(path){
		$scope.toggleMenu();
		$location.path(path);
	};

	$scope.openCashDrawer = function(){
		globalFunctions.openCashDrawer(globalData.printerSettings.defaultCashDrawerMacAddress);
	};

	$scope.printReceipt = function(){
		globalFunctions.printReceipt($scope.printerSettings.defaultPrinterMacAddress, $scope.order.orderHeader.orderID, "prePrintReceipt", function(){
			$scope.toggleMenu();
		});
	};

	$scope.showIf = function(route){
		if($state.current.name.indexOf(route) != -1){
			return true;
		}
	};
});


pos.controller("formController", function($scope, $rootScope, globalData, globalFunctions, $timeout){

	//Extends onto modalDialog
	$scope.formValidate = function(formElements){
		if(formElements.$invalid == true){
			$scope.alertLevel = "error";
			$scope.alertMessage = "Please Correctly Fill out all Required Fields";
			$scope.$emit('modalLoading', false);
			$scope.modalButtonLoading = false;
			globalFunctions.scrollTo("alert");
			$scope.hasError = true;
			return false;
		}else{
			$scope.alertLevel = "";
			$scope.alertMessage = "";
			$scope.$emit('modalLoading', false);
			$scope.modalButtonLoading = false;
			$scope.hasError = "";
			return true;
		}
	};

	$rootScope.$on("formError", function(event, data){
		$scope.alertLevel = data.alertLevel;
		$scope.alertMessage = data.alertMessage;
		$scope.$emit('formLoading', false);
	});

	$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){ 
		$scope.alertLevel = "";
		$scope.alertMessage = "";
		$timeout(function(){
			globalFunctions.scrollTo("top");
		},1);
	});

	//Dismiss loading message
	$scope.dismissMessage = function(){
		$scope.alertMessage = "";
		$scope.modalButtonLoading = false;
		globalFunctions.alertMessage("loadingNotice", null, "")
		$scope.$emit('formLoading', false);
		$scope.$emit('modalLoading', false);
	};
});


pos.controller("modalDialog", function($scope, $rootScope, globalData, globalFunctions, $templateCache, $controller, $document, $timeout){
	//Variables


	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController
	$scope.$emit('modalLoading', true);


	//Listeners
	$rootScope.$on('modalOpen', function(event, data){
		var timestamp = new Date().getTime();
		$scope.modalOpen = true;
		$scope.modalButtonLoading = false;
		$scope.currentModalName = data.template.substr(data.template.lastIndexOf("/") + 1);

		$scope.template = "modalLoading";

		$scope.$watch('$includeContentLoaded', function(){
			if(globalData.posInfo.posPaymentFix) {
				$scope.template = data.template+"Modal.htm";
			} else {
				$scope.template = data.template+"Modal.htm?9"+ timestamp;
			}
			$scope.modalData = data.modalData;
		});

		$scope.$emit('modalLoading', true);

	});
	$scope.$on('modalLoading', function(event, data){
		$scope.loading = data;
	});

	$rootScope.$on('modalError', function(event, data){
		$scope.modalButtonLoading = false;
		$scope.$emit('modalLoading', false);
		$scope.alertLevel = data.alertLevel;
		$scope.alertMessage = data.alertMessage;
		$scope.isAlertActionable = data.isAlertActionable;
		$scope.alertActionableURL = data.alertActionableURL;
		$scope.$evalAsync();
		globalFunctions.scrollTo("alert");
	});

	$rootScope.$on('closeModal', function(){
		$scope.closeModal();
	});

	//Functions
	$scope.closeModal = function(action, response){
		$scope.modalOpen = false;
		$scope.clearModalAlerts();
		$scope.modalButtonLoading = false;
		$scope.$emit('modalLoading', false);
		if($("[name=formElements]").length) {
			$("[name=formElements]")[0].reset();
		}
		$templateCache.remove($scope.template);
		$(".modal").remove();
		$scope.hasError = 0; // Sets all errors back to null
		if(!$rootScope.chargeOpen){
			globalFunctions.swipe("creditCard", false);
			globalFunctions.swipe("driversLicense", false);
		}

		//Lets us fire an action on complete
		if(action){
			$rootScope.$emit(action, response);
		}
	};
	
	//Dismiss loading message
	$scope.dismissMessage = function(){
		$scope.alertMessage = "";
		$scope.modalButtonLoading = false;
		globalFunctions.alertMessage("loadingNotice", null, "")
		$scope.$emit('formLoading', false);
		$scope.$emit('modalLoading', false);
	};

	$scope.modalActionDefaults = function(formData){
		//Default Modal Actions. DRY
		$scope.$emit('modalLoading', true);
		$scope.modalButtonLoading = true;
		$scope.clearModalAlerts();
	};
	
	$scope.clearModalAlerts = function(){
		$scope.alertLevel = "";
		$scope.alertMessage = "";
	};

});


pos.filter('range', function() {
	return function(input, total) {
		total = parseInt(total);
		for (var i=0; i<total; i++)
			input.push(i);
		return input;
	};
});
