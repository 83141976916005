pos.customerManager.controller("addressBook", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.addressBook.listAddressBook";
	$scope.viewRoute = "customerManager.viewCustomer.addressBook.viewAddressBookEntry";
	$scope.addRoute = "customerManager.viewCustomer.addressBook.addAddressBookEntry";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("listAddressBook", function($scope, $rootScope, globalData, globalFunctions, addressBookService, $sce){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Address Book";
	$scope.addressBook = {};

	//Functions
	$scope.listAddressBook = function(page){
		$scope.$emit('formLoading', true);
		addressBookService.listAddressBook(page, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.addressBook = response.addressBook;
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		globalFunctions.scrollTo('top');
		$scope.listAddressBook(page);
	}

	//Listeners

	//Logic
	$scope.listAddressBook(1);
});


pos.customerManager.controller("viewAddressBookEntry", function($scope, $rootScope, globalData, globalFunctions, addressBookService, $stateParams, $controller, $state){
	//Variables
	$scope.$emit('formLoading', true);
	$rootScope.currentManager.subSectionTitle = "View Address";
	$scope.addressBookEntry = {};
	$scope.posInfo = globalData.posInfo; //Pulled to get states

	//Functions
	$scope.deleteAddressBookEntry = function(shipCustomerID){
		$scope.$emit('formLoading', true);
		addressBookService.deleteAddressBookEntry(shipCustomerID, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		})
	}

	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		addressBookService.editAddressBookEntry($scope.formData.address,	$scope.formData.address2,	$scope.formData.birthDate,	$scope.formData.birthDay,	$scope.formData.birthMonth,	$scope.formData.birthYear,	$scope.formData.city,	$scope.formData.company,	$scope.formData.email,	$scope.formData.firstName,	$scope.formData.isPrimary,	$scope.formData.lastName,	$scope.formData.nickName,	$scope.formData.phone,	$stateParams.shipCustomerID, $scope.formData.countryCode, $scope.formData.stateCode,	$scope.formData.stringAddress,	$scope.formData.zipCode, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}

	//Listeners

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController

	addressBookService.viewAddressBookEntry($stateParams.shipCustomerID, function(response){
		$scope.$emit('formLoading', false);
		$scope.addressBookEntry = response.addressBookEntry;
		$scope.formData = response.addressBookEntry;
	});
});

pos.customerManager.controller("addAddressBookEntry", function($scope, $rootScope, globalData, globalFunctions, addressBookService, $controller, $state){
	//Variables
	$rootScope.currentManager.subSectionTitle = "Add Address";
	$scope.posInfo = globalData.posInfo; //Pulled to get states
	$scope.$emit('formLoading', false);

	//Functions
	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		addressBookService.addAddressBookEntry($scope.formData.address,	$scope.formData.address2,	$scope.formData.birthDate,	$scope.formData.birthDay,	$scope.formData.birthMonth,	$scope.formData.birthYear,	$scope.formData.city,	$scope.formData.company,	$scope.formData.email,	$scope.formData.firstName,	$scope.formData.isPrimary,	$scope.formData.lastName,	$scope.formData.nickName,	$scope.formData.phone,	$scope.formData.countryCode, $scope.formData.stateCode,	$scope.formData.stringAddress,	$scope.formData.zipCode, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}
	//listeners

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController

});


pos.customerManager.controller("addAddressBookEntryModal", function($scope, $rootScope, globalData, globalFunctions, addressBookService, $controller, $state){
	//Variables
	$scope.modalTitle = "Add Address";
	$scope.modalSubmitTitle = "Save";
	$scope.posInfo = globalData.posInfo;

	//Functions
	$scope.formAction = function(){
		$scope.modalActionDefaults($scope.formData);
		addressBookService.addAddressBookEntry($scope.formData.address,	$scope.formData.address2,	$scope.formData.birthDate,	$scope.formData.birthDay,	$scope.formData.birthMonth,	$scope.formData.birthYear,	$scope.formData.city,	$scope.formData.company,	$scope.formData.email,	$scope.formData.firstName,	$scope.formData.isPrimary,	$scope.formData.lastName,	$scope.formData.nickName,	$scope.formData.phone,	$scope.formData.countryCode, $scope.formData.stateCode,	$scope.formData.stringAddress,	$scope.formData.zipCode, function(response){
			$scope.closeModal("addedNewAddressBookEntry", response);
		});
	}
	//listeners

	//Logic
	$scope.$emit('modalLoading', false);
});