pos.customerManager.controller("clubMemberships", function($scope, $rootScope, globalData, globalFunctions, clubMembershipService, shippingService, customerManagerService, creditCardService, addressBookService,$location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.clubMemberships.listClubMemberships";
	$scope.viewRoute = "customerManager.viewCustomer.clubMemberships.viewClubMembership";
	$scope.addRoute = "customerManager.viewCustomer.clubMemberships.addClubMembership";
	$scope.chooseRoute = "customerManager.viewCustomer.clubMemberships.chooseClub";
	$scope.clubMembership = {};
	$scope.pickupLocations = [];
	$scope.addressBook = [];
	$scope.salesAssociates = [];
	$scope.clubCancellationReasons = [];
	$scope.creditCards = [];
	$scope.clubs = [];
	$scope.shippingStrategies = [];
	$scope.shippingTypes = [];

	//Functions
	$scope.listPickupLocations = function(){
		clubMembershipService.listPickupLocations(function(response){
			$scope.pickupLocations = response.pickupLocations;
		});
	}

	$scope.listAddressBook = function(){
		addressBookService.listAddressBook(1,1000,function(response){
			$scope.addressBook = response.addressBook.array;
			$scope.localCustomer = angular.copy($scope.customer);
			$scope.localCustomer.nickName = "Billing Address";
			$scope.localCustomer.shipCustomerID = "BillTo";
			$scope.localCustomer.stringAddress = "";
			$scope.addressBook.unshift($scope.localCustomer);
			$scope.addressBook.push({
				shipCustomerID:"addNewAddress",
				nickName:"Add New Address",
				stringAddress:""
			})
		});
	}

	$scope.listClubCancellationReasons = function(){
		clubMembershipService.listClubCancellationReasons(function(response){
			$scope.clubCancellationReasons = response.clubCancellationReasons;
		});
	}

	$scope.listClubs = function(callback){
		clubMembershipService.listClubs(1, 100, function(response){
			$scope.clubs = response.clubs;
			(callback)()
		});
	}

	$scope.listShippingStrategies = function(callback){
		clubMembershipService.listShippingStrategies(function(response){
			$scope.shippingStrategies = response.shippingStrategies;
			if(callback)(callback)()
		});
	}

	$scope.listShippingTypes = function(shippingStrategyID, callback){
		clubMembershipService.listShippingTypes(shippingStrategyID, function(response){
			$scope.shippingTypes = response.shippingTypes;
			if(callback)(callback)()
		});
	}

	$scope.listSalesAssociates = function(){
		customerManagerService.listSalesAssociates(function(response){
			$scope.salesAssociates = response.salesAssociates;
		});
	}

	$scope.matchSalesAssociate = function(salesAssociateID){
		if(globalFunctions.findInObject($scope.salesAssociates,'salesAssociateID', salesAssociateID).length){
			return $scope.salesAssociate = globalFunctions.findInObject($scope.salesAssociates,'salesAssociateID', salesAssociateID)[0].salesAssociate || "";
		}
	}

	$scope.listCreditCards = function(callback){
		creditCardService.listCreditCards(1, 100, function(response){
			$scope.creditCards = response.creditCards.array;
			$scope.creditCards.push({
				"customerCreditCardID":"addNewCard",
				"nameOnCard":"Add New Credit Card"	
			})
			if(callback)(callback)(response)
		});	
	}

	$scope.getPickupAddressFromID = function(pickupLocationID){
		if(globalFunctions.findInObject($scope.pickupLocations,'pickupLocationID', pickupLocationID).length){
			return globalFunctions.findInObject($scope.pickupLocations,'pickupLocationID', pickupLocationID)[0];
		}
	}

	$scope.getShippingAddressFromID = function(shipCustomerID){
		if(globalFunctions.findInObject($scope.addressBook,'shipCustomerID', shipCustomerID).length){
			return globalFunctions.findInObject($scope.addressBook,'shipCustomerID', shipCustomerID)[0];
		}
	}

	$scope.getClubCancellationFromID = function(clubCancellationReasonID){
		if(globalFunctions.findInObject($scope.clubCancellationReasons,'clubCancellationReasonID', clubCancellationReasonID).length){
			return globalFunctions.findInObject($scope.clubCancellationReasons,'clubCancellationReasonID', clubCancellationReasonID)[0];
		}
		else{
			return null;
		}
	}

	$scope.getCreditCardFromID = function(customerCreditCardID){
		if(globalFunctions.findInObject($scope.creditCards,'customerCreditCardID', customerCreditCardID).length){
			return globalFunctions.findInObject($scope.creditCards,'customerCreditCardID', customerCreditCardID)[0];
		}
	}

	$scope.selectCreditCard = function(customerCreditCardID){
		if(customerCreditCardID == "addNewCard"){
			$scope.openModal("v2/views/customer/creditCards/addCreditCard");
		}
	}

	$scope.scrollTo = function(step){
		$timeout(function(){
			if($scope.deviceType == "iPhone" && angular.element(".contentCol").length) {
				angular.element(".contentCol").scrollToElement(angular.element("#"+step), 10, 1000);
			}else{
				if(angular.element(".hasNav").length) {
					angular.element(".hasNav").scrollToElement(angular.element("#"+step), 10, 1000);				
				}
			}
		},250)
	}


	//Listeners
	//Logic
});

pos.customerManager.controller("listClubMemberships", function($scope, $rootScope, globalData, globalFunctions, clubMembershipService, $sce){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Club Memberships";
	$scope.clubMemberships = {};

	//Functions
	$scope.listClubMemberships = function(page){
		$scope.$emit('formLoading', true);
		clubMembershipService.listClubMemberships(page, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.clubMemberships = response.clubMemberships;
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		$scope.scrollTo('top');
		$scope.listClubMemberships(page);
	}

	//Listeners

	//Logic
	$scope.listClubMemberships(1);
});

pos.customerManager.controller("viewClubMembership", function($document, $scope, $rootScope, session, globalData, globalFunctions, clubMembershipService, shippingService, customerManagerService, creditCardService, $stateParams, $controller, $state){
	//Variables
	$scope.$emit('formLoading', true);
	$rootScope.currentManager.subSectionTitle = "View Club Membership";
	$scope.customer = session.customer;
	$scope.currentStep = '';
	
	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		clubMembershipService.editClubMembership(
			$stateParams.clubMembershipID,
			$scope.formData.pickupLocationID,
			$scope.formData.shipCustomerID,
			$scope.formData.productSKU,
			$scope.formData.signupDay,
			$scope.formData.signupMonth,
			$scope.formData.signupYear,
			$scope.formData.cancelDay,
			$scope.formData.cancelMonth,
			$scope.formData.cancelYear,
			$scope.formData.clubCancellationReasonID,
			$scope.formData.onHoldStartDay,
			$scope.formData.onHoldStartMonth,
			$scope.formData.onHoldStartYear,
			$scope.formData.onHoldEndDay,
			$scope.formData.onHoldEndMonth,
			$scope.formData.onHoldEndYear,
			$scope.formData.productDiscount,
			$scope.formData.shippingDiscount,
			$scope.formData.salesAssociateID,
			$scope.formData.sourceCode,
			$scope.formData.notes,
			$scope.formData.retainClubPrivileges,
			$scope.formData.overrideShipping,
			$scope.formData.shippingStrategyID,
			$scope.formData.shippingTypeID,
			$scope.formData.isAGift,
			$scope.formData.giftMessage,
			$scope.formData.welcomePackageGiftCardCode,
			$scope.formData.welcomePackageCouponCode,
			$scope.formData.userChoice,
			$scope.formData.creditCardID,
			$scope.formData.shipTo,
			$scope.formData.customerCreditCardID,
			$scope.formData.clubID,
			$scope.formData.sendEmail,
			$scope.formData.sendCancellationEmail,
			$scope.formData.sendHoldEmail,
			 function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
			//Go back to list page, and show success screen at top.
		});
	}

	$scope.viewClubMembership = function(clubMembershipID){
		clubMembershipService.viewClubMembership(clubMembershipID, function(response){
			$scope.$emit('formLoading', false);
			$scope.salesAssociate = $scope.matchSalesAssociate(response.clubMembership.salesAssociateID);
			$scope.clubMembership = response.clubMembership;
			$scope.carlAcceptedTermsHTML = response.clubMembership.carlAcceptedTerms;
			$scope.formData = globalFunctions.flattenObject(response);
			$scope.formData.customerCreditCardID = response.clubMembership.creditCardID;
			$scope.formData.clubMembershipID = clubMembershipID;
			$scope.formData.shipCustomerID = $scope.formData.shipCustomerID || "BillTo";
			$scope.formData.welcomePackageCouponCode = "";
			$scope.formData.welcomePackageGiftCardCode = "";
			$scope.formData.productSKU = "";
			$scope.listShippingTypes($scope.formData.shippingStrategyID);
			if ($scope.formData.cancelDay) {
				$scope.sendCancellationLabel = "Resend Cancellation Email";
				$scope.formData.sendCancellationEmail = false;
			} else {
				$scope.sendCancellationLabel = "Send Cancellation Email";
				$scope.formData.sendCancellationEmail = false;
			}
		});
	}

	$scope.clubStep = function(step){
		$scope.currentStep = step;
		if(step){
			$scope.scrollTo(step);
		}else{
			$scope.scrollTo('review');
		}
	}

	$scope.changeShipTo = function(){
		if($scope.formData.shipCustomerID != "BillTo" && $scope.formData.shipCustomerID != "addNewAddress"){
			$scope.formData.shipTo = "ShippingAddress";
			$scope.clubMembership.ship.shipTo = "ShippingAddress";
		} else if($scope.formData.shipCustomerID == "addNewAddress"){
			$scope.formData.shipTo = "ShippingAddress";
			$scope.clubMembership.ship.shipTo = "ShippingAddress";
			$scope.formData.shipCustomerID = "";
			$scope.openModal("v2/views/customer/addressBook/addAddressBookEntry");
		}else{
			$scope.formData.shipTo = "billingAddress";
			$scope.formData.shipCustomerID = "";
			$scope.clubMembership.ship.shipTo = "billingAddress";
		}
	}


	//Listeners

	$rootScope.$on("addedNewCreditCard", function(event, response){
		$scope.$parent.creditCards = response.creditCards.array;
		$scope.formData.customerCreditCardID = response.addCreditCard.creditCard.customerCreditCardID
	})

	$rootScope.$on("addedNewAddressBookEntry", function(event, response){
		$scope.formData.shipCustomerID = response.addAddressBookEntry.addressBookEntry.shipCustomerID;
		$scope.clubMembership.ship.shipTo = "ShippingAddress";
		$scope.$parent.addressBook = response.addressBook.array;
		$scope.$parent.addressBook.push({
			shipCustomerID:"addNewAddress",
			nickName:"Add New Address",
			stringAddress:""
		})
		$scope.clubStep('');
	})

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController
	$scope.viewClubMembership($stateParams.clubMembershipID);
	$scope.listPickupLocations();
	$scope.listAddressBook();
	$scope.listClubCancellationReasons();
	$scope.listSalesAssociates();
	$scope.listCreditCards();
	$scope.listShippingStrategies()


	$rootScope.$on("formError", function(event, data){
		$scope.$emit('formLoading', false);
	})
});

pos.customerManager.controller("chooseClub", function($scope, $rootScope, $state, session, globalData, globalFunctions, clubMembershipService, $controller){
	//Variables
	$rootScope.currentManager.subSectionTitle = "Choose Club";
	$scope.$emit('formLoading', true);
	$scope.customer = session.customer;

	//Functions
	$scope.chooseClub = function(clubID){
		if($scope.customer.address && $scope.customer.firstName && $scope.customer.lastName && $scope.customer.city && $scope.customer.stateCode){
			$state.go($scope.addRoute,{clubID:clubID});
		}else{
			$scope.clubID = clubID;
			$scope.openModal('v2/views/customer/dashboard/editCustomer',{toBeFilled:1, alertLevel:'warning', alertMessage: 'In order to add a club, user must have a full billing address.'});
		}
	}

	//Listeners
	$rootScope.$on("editedCustomer", function(event, data){
		if($state.current.controller == "chooseClub"){
			$scope.chooseClub($scope.clubID);
		}
	});

	//Logic
	$scope.listClubs(function(){
		$scope.$emit('formLoading', false);
	});
});

pos.customerManager.controller("addClubMembership", function($scope, $rootScope, session, globalData, globalFunctions, $state, clubMembershipService, $controller, $stateParams){
	//Variables
	$rootScope.currentManager.subSectionTitle = "Add Club Membership";
	$scope.customer = session.customer;
	$scope.currentStep = 'shippingStep';
	$scope.$emit('formLoading', true);
	$scope.completedSteps = [];
	$scope.date = new Date();

	$scope.enableProcess = false;

	//Functions
	$scope.formAction = function(){
		$scope.$emit('formLoading', true);

		$scope.formData.customerCreditCardID = $scope.formData.creditCardID;
		$scope.formData.clubID = $stateParams.clubID;
		clubMembershipService.addClubMembership(
			$scope.formData.pickupLocationID,
			$scope.formData.shipCustomerID,
			$scope.formData.productSKU,
			$scope.formData.signupDay,
			$scope.formData.signupMonth,
			$scope.formData.signupYear,
			$scope.formData.cancelDay,
			$scope.formData.cancelMonth,
			$scope.formData.cancelYear,
			$scope.formData.clubCancellationReasonID,
			$scope.formData.onHoldStartDay,
			$scope.formData.onHoldStartMonth,
			$scope.formData.onHoldStartYear,
			$scope.formData.onHoldEndDay,
			$scope.formData.onHoldEndMonth,
			$scope.formData.onHoldEndYear,
			$scope.formData.productDiscount,
			$scope.formData.shippingDiscount,
			$scope.formData.salesAssociateID,
			$scope.formData.sourceCode,
			$scope.formData.notes,
			$scope.formData.retainClubPrivileges,
			$scope.formData.overrideShipping,
			$scope.formData.shippingStrategyID,
			$scope.formData.shippingTypeID,
			$scope.formData.isAGift,
			$scope.formData.giftMessage,
			$scope.formData.welcomePackageGiftCardCode,
			$scope.formData.welcomePackageCouponCode,
			$scope.formData.userChoice,
			$scope.formData.creditCardID,
			$scope.formData.shipTo,
			$scope.formData.customerCreditCardID,
			$scope.formData.clubID,
			$scope.formData.sendEmail,
			$scope.formData.sendCancellationEmail,
			$scope.formData.sendHoldEmail,
			function(response){
				$scope.$emit('formLoading', false);
				$state.go($scope.listRoute);
		});
	}

	$scope.viewClub = function(callback){
		clubMembershipService.viewClub($stateParams.clubID, function(response){
			$scope.$emit('formLoading', false);
			$scope.club = response.club;
			if(globalFunctions.findInObject($scope.creditCards, "isPrimary", "1").length){
				$scope.formData.creditCardID = globalFunctions.findInObject($scope.creditCards, "isPrimary", "1")[0].customerCreditCardID;
			}
			$scope.formData.userChoiceOptions = $scope.club.userChoiceOptions
			$scope.scrollTo("shippingStep");
		});
	}

	$scope.changeShipTo = function(){
		if($scope.formData.shipCustomerID != "BillTo" && $scope.formData.shipCustomerID != "addNewAddress"){
			$scope.formData.shipTo = "ShippingAddress";
		}else if($scope.formData.shipCustomerID == "addNewAddress"){
			$scope.formData.shipCustomerID = "";
			$scope.openModal("v2/views/customer/addressBook/addAddressBookEntry");
		}else{
			$scope.formData.shipTo = "billingAddress";
			$scope.formData.shipCustomerID = "";
		}
	}

	$scope.editStep = function(step){
		$scope.currentStep = step;
		$scope.scrollTo(step);
		$scope.enableProcess = false;
	}

	$scope.cancelStep = function(){
		$scope.currentStep = "";
		$scope.scrollTo('review');
	}

	$scope.nextStep = function(step){
		if($scope.completedSteps.indexOf(step) == -1){
			switch(step){
				case "welcomePackageStep":
					$scope.scrollTo(step);
					if($scope.club.clubProperties.hasWelcomePackage == 1){
						$scope.currentStep = step;
					}else{
						$scope.nextStep("userChoiceStep")
					}
				break;

				case "userChoiceStep":
					$scope.scrollTo(step);
					if($scope.club.userChoiceOptions.length){
						$scope.currentStep = step;
					}else{
						$scope.nextStep("review")
						$scope.currentStep = "";
						$scope.enableProcess = true;
					}
				break;

				default:
					$scope.scrollTo(step);
					$scope.currentStep = step;
				break;
			}
		}else{
			$scope.currentStep = "";
			$scope.scrollTo('review');
			$scope.enableProcess = true;
		}

		if(!step){
			$scope.scrollTo('review');	
			$scope.enableProcess = true;
		}
		$scope.completedSteps.push(step);
	}


	$rootScope.$on("addedNewCreditCard", function(event, response){
		try {
			$scope.$parent.creditCards = response.creditCards.array;
			$scope.formData.customerCreditCardID = response.addCreditCard.creditCard.customerCreditCardID
		} catch (error) {
			var rollbarDebugInfo = {
				response: response,
				error: error
			};
			Rollbar.error("POS Club Membership New Credit Card - response error",rollbarDebugInfo);
		}
	})

	$rootScope.$on("addedNewAddressBookEntry", function(event, response){
		$scope.formData.shipCustomerID = response.addAddressBookEntry.addressBookEntry.shipCustomerID;
		$scope.$parent.addressBook = response.addressBook.array;
		$scope.$parent.addressBook.push({
			shipCustomerID:"addNewAddress",
			nickName:"Add New Address",
			stringAddress:""
		})
	})


	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController
	$scope.listPickupLocations();
	$scope.listAddressBook();
	$scope.listClubCancellationReasons();
	$scope.listSalesAssociates();
	$scope.listCreditCards();
	$scope.viewClub();
});