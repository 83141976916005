pos.storeManager.controller("storeManager", function($scope, $rootScope, session, globalData, globalFunctions, orderService, cartService, loginService, $state, $animate){
	$rootScope.currentManager = {};
	$rootScope.currentManager.sectionTitle = "";
	$rootScope.currentManager.subSectionTitle = "";
	$rootScope.isRefund = globalFunctions.isRefund();
	$rootScope.hideBack = false;
	$scope.enableSearch = true;

	//Logic
	loginService.aboutPOS(function(){
		$rootScope.$emit("notificationListen", true);
	});

	requestReceiptPrinters();


	//Functions
	$scope.currentPage = function(){
		return $state.current.controller;
	};
	
	// Listeners
	$scope.$on('$locationChangeStart', function(event) {
		$rootScope.isRefund = globalFunctions.isRefund();
	});
});


pos.storeManager.controller("storeManagerSearch", function($scope, $rootScope, globalData, globalFunctions, orderService, loginService, $state, $animate){
	//Variables
	$scope.loading = true;

	//Logic
	$animate.enabled(false, angular.element(".storeSearch")); //Disabled Angular Animation

	//Listeners

	//Functions
	$scope.initSearch = function(){
		$scope.loading = true;
		orderService.listOrderSearchAttributes(function(response){
			$scope.loading = false;
			$scope.orderSearchAttributes = response;
			$scope.formData.orderType = "";
			$scope.formData.orderStatus = globalFunctions.findInObject($scope.orderSearchAttributes.orderStatuses,"default", "1")[0];
			$scope.formData.orderPaymentStatus = globalFunctions.findInObject($scope.orderSearchAttributes.orderPaymentStatuses,"orderPaymentStatus", "All Statuses")[0];
			$scope.formData.orderShippingStatus = globalFunctions.findInObject($scope.orderSearchAttributes.orderShippingStatuses,"orderShippingStatus", "All Statuses")[0];
			$scope.formData.salesAssociate = globalFunctions.findInObject($scope.orderSearchAttributes.salesAssociates,"salesAssociate", "All Sales Associates")[0];
			$scope.formData.webserviceVendor = globalFunctions.findInObject($scope.orderSearchAttributes.webserviceVendors,"webserviceVendor", "All Vendors")[0];
		});
	};
	$scope.initSearch();

	$scope.clear = function(){
		$("[name=searchForm]")[0].reset();
		$scope.formData = {};
		$scope.initSearch();
		$rootScope.$emit('search'+$state.current.controller);
	};

	$scope.searchSubmit = function(formData){
		$scope.search = false;
		$rootScope.$emit('search'+$state.current.controller, {
			orderType:formData.orderType.orderType,
			orderNumber:formData.orderNumber,
			billFirstName:formData.billFirstName,
			billLastName:formData.billLastName,
			shipFirstName:formData.billFirstName,
			shipLastName:formData.billLastName,
			fromDate:formData.fromDate,
			toDate:formData.toDate,
			isPickup:formData.isPickup,
			orderStatusID:formData.orderStatus.orderStatusID,
			orderPaymentStatusID:formData.orderPaymentStatus.orderPaymentStatusID,
			orderShippingStatusID:formData.orderShippingStatus.orderShippingStatusID,
			salesAssociateID:formData.salesAssociate.salesAssociateID,
			webserviceVendorID:formData.webserviceVendor.webserviceVendorID
		});
	};

});