pos.store.controller("product", function($scope, $rootScope, session, globalData, globalFunctions, productService, emvTerminalService, checkoutService, cartService){
	//Variables
	$scope.loading = true;
	$scope.products = globalData.products;
	$scope.categories = globalData.categories;
	$scope.isSearchOpen = false;
	$scope.paymentTypes = session.paymentTypes;
	$scope.alertLevel = "";
	$scope.alertMessage = "";

	//Logic
	productService.init(null, function(){
		//Removes Loading on this scope when its done loading.
		$scope.loading = false;
		
		// Sends alert if emvTerminal in localStorage is invalid
		if (globalData.posInfo.hasEMV) {
			emvTerminalService.validateEMVTerminal();
		}

		$rootScope.currentCategory = globalData.categories[0].label;
	});

	//Listeners
	$rootScope.$watch('closer', function(){
		if($rootScope.closer == false){
			$scope.closeDropdown();
			$scope.state.selected = undefined;
		}
	});

	$scope.state = { selected: undefined };
	//Functions
	$scope.openProductMenu = function(index){
		$rootScope.closer = true;
		$scope.state.selected = index;
	}

	$scope.closeProductMenu = function(){
		$rootScope.closer = false;
		$scope.state.selected = undefined;
	}

	$scope.searchFormSubmit = function(){
		$scope.loading = true;
		productService.search($scope.searchText, session.order.orderHeader.orderID, function(response){
			$scope.loading = false;
			$rootScope.currentCategory = "Search Results";
		});
	}

	$scope.activeClass = function(category) {
	    return category.label === $rootScope.currentCategory ? 'active' : undefined;
	};

	$scope.addToCart = function(product){
		$rootScope.$emit('cartLoading', true);
		$scope.alertLevel = "";
		$scope.alertMessage = "";
		cartService.addToCart(product, function(){
			if($scope.paymentTypes.length == 0){
				checkoutService.listPaymentTypes(function(){
					$rootScope.$emit('cartLoading', false);
				});
			} else {
				$rootScope.$emit('cartLoading', false);
			}
		});
	}

	$scope.startBarcodeScan = function(){
		startBarcodeScan();
	}

	$scope.closeDropdown = function(){
		$rootScope.closer = false;
		$scope.dropdown = "";
	}

	$scope.closeSearch = function(loadProducts){
		//Close Search
		$scope.isSearchOpen = false;
		$scope.searchText = "";
		$rootScope.currentCategory = globalData.categories[0].label;

		// setTimeout(function() {	angular.element('[name=searchText]').blur()}, 0);

		if(loadProducts){
			productService.listProducts(null, session.order.orderHeader.orderID, function(response){
				$scope.loading = false;
			});	
		}
	}

	//Someone Clicks a Category
	$scope.loadCategory = function(category){
		$rootScope.currentCategory = null; //Sets Category to null so it shows secondary value
		$scope.loading = true;
		$scope.closeDropdown();
		$scope.closeSearch(false);
		productService.listProducts(category.POSCategoryID, session.order.orderHeader.orderID, function(response){
			$rootScope.currentCategory = category.label;
			$scope.loading = false;
		});
	}

	$scope.multiSKU = function(product){
		$scope.loading = true;
		productService.retrieveProduct(product, session.order.orderHeader.orderID, function(response){
			$scope.loading = false;
			$scope.openModal("v2/views/partials/modals/multiSKU", response.product);
		});
	}

	$scope.openDropdown = function(){
		$rootScope.closer = true;
		$scope.dropdown = 'active';
	}



	$scope.toggleSearch = function(){
		if($scope.isSearchOpen == true){
			$scope.closeSearch(true);
		}else{
			//OpenSearch
			$scope.isSearchOpen = true;
		}
	}
	
	$rootScope.$on('storeError', function(event, data){
		$rootScope.$emit("cartLoading", false);
		$scope.alertLevel = data.alertLevel;
		$scope.alertMessage = data.alertMessage;
		globalFunctions.scrollTo("storeAlert");
	});

	$(document).on("click", ".toggleSearch", function(event){
		angular.element('[name=searchText]').focus();
	});

});

pos.store.controller("exchangeProduct", function($scope, $rootScope, session, globalData, globalFunctions, productService, checkoutService, orderService, $timeout){
	//Variables
	$scope.loading = true;
	$scope.products = globalData.products;
	$scope.categories = globalData.categories;
	$scope.isSearchOpen = false;
	$scope.paymentTypes = session.paymentTypes;
	$scope.state = { selected: undefined };

	//Logic


	//Functions
	$scope.exchangeProductInit = function(){
		productService.init(session.refund.refundHeader.refundOrderID, function(){
			//Removes Loading on this scope when its done loading.
			$scope.loading = false;
			$rootScope.currentCategory = globalData.categories[0].label;
		}, true, true);
	}
	
	$scope.openProductMenu = function(index){
		$rootScope.closer = true;
		$scope.state.selected = index;
	}

	$scope.closeProductMenu = function(){
		$rootScope.closer = false;
		$scope.state.selected = undefined;
	}

	$scope.searchFormSubmit = function(){
		$scope.loading = true;
		productService.search($scope.searchText, session.refund.refundHeader.refundOrderID, function(response){
			$scope.loading = false;
			$rootScope.currentCategory = "Search Results";
		}, true);
	}

	$scope.activeClass = function(category) {
	    return category.label === $rootScope.currentCategory ? 'active' : undefined;
	};

	$scope.addToCart = function(product){
		$rootScope.$emit('cartLoading', true);
		orderService.addExchangeItemToRefund(product, function(response){			
			if($scope.paymentTypes.length == 0){
				checkoutService.listPaymentTypes(function(){
					$rootScope.$emit("updateRefund", response.refund);
					$rootScope.$emit('cartLoading', false);
				});
			} else {
				$rootScope.$emit("updateRefund", response.refund);
				$rootScope.$emit('cartLoading', false);
			}
		});
	}

	$scope.startBarcodeScan = function(){
		startBarcodeScan();
	}

	$scope.closeDropdown = function(){
		$rootScope.closer = false;
		$scope.dropdown = "";
	}

	$scope.closeSearch = function(loadProducts){
		//Close Search
		$scope.isSearchOpen = false;
		$scope.searchText = "";
		$rootScope.currentCategory = globalData.categories[0].label;

		if(loadProducts){
			productService.listProducts(null, session.refund.refundHeader.refundOrderID, function(response){
				$scope.loading = false;
			}, true, false);
		}
	}

	//Someone Clicks a Category
	$scope.loadCategory = function(category){
		$rootScope.currentCategory = null; //Sets Category to null so it shows secondary value
		$scope.loading = true;
		$scope.closeDropdown();
		$scope.closeSearch(false);
		productService.listProducts(category.POSCategoryID, session.refund.refundHeader.refundOrderID, function(response){
			$rootScope.currentCategory = category.label;
			$scope.loading = false;
		}, true, true);
	}

	$scope.multiSKU = function(product){
		$scope.loading = true;
		productService.retrieveProduct(product, session.refund.refundHeader.refundOrderID, function(response){
			$scope.loading = false;
			$scope.openModal("v2/views/partials/modals/multiSKU", response.product);
		}, true);
	}

	$scope.openDropdown = function(){
		$rootScope.closer = true;
		$scope.dropdown = 'active';
	}

	$scope.toggleSearch = function(){
		if($scope.isSearchOpen == true){
			$scope.closeSearch(true);
		}else{
			//OpenSearch
			$scope.isSearchOpen = true;
		}
	}
	
	//Listeners
	$rootScope.$watch('closer', function(){
		if($rootScope.closer == false){
			$scope.closeDropdown();
			$scope.state.selected = undefined;
		}
	});
	
	$rootScope.$on("refundInitialized", function(event,data){
		$timeout(function(){
			$scope.exchangeProductInit();
		},100)
	});

	$(document).on("click", ".toggleSearch", function(event){
		angular.element('[name=searchText]').focus();
	});
});


pos.store.controller("multiSKU", function($scope, $rootScope, session, globalData, globalFunctions, checkoutService, cartService, orderService){
	//Variables
	$scope.modalTitle = "Multiple SKUs";
	$scope.modalSubmitTitle = "hide";
	$scope.paymentTypes = session.paymentTypes;
	$scope.modalData;

	//Logic
	$scope.$emit('modalLoading', false);


	//Functions
	$scope.addToCart = function(product){
		$scope.modalActionDefaults($scope.formData);
		$rootScope.$emit('cartLoading', true);
		$scope.closeModal();
		if(globalFunctions.isRefund()){
			orderService.addExchangeItemToRefund(product, function(response){			
				if($scope.paymentTypes.length == 0){
					checkoutService.listPaymentTypes(function(){
						$rootScope.$emit("updateRefund", response.refund);
						$rootScope.$emit('cartLoading', false);
					});
				} else {
					$rootScope.$emit("updateRefund", response.refund);
					$rootScope.$emit('cartLoading', false);
				}
			});
		} else{
			cartService.addToCart(product, function(){
				if($scope.paymentTypes.length == 0){
					checkoutService.listPaymentTypes(function(){
						$rootScope.$emit('cartLoading', false);
					});
				} else {
					$rootScope.$emit('cartLoading', false);
				}
			});
		}
	}

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.closeModal();
	};
});


pos.store.controller("multiProduct", function($scope, $rootScope, globalData, globalFunctions, checkoutService, cartService, orderService){
	//Variables
	$scope.modalTitle = "Multiple Product";
	$scope.modalSubmitTitle = "hide";
	$scope.modalData;

	//Logic 
	$scope.$emit('modalLoading', false);

	//Functions
	$scope.addToCart = function(product){
		$scope.modalActionDefaults($scope.formData);
		$rootScope.$emit('cartLoading', true);
		$scope.closeModal();
		if(globalFunctions.isRefund()){
			orderService.addExchangeItemToRefund(product, function(response){
				$rootScope.$emit("updateRefund", response.refund);
				$rootScope.$emit('cartLoading', false);
			});
		} else{
			cartService.addToCart(product, function(){
				$rootScope.$emit('cartLoading', false);
			});
		}
	}

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.closeModal();
	};
});