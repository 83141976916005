pos.store.controller("viewInventory", function($scope, $rootScope, session, globalData, globalFunctions, inventoryService){
	// Variables
	$scope.modalTitle = "View Inventory";
	$scope.modalSubmitTitle = "hide";
	$scope.modalData;
	$scope.inventoryPools = [];

	//Funtions
	$scope.listInventoryPools = function(){
		inventoryService.listInventoryPools(function(response){
			$scope.viewInventory(response.inventoryPools);
		})
	}

	$scope.viewInventory = function(inventoryPools){
		inventoryPools.forEach(function(element,index){
			inventoryService.viewInventory(element.inventoryPoolID, $scope.modalData.sku, function(response){
				var inventoryPool = element;
				inventoryPool.productSKU = response.productSKU;
				if(inventoryPool.productSKU.hasInventory){
					$scope.inventoryPools.push(inventoryPool);
				}
				if(inventoryPools.length-1 == index){
					$scope.$emit('modalLoading', false);
				}
			});
		});
	}

	//Logic
	$scope.listInventoryPools();

	//Listeners

});	