pos.store.factory("productService", function($http, session,globalData, globalFunctions, cacheService){
	var init = function(orderID, callback, isRefund, clearCache){
		var isRefund = isRefund || false;
		var clearCache = clearCache || false;
		
		var products = {};
		var posCategories = [];
		
		// When a customer is added to the order, their price level could change the price of the products, 
		// which means products need to be-refetched.
		if(globalData.posInfo.hasProductCaching && clearCache){
			cacheService.reset();
		} else if(globalData.posInfo.hasProductCaching){
			products = cacheService.fetch('productsByCategory');
			posCategories = cacheService.fetch('posCategories');
		}
		
		if(globalData.posInfo.hasProductCaching && products && posCategories.length){
			angular.copy(posCategories, globalData.categories);
			angular.copy(products[posCategories[0].POSCategoryID], globalData.products);
			
			(callback)();
		} else {
			globalFunctions.v65http("/store/index.cfm?method=products.init",{posProfile:session.posProfile, orderID:orderID, isRefund:isRefund},function(response){
				angular.copy(response.products, globalData.products); //Get Products
				angular.copy(response.categories, globalData.categories); //Get Products
				
				if(globalData.posInfo.hasProductCaching){
					products[response.categories[0].POSCategoryID] = response.products;
					
					cacheService.save('productsByCategory', products);
					cacheService.save('posCategories', response.categories);
				}
				
				(callback)(response);
			}, null, 60000)
		}
	}

	var listProducts = function(posCategoryID, orderID, callback, isRefund, clearCache){
		var isRefund = isRefund || false;
		var clearCache = clearCache || false;
		
		var products = {};
		
		if(globalData.posInfo.hasProductCaching && clearCache){
			cacheService.reset();
		} else if(globalData.posInfo.hasProductCaching){
			products = cacheService.fetch('productsByCategory');
		}
		
		if(globalData.posInfo.hasProductCaching && products && products[posCategoryID]){
			angular.copy(products[posCategoryID], globalData.products);
			if(callback) (callback)();
		} else {
			globalFunctions.v65http("/store/index.cfm?method=products.listProducts",{posCategoryID:posCategoryID, posProfile:session.posProfile, orderID:orderID, isRefund:isRefund},function(response){
				angular.copy(response.products, globalData.products); //Get Products
				
				if(globalData.posInfo.hasProductCaching){
					products[posCategoryID] = response.products;
					cacheService.save('productsByCategory', products);
				}
				
				if(callback) (callback)(response);
			}, null, 15000);
		}
	}

	var search = function(searchText, orderID, callback, isRefund){
		var isRefund = isRefund || false;
		globalFunctions.v65http("/store/index.cfm?method=products.listProducts",{posCategoryID:"search",productTitle:searchText, posProfile:session.posProfile, orderID:orderID, isRefund:isRefund}, function(response){
			angular.copy(response.products, globalData.products); //Get Products
			(callback)(response);
		}, null, 15000);
	}

	var retrieveProduct = function(product, orderID, callback, isRefund){
		var isRefund = isRefund || false;
		// /store/index.cfm?method=products.retrieveProduct&productID=844CB2CA-0E31-94A8-4B1D-6FC60B81AF9C
		globalFunctions.v65http("/store/index.cfm?method=products.retrieveProduct",{productID:product.productID, posProfile:session.posProfile, orderID:orderID, isRefund:isRefund}, function(response){
			(callback)(response);
		}, null, 15000)
	}

	return{
		init:				init,
		listProducts:		listProducts,
		search:				search,
		retrieveProduct:	retrieveProduct
	};
});