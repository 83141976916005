pos.store.factory("shippingService", function($http, session, globalData, globalFunctions){

	var addShippingAddress = function(	billAddress,
	billAddress2,
	billBirthDate,
	billBirthDay,
	billBirthMonth,
	billBirthYear,
	billCity,
	billCompany,
	billEmail,
	billFirstName,
	billLastName,
	billNickName,
	billPhone,
	billCountryCode,
	billStateCode,
	billZipCode,
	shipAddress,
	shipAddress2,
	shipBirthDate,
	shipBirthDay,
	shipBirthMonth,
	shipBirthYear,
	shipCity,
	shipCompany,
	shipEmail,
	shipFirstName,
	shipLastName,
	shipNickName,
	shipPhone,
	shipCountryCode,
	shipStateCode,
	shipZipCode,
	giftMessage,
	callback){

		globalFunctions.v65http("/store/index.cfm?method=shippingAddress.addShippingAddress", {
		billAddress:billAddress,
		billAddress2:billAddress2,
		billBirthDate:billBirthDate,
		billBirthDay:billBirthDay,
		billBirthMonth:billBirthMonth,
		billBirthYear:billBirthYear,
		billCity:billCity,
		billCompany:billCompany,
		billEmail:billEmail,
		billFirstName:billFirstName,
		billLastName:billLastName,
		billNickName:billFirstName+" "+billLastName,
		billPhone:billPhone,
		billCountryCode:billCountryCode,
		billStateCode:billStateCode,
		billZipCode:billZipCode,
		shipAddress:shipAddress,
		shipAddress2:shipAddress2,
		shipBirthDate:shipBirthDate,
		shipBirthDay:shipBirthDay,
		shipBirthMonth:shipBirthMonth,
		shipBirthYear:shipBirthYear,
		shipCity:shipCity,
		shipCompany:shipCompany,
		shipEmail:shipEmail,
		shipFirstName:shipFirstName,
		shipLastName:shipLastName,
		shipNickName:shipFirstName+" "+shipLastName,
		shipPhone:shipPhone,
		shipCountryCode:shipCountryCode,
		shipStateCode:shipStateCode,
		shipZipCode:shipZipCode,
		giftMessage:giftMessage,
		posProfile:session.posProfile,
		orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response);
			(callback)(response);
		}, null, null, 0);
	}

	var loadAddressBlock = function(countryCode){
		var addressBlock = {};

		switch(countryCode){
			case "CN":
				addressBlock = {
						birthDate:"生日 (Birth Date)",
						birthDay:"日 (Day)",
						birthMonth:"月 (Month)",
						birthYear:"年 (Year)",
						email:"电子邮件地址 (Email)",
						firstName:"名 (First Name)",
						lastName:"您的姓 (Last Name)",
						phone:"手机号码 (Phone)",
						company:"公司 (Company)",
						address:"街道地址 (Address)",
						address2:"街道地址 (Address 2)",
						city:"村/镇 (City)",
						country:"国家 (Country)",
						state:"州/区域 (State)",
						zipCode:"邮政编码 (Zip Code)",
						giftMessage:"订购注释 (Gift Message)"
				}
			break;
			default:
				addressBlock = {
						birthDate:"Birth Date",
						birthDay:"Day",
						birthMonth:"Month",
						birthYear:"Year",
						email:"Email",
						firstName:"First Name",
						lastName:"Last Name",
						phone:"Phone",
						company:"Company",
						address:"Address",
						address2:"Address 2",
						city:"City",
						country:"Country",
						state:"State",
						zipCode:"Zip Code",
						giftMessage:"Gift Message"
					}
		}

		return addressBlock;
	}

	var listAddressBook = function(customerID, callback){
		globalFunctions.v65http("/store/index.cfm?method=shippingAddress.listAddressBook", {customerID:customerID,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listShippingTypes = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=shippingTypes.listShippingTypes", {posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){

			//Don't send to fullfiment house comes in as blank, so we need to add the text...
			angular.forEach(response.fulfillment, function(value, key){
				if(response.fulfillment[key].fulfillmentHouse == ""){
					response.fulfillment[key].fulfillmentHouse = "Don't Send to Fulfillment";
				}
			});

			(callback)(response);
		}, null, 15000);
	}

	var overrideShipping = function(price, callback){
		globalFunctions.v65http("/store/index.cfm?method=cart.overrideShipping", {price:price, posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response); 
			(callback)(response);
		}, null, 15000)
	}
	
	var overrideExchangeShipping = function(price, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.overrideExchangeShipping", {price:price, posProfile:session.posProfile,refundOrderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response); 
			(callback)(response);
		}, null, 15000)
	}

	// var pickupOrder = function(callback){
	// 	globalFunctions.v65http("/store/index.cfm?method=shippingAddress.pickupOrder", null, function(response){
	// 		(callback)(response);
	// 	});
	// }

	// var retrieveAddressBookEntry = function(shipCustomerID, callback){
	// 	globalFunctions.v65http("/store/index.cfm?method=shippingAddress.retrieveAddressBookEntry", {shipCustomerID:shipCustomerID}, function(response){
	// 		(callback)(response);
	// 	});
	// }

	var removeShippingAddress = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=shippingAddress.removeShippingAddress", {posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response); 
			(callback)(response);
		}, null, 15000);
	}

	var selectShippingType = function(shippingStrategyID, shippingTypeID, fulfillmentHouse, callback){

		if(fulfillmentHouse == "Don't Send to Fulfillment"){
			fulfillmentHouse = "";
		}

		globalFunctions.v65http("/store/index.cfm?method=shippingTypes.selectShippingType", {shippingStrategyID:shippingStrategyID, shippingTypeID:shippingTypeID, fulfillmentHouse:fulfillmentHouse, posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response); 
			(callback)(response);
		}, null, 15000);
	}
	
	var selectFulfillment = function(fulfillmentHouse, callback){

		if(fulfillmentHouse == "Don't Send to Fulfillment"){
			fulfillmentHouse = "";
		}

		globalFunctions.v65http("/store/index.cfm?method=shippingTypes.selectFulfillment", {fulfillmentHouse:fulfillmentHouse, posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			_updateSession(response); 
			(callback)(response);
		});
	}

	// var shipOrder = function(callback){
	// 	globalFunctions.v65http("/store/index.cfm?method=shippingAddress.shipOrder", null, function(response){
	// 		(callback)(response);
	// 	});
	// }

	// var selectAddressBookEntry = function(shipCustomerID, callback){
	// 	globalFunctions.v65http("/store/index.cfm?method=shippingAddress.selectAddressBookEntry", {shipCustomerID:shipCustomerID}, function(response){
	// 		angular.copy(response.order, session.order);
	// 		(callback)(response);
	// 	});
	// }

	var viewShippingAddress = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=shippingAddress.viewShippingAddress", {posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			(callback)(response);
		}, null, 15000);
	}
	
	// Private
	var _updateSession = function(response){
		if(globalFunctions.isRefund()){
			angular.copy(response.refund, session.refund); 
		} else{
			angular.copy(response.order, session.order);
		}
	}

	return{
		addShippingAddress: 		addShippingAddress,
		listShippingTypes:			listShippingTypes,
		listAddressBook:			listAddressBook,
		loadAddressBlock:			loadAddressBlock,
		overrideShipping:			overrideShipping,
		overrideExchangeShipping: overrideExchangeShipping,
		selectShippingType:			selectShippingType,
		selectFulfillment:			selectFulfillment,
		removeShippingAddress:		removeShippingAddress,
		viewShippingAddress:		viewShippingAddress
	};
});