pos.factory('speedtestService', function() {


	// Constants
	var RANGE = 1000;
	var LOG_RANGE = Math.log(RANGE * 1.5);
	var DEFAULT_DELAY = 1000;
	var ABSOLUTE_MINDELAY = 10;
	var MINDELAY = DEFAULT_DELAY;

	var _now = function () {
		return window.performance.now();
	};
	// Private Properties
	var _running = true;
	var _lastTick = _now();
	var _lastStart = _lastTick;
	var _blips = [];
	var _lastBotch = 0;

	// Private Functions
	var _now = function () {
		return window.performance.now();
	};

	var _lastTick = _now();
	var _nextFrame =
		window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		function (callback) {
			setTimeout(callback, 1000 / 60);
		};

	// The mindelay calculation pings a mindelay URL and updates the delay between calls based on the response
	var _updateMinDelay = function () {
		$.getJSON('https://gfblip.appspot.com/mindelay?callback=?', function (data) {
			var newdelay = parseInt(data);
			if (newdelay >= ABSOLUTE_MINDELAY) {
				MINDELAY = newdelay;
			} else {
				MINDELAY = DEFAULT_DELAY;
			}

			// unfortunately this periodic update causes a periodic glitch in
			// the measurements... but it's important in case the server needs to slow
			// us down under load.
			setTimeout(_updateMinDelay, 60000);
		});
	}

	var _BlipCanvas = function (canvas) {
		this.canvas = canvas;
		this.canvas.height = 200;
		this.ctx = this.canvas.getContext('2d');
		this.xofs = 60;
		this.current_x = 0;
		this.xdiv = canvas.width / 1000;

		this.drawYAxis = function () {
			var labels = [2, 5, 10, 20, 50, 100, 200, 500, 1000];
			this.ctx.fillStyle = 'black';
			this.ctx.textBaseline = 'middle';
			this.ctx.textAlign = 'right';
			this.ctx.scale(3, 1);
			this.ctx.font = '8px Arial';
			for (var i = 0; i < labels.length; i++) {
				var msecs = labels[i];
				this.ctx.fillText(msecs, (this.xofs - 5) / 3, this.msecToY(msecs));
			}
			this.ctx.scale(1 / 3, 1);
		}

		this.nextX = function (msecs) {
			var steps = msecs / ABSOLUTE_MINDELAY;
			if (steps > 100) {
				steps = 100;
			}
			var x_inc = steps / this.xdiv;
			var new_x = (this.current_x + x_inc) % (this.canvas.width - this.xofs);

			// draw the new bar
			this.ctx.fillStyle = 'rgba(128,128,128,1.0)';
			this.ctx.fillRect(new_x + this.xofs + 1, 0,
				4, this.canvas.height);

			// wipe out the old bar
			this.ctx.fillStyle = 'rgba(255,255,255,1.0)';
			this.ctx.fillRect(this.current_x + this.xofs, 0,
				x_inc + 1, this.canvas.height);
			if (new_x < this.current_x) {
				this.ctx.fillRect(this.xofs, 0,
					new_x - 1, this.canvas.height);
			}
			this.current_x = new_x;
		}

		this.msecToY = function (msecs) {
			return this.canvas.height -
				(Math.log(msecs) * this.canvas.height / LOG_RANGE);
		}

		this.drawBlip = function (color, startTime, endTime, minlatency) {
			var msecs = endTime - startTime;
			if (msecs < minlatency) {
				// impossibly short; that implies we're not actually reaching the
				// remote end, probably because we're entirely offline
				_lastBotch = endTime;
			}
			if (endTime > 2100 && endTime - _lastBotch < 2100) {
				// if there were any "offline" problems recently, there might be
				// a bit of jitter where some of the requests are a bit slower than
				// the impossible timeout, but that doesn't mean it's working yet.
				// So stop reporting for a minimum amount of time.  During that time,
				// we just want to show an error.
				msecs = 1000;
			}
			var y = this.msecToY(msecs);
			var x = this.current_x + this.xofs;
			if (msecs >= RANGE) {
				this.ctx.fillStyle = '#f00';
				this.ctx.fillRect(x - 2, y - 1, 3, 4);
			}
			this.ctx.fillStyle = color;
			this.ctx.fillRect(x - 1, y - 3, 2, 6);
		}
	}
	var _c1 = new _BlipCanvas(document.getElementsByClassName('blipchart')[0]);
	var _addBlip = function (color, url, minlatency) {
		_blips.push({color: color, url: url, minlatency: minlatency});
	}

	var _gotBlip = function (color, url, minlatency, startTime) {
		var endTime = _now();
		_c1.drawBlip(color, startTime, endTime, minlatency);
		_addBlip(color, url, minlatency);
	}

	var _startBlips = function () {
		while (_blips.length) {
			var blip = _blips.shift();
			var createResult = function (blip) {
				var startTime = _now();
				var result = function () {
					_gotBlip(blip.color, blip.url, blip.minlatency, startTime);
				}
				return result;
			}
			var result = createResult(blip);
			$.ajax({
				'url': blip.url,
				crossDomain: false,
				timeout: RANGE
			}).complete(result);
		}
	};

	var _gotTick = function () {
		var t = _now();
		var tdiff = t - _lastTick;
		if (_running) {
			if (tdiff >= ABSOLUTE_MINDELAY) {
				if (t - _lastStart > MINDELAY) {
					_startBlips();
					_lastStart = t;
				}
				_c1.nextX(tdiff);
				_lastTick = t;
			}
			_nextFrame(_gotTick);
		}
	}

	// Public Functions
	var toggleBlip = function () {
		if (_running) {
			_running = 0;
		} else {
			_running = 1;
			_lastTick = _now();
			_nextFrame(_gotTick);
		}
	}

	var disableBlip = function () {
		_running = 0;
	}

	var initSpeedTestService = function() {

		_c1 = new _BlipCanvas(document.getElementsByClassName('blipchart')[0]);
		_updateMinDelay();
		_c1.drawYAxis();

		// Note that because gstatic.com latency is very low almost anywhere in the world,
		// blip will generate a higher volume of traffic (up to 100 queries per second per client!)
		// than most other sites you might ping.
		// Blip uses the mindelay server, up above in the mindelay calculation, to try to scale things back
		// if a lot of people run blip all at once.
		_addBlip('rgba(0,255,0,0.8)', 'https://gstatic.com/generate_204', 0);

		// Second blip for regional vin65 server with 5ms min latency
		// _addBlip('rgba(0,0,255,0.8)', 'https://vin65.com/', 5);
        _addBlip('rgba(0,0,255,0.8)', '/index.cfm?method=global.speedTest', 0);

		// _nextFrame(_gotTick);
	};

	return {
		initSpeedTestService: initSpeedTestService,
		toggleBlip: toggleBlip,
		disableBlip: disableBlip
	};
});