pos.service("auth0Service", auth0Service);

function auth0Service() {
	var service = this;
	
	service.logoutIframePromise = logoutIframePromise;
	
	function isIframeLoaded(resolve){
		var iframe = document.getElementById('auth0Logout');
		if(iframe){
			var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
			if (iframeDoc.readyState === 'complete') {
				resolve(true);
			}
		}
		setTimeout(function(){ isIframeLoaded(resolve) }, 100);
	};

	function logoutIframePromise(){
		return new Promise(function(resolve, reject){
			isIframeLoaded(resolve);
		});
	};
}