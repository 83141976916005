pos.store.controller("manageTables", function($scope, $rootScope, session, globalData, globalFunctions, tableService){
	//Variables	
	$scope.modalData;
	$scope.$emit('modalLoading', false);
	$scope.modalTitle = "Manage Tables";
	$scope.modalSubmitTitle = "Start Table";
	$scope.maxRows = 50;
	$scope.currentPage = 0;
	$scope.totalRecordCount = 0;
	$scope.posInfo = globalData.posInfo;
	$scope.session = session;

	//Logic
	$scope.$emit('modalLoading', false);

	if($scope.session.table.tableID){
		$scope.modalSubmitTitle = "hide";
	}

	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		if(($scope.formData.clubCount + $scope.formData.nonClubCount) == 0){
			$scope.modalButtonLoading = false;
			$scope.alertLevel = "error";
			$scope.alertMessage = "You need at least 1 person at the table.";
			$scope.$emit('modalLoading', false);
		}else{
			tableService.startTable($scope.formData.tableName,$scope.formData.clubCount,$scope.formData.nonClubCount, function(response){
				angular.copy(response.table, session.table);
				$scope.toggleMenu();
				$scope.closeModal();
			});
		}
	}
	
});



pos.store.controller("tableMenu", function($scope, $rootScope, session, globalData, globalFunctions, tableService, orderService, cartService){
	//Variables	


	//Logic


	//Functions
	$scope.holdTable = function(){
		$rootScope.$emit("cartLoading", true);
		orderService.holdOrder(session.table.tableName, null, function(response){
			cartService.init(null, function(){
				$rootScope.$emit("cartLoading", false);
				globalFunctions.clearSession();
			});
		});
	}

	$scope.sendTo = function(){
		alert("Send To Kitchen");
	}


});
	


	pos.store.controller("sendTo", function($scope, $rootScope, session, globalData, globalFunctions, tableService, orderService, checkoutService,settingsService){
		//Variables	
		$scope.modalData;
		$scope.$emit('modalLoading', false);
		$scope.modalTitle = "Send To Prep Area";
		$scope.modalSubmitTitle = "Send";
		$scope.maxRows = 50;
		$scope.currentPage = 0;
		$scope.totalRecordCount = 0;
		// $scope.posInfo = globalData.posInfo;
		$scope.session = session;
		$scope.itemsAvailable = [];
		$scope.prepAreas = [];
		$scope.printers = globalData.printers;
		$scope.additionalOrderData = globalData.additionalOrderData;
		$scope.ticketNumber = 0;

		//Functions

		$scope.getTicketNumber = function(prepArea) {
			$scope.ticketNumber = prepArea.ticketNumber;
		}

		$scope.listItemsAvailableToSend = function(tableID,callback){
			tableService.listItemsAvailableToSend(tableID, function(response){
				$scope.$emit('modalLoading', false);
				$scope.itemsAvailable = response.itemsAvailable.array;
				$scope.prepAreas = response.prepAreas.array;
				$scope.formData.prepArea = $scope.prepAreas[0];
				$scope.ticketNumber = $scope.formData.prepArea.ticketNumber;
			});
		}

		$scope.modalAction = function(){
			$scope.modalActionDefaults($scope.formData);
			tableService.sendItemsToPrepArea($scope.formData.tableID,$scope.formData.prepArea.prepAreaID,$scope.formData.itemsToSend, function(response){
				$scope.$emit('modalLoading', false);
				tableService.printReceipt(
					$scope.formData.tableID,
					$scope.formData.tableName,
					$scope.formData.prepArea.prepAreaID,
					$scope.formData.prepArea.location,
					$scope.formData.itemsToSend,
					$scope.formData.salesAssociate,
					session.table.clubCount,
					session.table.nonClubCount,
					session.order.orderHeader.orderID,
					response.order.orderNumber,
					$scope.formData.printer.macAddress,
					$scope.ticketNumber,
					$scope.formData.notes
				);
				$scope.closeModal();
			});
		}

		//Logic

		checkoutService.listAdditionalOrderData(function(){
			if(globalFunctions.findInObject($scope.additionalOrderData.salesAssociates,'default', 1).length){
				$scope.formData.salesAssociate = globalFunctions.findInObject($scope.additionalOrderData.salesAssociates,'default', 1)[0].salesAssociate;	
			}
		});

		settingsService.viewSettings($scope.printers, function(response){
			$scope.printers = response.printerArray;
		});

		$scope.$emit('modalLoading', true);
		$scope.listItemsAvailableToSend($scope.session.table.tableID);
		requestReceiptPrinters();
	});

pos.store.controller("manageKitchenTickets", function($scope, $rootScope, session, globalData, globalFunctions, tableService) {
	$scope.modalData;
	$scope.$emit('modalLoading', false);
	$scope.modalTitle = "Manage Orders Sent to Printer";
	$scope.pagination = { maxRows: 10, currentPage: 0, recordCount: 0 };
	$scope.session = session;
	$scope.currentSalesAssociateID = $scope.session.order.orderHeader.additionalOrderData.salesAssociateID;
	$scope.kitchenTickets = [];
	$scope.availableFilters = [];
	$scope.selectedFilterId = '';
	$scope.selectedFilterOptions = [];
	$scope.selectedFilterOptionValue = '';
	$scope.dateFilter = new Date().toJSON().slice(0, 10);
	$scope.requestFilters = {'date': $scope.dateFilter};
	$scope.formSelectedFilter = '';
	$scope.formSelectedOption = '';

	$scope.listKitchenTickets = function(currentPage, callback) {
		tableService.listKitchenTickets(false, currentPage + 1, $scope.pagination.maxRows, $scope.requestFilters, function(response) {
			$scope.$emit('modalLoading', false);
			$scope.kitchenTickets = response.kitchenTickets.array;
			$scope.availableFilters = response.filters;
			$scope.pagination.recordCount = response.kitchenTickets.pagination.totalRecordCount;
			$scope.pagination.currentPage = currentPage;
			if(callback) (callback)(response);
		});
	}

	$scope.numberOfPages = function(){
		return Math.ceil($scope.pagination.recordCount / $scope.pagination.maxRows);
	}

	$scope.setSelectedFilterId = function(selectedFilterId) {
		// Clear form values.
		$scope.formSelectedFilter = '';
		$scope.formSelectedOption = '';
		$scope.selectedFilterOptionValue = '';
		$scope.selectedFilterOptions = [];
		$scope.selectedFilterId = selectedFilterId;
	}

	$scope.setSelectedFilterOptionValue = function(selectedFilterOptionValue) {
		$scope.selectedFilterOptionValue = selectedFilterOptionValue;
	}

	$scope.setSelectedFilterOptions = function(selectedFilterId) {
		$scope.setSelectedFilterId(selectedFilterId);
		$scope.availableFilters.forEach(function(filter) {
			if (filter.id == $scope.selectedFilterId) {
				$scope.selectedFilterOptions = filter.options;
			}
		});
	}

	$scope.applyMyTicketsFilter = function() {
		$scope.setSelectedFilterId('salesAssociateID');
		$scope.setSelectedFilterOptions($scope.selectedFilterId);
		$scope.applyFilter($scope.currentSalesAssociateID);
	}

	$scope.applyFilter = function(selectedFilterValue) {
		$scope.loading = true;

		$scope.requestFilters = {};
		$scope.formSelectedFilter = '';
		$scope.formSelectedOption = '';
		$scope.setSelectedFilterOptionValue(selectedFilterValue);
		
		// Add default date filter.
		$scope.requestFilters['date'] = $scope.dateFilter;
		// Add selected filter.
		$scope.requestFilters[$scope.selectedFilterId] = selectedFilterValue;
		
		// Reload ticket list.
		$scope.listKitchenTickets(0, function() {
			$scope.loading = false;
			globalFunctions.scrollTo("alert");
		});
	}

	$scope.goToPage = function(page){
		$scope.loading = true;
		$scope.listKitchenTickets(page, function(){
			$scope.loading = false;
			globalFunctions.scrollTo("alert");
		});
	}

	$scope.viewKitchenTicket = function(ticket) {
		tableService.printReceipt(
			ticket.tableID,
			ticket.tableName,
			ticket.prepAreaID,
			ticket.location,
			ticket.items,
			ticket.salesAssociate,
			ticket.clubCount,
			ticket.nonClubCount,
			ticket.orderID,
			ticket.orderNumber,
			'',
			ticket.ticketNumber,
			'',
			'html'
		);
	}

	$scope.$emit('modalLoading', true);
	$scope.listKitchenTickets(0);
});

pos.store.controller("sendKitchenTicket", function($scope, $rootScope, session, globalData, globalFunctions, tableService, settingsService) {
	$scope.$emit('modalLoading', false);
	$scope.modalTitle = "Send Kitchen Ticket";
	$scope.modalSubmitTitle = "Send";
	$scope.session = session;
	$scope.prepOrderID = $scope.modalData.prepOrderID;
	$scope.kitchenTicketData = [];
	$scope.printers = globalData.printers;

	$scope.loadKitchenTicket = function(prepOrderID, callback) {
		tableService.listKitchenTickets(prepOrderID, 1, 1, {}, function(response) {
			$scope.$emit('modalLoading', false);
			$scope.kitchenTicketData = response.kitchenTickets.array[0];
		});
	}

	$scope.modalAction = function() {
		tableService.printReceipt(
			$scope.kitchenTicketData.tableID,
			$scope.kitchenTicketData.tableName,
			$scope.kitchenTicketData.prepAreaID,
			$scope.kitchenTicketData.location,
			$scope.kitchenTicketData.items,
			$scope.kitchenTicketData.salesAssociate,
			$scope.kitchenTicketData.clubCount,
			$scope.kitchenTicketData.nonClubCount,
			$scope.kitchenTicketData.orderID,
			$scope.kitchenTicketData.orderNumber,
			$scope.formData.printer.macAddress,
			$scope.kitchenTicketData.ticketNumber,
			$scope.formData.notes
		);
		$scope.closeModal();
	}

	settingsService.viewSettings($scope.printers, function(response) {
		$scope.printers = response.printerArray;
	});

	$scope.$emit('modalLoading', true);
	$scope.loadKitchenTicket($scope.prepOrderID);
	requestReceiptPrinters();
});
