
pos.store.factory("contactTypeService", function($http, session,globalData, globalFunctions){

	var listContactTypes = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=contactTypes.listContactTypes", {page:page, maxRows:maxRows, customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var editContactType = function(contactTypeID, process, callback){
		globalFunctions.v65http("/customer/index.cfm?method=contactTypes.editContactType", {contactTypeID:contactTypeID, process:process,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}


	return{
		editContactType:	editContactType,
		listContactTypes:	listContactTypes
	};
});