
pos.store.factory("creditCardService", function($http, session, globalData, globalFunctions){

	var addCreditCard = function(cardExpiryMo,	cardExpiryYr,	cardNumber,	isPrimary,	nameOnCard, clubMemberships, callback){

		globalFunctions.v65http("/customer/index.cfm?method=creditCards.addCreditCard",{cardExpiryMo:cardExpiryMo,
		cardExpiryYr:cardExpiryYr,
		cardNumber:cardNumber,
		isPrimary:isPrimary,
		nameOnCard:nameOnCard,
		clubMemberships:clubMemberships,
		customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var deleteCreditCard = function(customerCreditCardID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=creditCards.deleteCreditCard",{customerCreditCardID:customerCreditCardID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var editCreditCard = function(cardExpiryMo,	cardExpiryYr,	cardNumber, customerCreditCardID,	isPrimary,	nameOnCard, clubMemberships, callback){
		globalFunctions.v65http("/customer/index.cfm?method=creditCards.editCreditCard",{cardExpiryMo:cardExpiryMo,
		cardExpiryYr:cardExpiryYr,
		cardNumber:cardNumber,
		customerCreditCardID:customerCreditCardID,
		isPrimary:isPrimary,
		nameOnCard:nameOnCard,
		clubMemberships:clubMemberships,
		customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listCreditCards = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=creditCards.listCreditCards", {page:page, maxRows:maxRows,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var swipeCreditCard = function(cardSwipe, callback){
		globalFunctions.v65http("/customer/index.cfm?method=creditCards.swipeCreditCard",{cardSwipe:cardSwipe,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, null, 0);
	}

	var viewCreditCard = function(customerCreditCardID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=creditCards.viewCreditCard",{customerCreditCardID:customerCreditCardID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	return{
		addCreditCard:	addCreditCard,
		deleteCreditCard:	deleteCreditCard,
		editCreditCard:	editCreditCard,
		listCreditCards:	listCreditCards,
		swipeCreditCard:	swipeCreditCard,
		viewCreditCard:	viewCreditCard
	};
});