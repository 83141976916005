
pos.store.factory("addressBookService", function($http, session, globalData, globalFunctions){

var addAddressBookEntry = function(address,	address2,	birthDate,	birthDay,	birthMonth,	birthYear,	city,	company,	email,	firstName,	isPrimary,	lastName,	nickName,	phone,	countryCode, stateCode,	stringAddress,	zipCode, callback){
		globalFunctions.v65http("/customer/index.cfm?method=addressBook.addAddressBookEntry",{address:address,
		address2:address2,
		birthDate:birthDate,
		birthDay:birthDay,
		birthMonth:birthMonth,
		birthYear:birthYear,
		city:city,
		company:company,
		email:email,
		firstName:firstName,
		isPrimary:isPrimary,
		lastName:lastName,
		nickName:nickName,
		phone:phone,
		countryCode:countryCode,
		stateCode:stateCode,
		stringAddress:stringAddress,
		zipCode:zipCode,
		customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, null, 0);
	}

	var deleteAddressBookEntry = function(shipCustomerID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=addressBook.deleteAddressBookEntry",{shipCustomerID:shipCustomerID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}


	var editAddressBookEntry = function(address,	address2,	birthDate,	birthDay,	birthMonth,	birthYear,	city,	company,	email,	firstName,	isPrimary,	lastName,	nickName,	phone,	shipCustomerID, countryCode, stateCode,	stringAddress,	zipCode, callback){
		globalFunctions.v65http("/customer/index.cfm?method=addressBook.editAddressBookEntry",{address:address,
		address2:address2,
		birthDate:birthDate,
		birthDay:birthDay,
		birthMonth:birthMonth,
		birthYear:birthYear,
		city:city,
		company:company,
		email:email,
		firstName:firstName,
		isPrimary:isPrimary,
		lastName:lastName,
		nickName:nickName,
		phone:phone,
		shipCustomerID:shipCustomerID,
		countryCode:countryCode,
		stateCode:stateCode,
		stringAddress:stringAddress,
		zipCode:zipCode,
		customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listAddressBook = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=addressBook.listAddressBook", {page:page, maxRows:maxRows,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var viewAddressBookEntry = function(shipCustomerID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=addressBook.viewAddressBookEntry",{shipCustomerID:shipCustomerID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	return{
		addAddressBookEntry:	addAddressBookEntry,
		deleteAddressBookEntry:	deleteAddressBookEntry,
		editAddressBookEntry:	editAddressBookEntry,
		listAddressBook:		listAddressBook,
		viewAddressBookEntry:	viewAddressBookEntry
	};
});