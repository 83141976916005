pos.store.controller("charge", function (
	$scope,
	$rootScope,
	$localStorage,
	$timeout,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	//Variables
	$scope.formData = {};
	$scope.posInfo = globalData.posInfo;
	$scope.paymentTypes = session.paymentTypes;
	$scope.storage = $localStorage;
	$scope.session = session;
	$rootScope.isSplitPayment = 0;
	$rootScope.splitCount = 0;
	$scope.checkingCompliance = false;
	$scope.hasErrorDetails = false;
	$scope.showErrorDetails = false;
	$scope.vivoPayInEmulateMode = false;

	//Listeners
	$scope.$watch("chargeOpen", function (data) {
		if (globalData.posInfo.posPaymentFix) {
			if ($scope.formData) {
				$scope.formData.cardNumber = '';
				$scope.formData.cardExpiryMo = '';
				$scope.formData.cardExpiryYr = '';
				$scope.formData.CVV2 = '';
				$scope.formData.nameOnCard = '';
			}
		}

		$scope.alertLevel = "";
		$scope.alertMessage = "";
		$scope.alertMessageDetails = "";
		$scope.formData.amountTendered = "";
		$scope.updateVariables();
		$scope.formData.maxValue = $scope.header
			? Number(($scope.header.amountDueCents / 100).toFixed(2))
			: 0;
		if (data == true) {
			if (
				$scope.header.shipping &&
				$scope.posInfo.hasComplianceWarnings
			) {
				$scope.checkingCompliance = true;
				$scope.complianceErrors = [];
				checkoutService.precheckCompliance(function (response) {
					$scope.complianceErrors = response.complianceErrors;
					$scope.checkingCompliance = false;
				});
			}

			checkoutService.listAdditionalOrderData();
			globalFunctions.swipe("creditCard", true);
		} else {
			globalFunctions.swipe("creditCard", false);
			globalFunctions.setProcessingFlag(false);
		}

		//True means it's opening, false it's closing.
	});

	$rootScope.$on("cancelTender", function (event, data) {
		$scope.formData.maxValue = $scope.formData.amountTendered = Number(
			($scope.header.amountDueCents / 100).toFixed(2)
		);
	});

	$rootScope.$on("chargeLoading", function (event, data) {
		$scope.loading = data;
	});

	$rootScope.$on("clearAmountTendered", function (event, data) {
		$scope.updateVariables();
		$scope.hasErrorDetails = false;
		$scope.showErrorDetails = false;
		$scope.alertMessageDetails = '';
		$scope.alertLevel = "success";
		$scope.alertMessage =
			"The payment is complete, review the remaining amount.";
		$scope.formData.maxValue = $scope.formData.amountTendered = Number(
			($scope.header.amountDueCents / 100).toFixed(2)
		);
		globalFunctions.swipe("creditCard", true);
	});

	$rootScope.$on("chargeError", function (event, data) {
		$scope.updateVariables();
		$scope.alertLevel = data.alertLevel;
		$scope.alertMessage = data.alertMessage;
		$scope.$emit("chargeLoading", false);
		if ((data.alertMessage == "Invalid Credit Card")) {
			globalFunctions.swipe("creditCard", true);
		}
		$rootScope.$emit("closeModal");
		$rootScope.isSplitPayment = 1;
	});

	$rootScope.$on("updateChargeVariables", function (event, data) {
		$scope.updateVariables();
	});

	//Functions
	$scope.toggleVivoPayInEmulateMode = function () {
		$scope.vivoPayInEmulateMode = !$scope.vivoPayInEmulateMode;
		console.log("Vivo Pay In Emulate Mode: " + $scope.vivoPayInEmulateMode);
	}

	$scope.getAmountTendered = function () {
		if ($rootScope.isSplitPayment == 1 && $scope.formData.amountTendered) {
			return $scope.formData.amountTendered.toFixed(2);
		} else {
			return ($scope.header.amountDueCents / 100).toFixed(2);
		}
	};

	$scope.getLoyaltyPointsInfo = function () {
		var loyaltyPointsInfo = {
			loyaltyPointsRedemptionRate:
				$scope.header.loyaltyPointsRedemptionRate,
			contactsPointBalance: $scope.customer.loyaltyPoints.balance,
			maxRedeemableLoyaltyPoints:
				$scope.header.maxRedeemableLoyaltyPoints,
			maxRedeemableLoyaltyPointsInDollars:
				$scope.header.maxRedeemableLoyaltyPointsInDollars,
		};

		return loyaltyPointsInfo;
	};

	$scope.splitPayment = function () {
		$rootScope.isSplitPayment = 1;
	};

	$scope.removeOrderTender = function (orderTender) {
		$scope.$emit("chargeLoading", true);
		checkoutService.removeOrderTender(orderTender.orderTenderID, function (
			response
		) {
			$scope.$emit("chargeLoading", false);
			//callback
			globalFunctions.swipe("creditCard", true);
			// Update amountTendered
			$scope.updateVariables();
			$scope.formData.maxValue = Number(
				($scope.header.amountDueCents / 100).toFixed(2)
			);
			// Timeout is required to solve race condition
			$timeout(function () {
				$scope.formData.amountTendered = $scope.formData.maxValue;
			}, 25);

			if (response.removeOrderTender.alertLevel == 'success') {
				$scope.alertLevel = response.removeOrderTender.alertLevel;
				$scope.alertMessage = response.removeOrderTender.alertMessage;
			}
		});
	};

	$scope.payWithTerminal = function (paymentTerminal, amountTendered) {
		$scope.$emit("chargeLoading", true);
		checkoutService.payWithTerminalStart(function (response) {
			$scope.$emit("chargeLoading", false);
			$scope.openModal(response.nextModal, {
				amountTendered: amountTendered,
			});
		});
	};

	$scope.payWithEMVTerminal = function (amountTendered) {
		$scope.$emit("chargeLoading", true);
		checkoutService.payWithEMVTerminalStart(function (response) {
			$scope.$emit("chargeLoading", false);
			$scope.openModal(response.nextModal, {
				amountTendered: amountTendered,
			});
		});
	};
	
	$scope.payWithVivoTerminal = function (paymentTerminal, amountTendered) {
		$scope.$emit("chargeLoading", true);
		$scope.alertLevel = "";
		$scope.alertMessage = "";
		checkoutService.payWithVivoTerminalStart(function (response) {
			$scope.$emit("chargeLoading", false);
			$scope.openModal(response.nextModal, {
				amountTendered: amountTendered,
				tip: 0,
				isVivoPay: true
			});
		});
	};
	
	$scope.closeChargeWindow = function () {
		$rootScope.$emit("chargeOpen", false);
		$scope.alertLevel = "";
		$scope.alertMessage = "";
		$scope.hasErrorDetails = false;
		$scope.showErrorDetails = false;
		$scope.alertMessageDetails = '';
	};

	$scope.updateVariables = function () {
		$scope.isRefund = globalFunctions.isRefund();
		if ($scope.isRefund) {
			// We dont want this to error if the refund hasn't fully been instantiated yet
			$scope.customer = session.refund.refundHeader
				? session.refund.refundHeader.billCustomer
				: session.customer;
			$scope.header = session.refund.refundHeader;
			$scope.order = session.refund;
		} else {
			$scope.customer = session.customer;
			$scope.header = session.order.orderHeader;
			$scope.order = session.order;
		}
	};

	//Logic
	$scope.cardSwipe = function (swipe) {
		cardSwipe(swipe);
	};

	$scope.updateVariables();
});

pos.store.filter('counter', [function() {
	return function(seconds) {
		return new Date(1970, 0, 1).setSeconds(seconds);
	};
}])

//Pay With Terminal
pos.store.controller("payWithEMV", function (
	$scope,
	$rootScope,
	$localStorage,
	session,
	globalFunctions,
	checkoutService,
	$interval
) {
	//Global settings
	globalFunctions.setProcessingFlag(true);
	$scope.$emit("modalLoading", false);

	//Variables
	$scope.modalTitle = "Pay By Terminal";
	$scope.modalCancelTitle = "hide";
	$scope.modalSubmitTitle = "hide";
	$scope.maxRetryAttempts = 4;
	$scope.retryAttempts = 0;
	$scope.counterDefaultValue = 303; // 5:05 Min
	$scope.counter = $scope.counterDefaultValue;

	$scope.order = session.order;
	$scope.storage = $localStorage;
	$scope.emvTerminal = $scope.storage.emvTerminal;
	$scope.amountDue = $scope.modalData.amountTendered;
	$scope.previousOrderNumber = globalFunctions.isRefund()
		? session.refund.refundHeader.orderInformation.previousOrderNumber
		: '';
	$scope.orderID = globalFunctions.isRefund()
		? session.refund.refundHeader.refundOrderID
		: session.order.orderHeader.orderID;
	$scope.orderNumber = globalFunctions.isRefund()
		? session.refund.refundHeader.orderInformation.orderNumber
		: session.order.orderHeader.orderNumber;
	$scope.orderType = globalFunctions.isRefund()
		? session.refund.refundHeader.orderInformation.orderType
		: session.order.orderHeader.additionalOrderData.orderType;
	$scope.splitCount = globalFunctions.isRefund()
		? session.refund.refundHeader.orderTenders.length
		: session.order.orderHeader.orderTenders.length;

	//Functions
	$scope.startCountdown = function() {
		$rootScope.countdown = $interval(function() {
			if (angular.isDefined($rootScope.countdown)) {
				if ($scope.counter <= 0) {
					$scope.stopAndRestore();
				}
			}
			$scope.counter--;
		}, 1000);
	};
	$scope.restoreCountDown = function() {
		$scope.counter = $scope.counterDefaultValue;
	};
	$scope.stopCountdown = function () {
		$interval.cancel($rootScope.countdown);
	}
	$scope.stopAndRestore = function () {
		$scope.stopCountdown();
		$scope.restoreCountDown();
	};
	$scope.countRetryAttempts = function () {
		$scope.retryAttempts++;
	};
	$scope.revertAllCounters = function () {
		$scope.retryAttempts = 0;
		$scope.stopAndRestore();
	};
	$scope.$on('$destroy', $scope.revertAllCounters());
	$rootScope.$on('modalError', $scope.revertAllCounters());

	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
	};

	$scope.closeModal = function () {
		$scope.clearModalAlerts();
		$scope.$parent.closeModal();
	};

	$scope.sendTransactionToTerminal = function (isRetry) {
		$scope.startCountdown();
		var failedTender = '';
		var lastTender = session.order.orderHeader.orderTenders.pop();
		if (isRetry && lastTender.orderPaymentStatusID == '3BF8C622-13A3-463E-8677-E2515711C951') {
			failedTender = lastTender.orderTenderID;
		}
		checkoutService.payWithEMVTerminal(
			$scope.amountDue,
			$scope.emvTerminal.terminalID,
			$scope.emvTerminal.locationID,
			session.posProfile.zeamsterProductTransactionID,
			$scope.orderID,
			$scope.emvTerminal.nickName,
			"sale",
			$scope.orderNumber,
			$scope.previousOrderNumber,
			$scope.orderType,
			$rootScope.isSplitPayment,
			$scope.splitCount,
			typeof(isRetry) == 'undefined' ? false : true,
			$scope.retryAttempts,
			failedTender,
			function (response) {
				$scope.stopCountdown();
				// Update charge so tender shows up
				$rootScope.$emit("updateChargeVariables", true);

				if (response.nextModal) {
					$scope.openModal(response.nextModal, {
						orderTenderID: response.orderTenderID,
						amountTendered: $scope.modalData.amountTendered,
						authCode: response.authCode,
						cardNumber: response.cardNumber,
						cardType: response.cardType
					});
				} else {
					// Close modal and display decline error on charge screen
					$scope.closeModal();
				}
			}
		);
	}

	$scope.retryTransaction = function () {
		$scope.modalCancelTitle = "hide";
		$scope.clearModalAlerts();
		$scope.countRetryAttempts();
		if ($scope.retryAttempts < $scope.maxRetryAttempts) {
			$scope.stopAndRestore();
			$scope.sendTransactionToTerminal(true);
		} else {
			$scope.modalCancelTitle = "Close";
			globalFunctions.alertMessage("charge", "error", "Sorry we could not process the order.");
			$scope.stopCountdown();
		}
	};

	//Initiate Sale
	$scope.sendTransactionToTerminal();
});


//Pay With Terminal
pos.store.controller("payWithVivo", function (
	$scope,
	$rootScope,
	$localStorage,
	session,
	globalData,
	globalFunctions,
	checkoutService,
	emvTerminalService
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.modalTitle = "Pay with BT Reader";
	$scope.modalCancelTitle = "";
	$scope.modalSubmitTitle = "Connect";
	$scope.order = session.order;
	$scope.deviceName = "";
	$scope.processingChip = false;
	$scope.inactive = false;
	$scope.storage = $localStorage;
	$scope.emvTerminal = $scope.storage.emvTerminal;
	$scope.amountDue = $scope.modalData.amountTendered;
	$scope.tip = $scope.modalData.tip || 0;
	$scope.deviceMessage = "";
	$scope.orderTenderID = "";
	$scope.orderNumber = globalFunctions.isRefund()
		? session.refund.refundHeader.orderInformation.orderNumber
		: session.order.orderHeader.orderNumber;
	$scope.orderType = globalFunctions.isRefund()
		? session.refund.refundHeader.orderInformation.orderType
		: session.order.orderHeader.additionalOrderData.orderType;
	$scope.splitCount = globalFunctions.isRefund()
		? session.refund.refundHeader.orderTenders.length
		: session.order.orderHeader.orderTenders.length;
	$scope.orderID = globalFunctions.isRefund()
		? session.refund.refundHeader.refundOrderID
		: session.order.orderHeader.orderID;
	$scope.previousOrderNumber = globalFunctions.isRefund()
		? session.refund.refundHeader.orderInformation.previousOrderNumber
		: '';

	//Functions
	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
	};

	$scope.closeModal = function (cancelTender) {
		$scope.modalActionDefaults($scope.formData)
		if($scope.deviceName !== "" && cancelTender) {
			cancelVivoTerminal("CANCELED MANUALLY", false);
		}
		$scope.clearModalAlerts();
		$scope.$parent.closeModal();
	};

	$scope.scanDevices = function () {
		$scope.inactive = true;
		$scope.deviceMessage = 'Please wait ...';
		$scope.clearModalAlerts();
		console.log("Scanning devices ...");
		var developerID = globalData.posInfo.paymentMode == 'live' ? 'winedir1' : 'HaQ4rxTR'
		var apiURL = globalData.posInfo.paymentMode == 'live' ? 'api.zeamster.com' : 'api.sandbox.zeamster.com';
		var globalUsername = globalData.posInfo.paymentUsername;
		var globalPassword = globalData.posInfo.paymentPassword;
		var serialNumber = $scope.storage.emvTerminal.serialNumber.substring(3) || "";
		var deviceName = "IDTECH-VP3300-" + serialNumber;
		console.log(deviceName)
		var endpoint = "/v2/transactions";
		var protocol = "https"

		var data =  'API_Protocol=' + protocol +
					'&API_Host_Name='+ apiURL +
					'&API_Endpoint_Path='+ endpoint +
					'&User_Id=' + globalUsername +
					'&User_API_Key='+ globalPassword +
					(serialNumber !== "" ?  '&Device_Name=' + deviceName : "") +
					'&Developer_Id=' + developerID;

		scanVivoTerminal(data);
	};

	$scope.chipTransaction = function () {
		$scope.inactive = true;
		$scope.clearModalAlerts();
		console.log("Chip devices ...");
		$scope.processingChip = true;
		var isSplit = $rootScope.isSplitPayment;

		// Avoid duplicate charge in terminals
		var tender = globalData.posInfo.paymentMode == 'live' ? '' : globalData.posInfo.websiteName;
		if (isSplit == 1) {
			tender = 'split' + $scope.amountDue + $scope.splitCount;
		}

		checkoutService.payWithVivoTerminalInit(
			$scope.amountDue,
			$scope.tip,
			$scope.emvTerminal.terminalID,
			$scope.emvTerminal.locationID,
			session.posProfile.zeamsterProductTransactionID,
			$scope.emvTerminal.nickName,
			$scope.orderID,
			$scope.orderNumber,
			$scope.orderType,
			$scope.previousOrderNumber,
			function(response){
				if (typeof response.transactionData === "undefined") {
					console.log('Error');
					$scope.closeModal();
					return;
				}

				$scope.orderTenderID = response.transactionData.orderTenderID;

				var data = 'transaction_amount=' + $scope.amountDue +
					'&payment_method='+ "cc" +
					'&transaction_api_id=' + response.transactionData.orderTenderID +
					'&location_id=' +$scope.emvTerminal.locationID +
					'&transaction_type='+ "sale" +
					'&product_transaction_id=' + session.posProfile.zeamsterProductTransactionID +
					'&order_num=' + $scope.orderNumber +
					'&tip_amount=' + $scope.tip +
					'&timeout=' + true +
					'&idleTimeout=' + 10 +
					'&sleepTimeout=' + 90;

				processVivoTerminal(data);
			}
		);
	};

	$scope.updateVivoTerminal = function() {
		// Update charge so tender shows up
		console.log('Updating new method')
		$rootScope.$emit("updateChargeVariables", true);
	}

	//Logic
	$scope.$emit("modalLoading", false);
	$scope.scanDevices();
});

//Pay With Terminal
pos.store.controller("insertCard", function (
	$scope,
	$rootScope,
	$timeout,
	$localStorage,
	session,
	globalData,
	globalFunctions,
	checkoutService,
	paymentService,
	Rollbar
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.modalTitle = "Terminal Activated";
	$scope.modalSubmitTitle = "hide";
	$scope.order = session.order;
	$scope.storage = $localStorage;
	$scope.paymentTerminal = $scope.storage.paymentTerminal;
	$scope.paymentCalled = 0;
	$scope.amountDue = $scope.modalData.amountTendered;
	$scope.paymentRequestID = "";
	$scope.orderNumber = globalFunctions.isRefund()
		? session.refund.refundHeader.orderInformation.orderNumber
		: session.order.orderHeader.orderNumber;
	$scope.orderType = globalFunctions.isRefund()
		? session.refund.refundHeader.orderInformation.orderType
		: session.order.orderHeader.additionalOrderData.orderType;

	//Functions
	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		$rootScope.$broadcast("cancelTimer", $scope.order);
	};

	$scope.closeModal = function () {
		$rootScope.$broadcast("cancelTimer", $scope.order);
		$scope.$emit("modalLoading", true);
		paymentService.cancelPaymentRequest($scope.paymentRequestID, function (
			response
		) {
			$scope.$parent.closeModal();
		});
	};

	//Logic
	$scope.$emit("modalLoading", false);

	//Initiate Sale
	paymentService.initiatePaymentRequest(
		$scope.amountDue,
		$scope.storage.paymentTerminal.paymentTerminalKey,
		"cc:sale",
		$scope.orderNumber,
		"",
		function (response) {
			if ($scope.paymentCalled == 0) {
				$scope.paymentCalled = 1;
				$scope.paymentRequestID = response.paymentRequestID;
				$scope.getPaymentStatus();
			} else {
				Rollbar.debug("Service was already called", $scope.order);
			}
		}
	);

	$scope.getPaymentStatus = function () {
		paymentService.getPaymentStatus($scope.paymentRequestID, function (
			response
		) {
			checkoutService.payWithTerminal(
				$scope.modalData.amountTendered, //amountTendered
				$scope.modalData.tip, //tip
				response.paymentStatusResponse.transaction.authcode, //authCode
				response.paymentStatusResponse.transaction.refnum, //transactionID
				response.paymentStatusResponse.transaction.result, //reasonCode
				response.paymentStatusResponse.transaction.creditcard.number, //cardNumber
				$scope.paymentTerminal, //paymentTerminal
				response.paymentStatusResponse.transaction.creditcard.type, //cardType
				response.paymentStatusResponse.transaction.creditcard
					.cardholder || "", //nameOnCard
				function (response) {
					$scope.openModal(response.nextModal, {
						orderTenderID: response.orderTenderID,
						amountTendered: $scope.modalData.amountTendered,
						authCode: response.authCode,
						cardNumber: response.cardNumber,
						cardType: response.cardType,
					});
				}
			);
		});
	};

	$scope.retryPaymentStatus = function () {
		$scope.clearModalAlerts();
		$scope.getPaymentStatus();
	};
});

//Pay With Custom Type
pos.store.controller("payWithCustomPaymentType", function (
	$scope,
	$rootScope,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.modalData;
	$scope.modalTitle = "Pay With " + $scope.modalData.paymenttype;
	$scope.modalSubmitTitle = "Process";
	$scope.order = session.order;

	//Logic
	$scope.$emit("modalLoading", false);

	//Functions
	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		checkoutService.alternatePaymentMethod(
			$scope.modalData.amountTendered,
			$scope.modalData.paymenttype,
			function (response) {
				$scope.openModal(response.nextModal, {
					orderTenderID: response.orderTenderID,
					amountTendered: $scope.modalData.amountTendered,
				});
			}
		);
	};
});

//Pay With Cash
pos.store.controller("payWithCash", function (
	$scope,
	$rootScope,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.modalTitle = "Pay with Cash";
	$scope.modalSubmitTitle = "Process";
	$scope.order = session.order;
	$scope.modalData;

	//Logic
	$scope.$emit("modalLoading", false);

	//Functions
	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);

		if (!$scope.formData.cashTendered) {
			$scope.formData.amountTendered = $scope.formData.cashTendered;
		}

		checkoutService.payWithCash(
			$scope.modalData.amountTendered,
			$scope.formData.cashTendered,
			function (response) {
				if ($scope.formData.cashTendered > 0) {
					if (
						$scope.formData.cashTendered -
							$scope.modalData.amountTendered >
						0
					) {
						globalFunctions.alertMessage(
							"iosNotice",
							"changeNotice",
							$scope.modalData.amountTendered -
								$scope.formData.cashTendered
						);
					}
				}

				globalFunctions.openCashDrawer(
					globalData.printerSettings.defaultCashDrawerMacAddress
				);

				$scope.openModal(response.nextModal, {
					orderTenderID: response.orderTenderID,
					amountTendered: $scope.modalData.amountTendered,
				});
			}
		);
	};
});

//Enter Credit Card Manually
pos.store.controller("enterCardManually", function (
	$scope,
	$rootScope,
	$localStorage,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.modalTitle = "Enter Card Manually";
	$scope.modalSubmitTitle = "Process";
	$scope.splitCount = globalFunctions.isRefund()
		? session.refund.refundHeader.orderTenders.length
			: session.order.orderHeader.orderTenders.length;

	if (globalData.posInfo.posPaymentFix) {
		if ($scope.formData) {
			$scope.formData.cardNumber = '';
			$scope.formData.cardExpiryMo = '';
			$scope.formData.cardExpiryYr = '';
			$scope.formData.CVV2 = '';
			$scope.formData.nameOnCard = '';
		}
	}

	//Logic
	$scope.$emit("modalLoading", false);

	//Functions
	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		checkoutService.enterCardManually(
			$scope.modalData.amountTendered,
			$scope.formData.cardNumber,
			$scope.formData.cardExpiryMo,
			$scope.formData.cardExpiryYr,
			$scope.formData.CVV2,
			$rootScope.isSplitPayment,
			$scope.splitCount,
			session.posProfile.zeamsterProductTransactionID,
			function (response) {
				$scope.openModal(response.nextModal, {
					orderTenderID: response.orderTenderID,
					amountTendered: $scope.modalData.amountTendered,
				});
			}
		);
	};
});

//Cards on file
pos.store.controller("cardsOnFile", function (
	$scope,
	$rootScope,
	$localStorage,
	session,
	globalData,
	globalFunctions,
	checkoutService,
	customerService
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.modalTitle = "Card on File";
	$scope.modalSubmitTitle = "hide";
	$scope.creditCards = []; //Locally accessed only, doesnt need to go in globalData.

	//Logic
	$scope.$emit("modalLoading", false);

	customerService.listCreditCards(function (response) {
		$scope.creditCards = response.creditCards;
	});

	//Functions
	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);

		checkoutService.useCardOnFile(
			$scope.modalData.amountTendered,
			$scope.formData,
			session.posProfile.zeamsterProductTransactionID,
			function (response) {
				$scope.openModal(response.nextModal, {
					orderTenderID: response.orderTenderID,
					amountTendered: $scope.modalData.amountTendered,
				});
			}
		);
	};
});

//Cards on file
pos.store.controller("giftCard", function (
	$scope,
	$rootScope,
	globalData,
	globalFunctions,
	checkoutService
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.modalTitle = "Gift Cards";
	$scope.modalSubmitTitle = "Process";

	//Logic
	$scope.$emit("modalLoading", false);

	//Functions
	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		checkoutService.useGiftCard(
			$scope.modalData.amountTendered,
			$scope.formData.giftCode,
			function (response) {
				$scope.amountDue = globalFunctions.isRefund()
					? response.refund.refundHeader.amountDueCents
					: response.order.orderHeader.amountDueCents;
				if (Number($scope.amountDue) == 0) {
					$scope.openModal(response.nextModal, {
						orderTenderID: response.orderTenderID,
						amountTendered: $scope.modalData.amountTendered,
					});
				} else {
					$rootScope.$emit("modalError", {
						alertLevel: response.useGiftCard.alertLevel,
						alertMessage: response.useGiftCard.alertMessage,
					});
					$scope.closeModal();
				}
			}
		);
	};

	$scope.closeModal = function () {
		globalFunctions.swipe("creditCard", true);
		$scope.$parent.closeModal();
	};
});

//Pay With Loyalty Points
pos.store.controller("loyaltyPoints", function (
	$scope,
	$rootScope,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.modalTitle = "Redeem Loyalty Points";
	$scope.modalSubmitTitle = "Process";
	$scope.isRefund = globalFunctions.isRefund();
	if ($scope.isRefund) {
		$scope.order = session.refund;
		$scope.header = session.refund.refundHeader;
	} else {
		$scope.order = session.order;
		$scope.header = $scope.order.orderHeader;
	}
	$scope.loyaltyPointsInDollarsTendered = 0.0;

	//Logic
	$scope.$emit("modalLoading", false);

	// //Functions
	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);

		checkoutService.payWithLoyaltyPoints(
			$scope.modalData.amountTendered,
			$scope.formData.loyaltyPointsTendered,
			function (response) {
				$scope.openModal(response.nextModal, {
					orderTenderID: response.orderTenderID,
					amountTendered: $scope.modalData.amountTendered,
				});
			}
		);
	};

	$scope.calculateLoyaltyPointsInDollars = function (redemptionRate) {
		var loyaltyPointsInDollars = (
			Math.round(
				$scope.formData.loyaltyPointsTendered * redemptionRate * 100
			) / 100
		).toFixed(2);
		$scope.loyaltyPointsInDollarsTendered =
			isNaN(loyaltyPointsInDollars) || loyaltyPointsInDollars < 0
				? 0.0
				: loyaltyPointsInDollars;

		// Need to ensure that we never display more than the max redeemable dollar amount
		var maxRedeemablePointsDollarAmount = Number(
			$scope.header.maxRedeemableLoyaltyPointsInDollars.replace(
				/[^0-9\.-]+/g,
				""
			)
		);
		if (
			$scope.loyaltyPointsInDollarsTendered >
			maxRedeemablePointsDollarAmount
		) {
			$scope.loyaltyPointsInDollarsTendered = maxRedeemablePointsDollarAmount;
		}
	};
});

//Tip Screen
pos.store.controller("tip", function (
	$scope,
	$rootScope,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	globalFunctions.setProcessingFlag(true);

	//Variables
	$scope.formData = {};
	$scope.modalTitle = "Optional Tip";
	$scope.modalSubmitTitle = "Apply";
	$scope.modalCancelTitle = "Cancel";
	$scope.modalData =
		typeof $scope.modalData === "undefined" ? "" : $scope.modalData;
	$scope.tip1 = parseInt(session.posProfile.customTip1);
	$scope.tip2 = parseInt(session.posProfile.customTip2);
	$scope.tip3 = parseInt(session.posProfile.customTip3);

	$scope.isRefund = globalFunctions.isRefund();
	if ($scope.isRefund) {
		$scope.order = session.refund;
		$scope.header = session.refund.refundHeader;
	} else {
		$scope.order = session.order;
		$scope.header = $scope.order.orderHeader;
	}
	$scope.lastTender =
		$scope.header.orderTenders[$scope.header.orderTenders.length - 1];
	$scope.amountTendered =
		typeof $scope.modalData.amountTendered === "undefined"
			? Number($scope.lastTender.amountTendered.replace("$", "")).toFixed(
					2
			  )
			: $scope.modalData.amountTendered;
	if ($scope.header.paymentType == "Terminal") {
		$scope.orderTenderID = "";
	} else {
		$scope.orderTenderID =
			typeof $scope.modalData.orderTenderID === "undefined"
				? $scope.lastTender.orderTenderID
				: $scope.modalData.orderTenderID;
	}

	//Logic
	$scope.$emit("modalLoading", false);

	//Functions
	$scope.modalAction = function (bypass) {
		$scope.modalActionDefaults($scope.formData);
		
		if($scope.modalData.isVivoPay) {
			var tip = Number(
				$scope.formData.tip.toString().replace("$", "")
			).toFixed(2);
			
			$scope.openModal('v2/views/partials/modals/payWithVivo', {
				orderTenderID: $scope.orderTenderID,
				tip: tip,
				amountTendered: (
					Number($scope.amountTendered) + Number(tip)
				).toFixed(2),
			});
		} else {
			checkoutService.tip(
				$scope.formData.tip,
				$scope.orderTenderID,
				function (response) {
					var tip = Number(
						$scope.formData.tip.toString().replace("$", "")
					).toFixed(2);
					
					$scope.openModal(response.nextModal, {
						orderTenderID: $scope.orderTenderID,
						tip: tip,
						amountTendered: (
							Number($scope.amountTendered) + Number(tip)
						).toFixed(2),
					});
				}
			);
		}
	};

	$scope.formSubmit = function () {
		$scope.formValidate(document.getElementsByName(formElements)) &&
			$scope.checkTip();
	};

	$scope.checkTip = function () {
		if (!$scope.formData.tip) {
			$scope.formData.tip = 0.0;
			$scope.modalAction();
		} else if ($scope.formData.tip < 500 || !Number($scope.formData.tip)) {
			$scope.modalAction();
		} else {
			if (confirm("You've left a tip greater than $500, are you sure?")) {
				$scope.modalAction();
			}
		}
	};

	$scope.closeModal = function () {
		$scope.modalActionDefaults($scope.formData);
		if ($scope.header.paymentType == "Terminal") {
			globalFunctions.swipe("creditCard", true);
			$scope.$parent.closeModal();
		} else {
			checkoutService.removeOrderTender(
				$scope.orderTenderID,
				function () {
					globalFunctions.swipe("creditCard", true);
					$scope.$parent.closeModal("cancelTender");
				}
			);
		}
	};
});

// Signature
pos.store.controller("signature", function (
	$scope,
	$rootScope,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	//Variables
	$scope.modalTitle = "Signature Required";
	$scope.modalSubmitTitle = "Process";
	$scope.modalCancelTitle = "Cancel";
	$scope.modalBackTitle = "Back";
	$scope.modalClearTitle = "Clear";
	$scope.modalSize = "big";
	$scope.isRefund = globalFunctions.isRefund();
	if ($scope.isRefund) {
		$scope.orderID = session.refund.refundHeader.refundOrderID;
		$scope.header = session.refund.refundHeader;
	} else {
		$scope.orderID = session.order.orderHeader.orderID;
		$scope.header = session.order.orderHeader;
	}

	$scope.signaturePad;
	$scope.hasSignature = false;
	$scope.modalData;
	$scope.backButton =
		session.posProfile.hasTips && $scope.header.paymentType != "Terminal";
	$scope.lastTender =
		$scope.header.orderTenders[$scope.header.orderTenders.length - 1];
	$scope.cardType = $scope.lastTender.creditCardType
		? $scope.lastTender.creditCardType
		: $scope.modalData.cardType;
	$scope.cardNumber = $scope.lastTender.cardNumber
		? $scope.lastTender.cardNumber.substr(
				$scope.lastTender.cardNumber.length - 4
		  )
		: $scope.modalData.cardNumber;
	$scope.amountTendered = $scope.lastTender.amountTendered;
	$scope.authCode = $scope.header.authCode
		? $scope.header.authCode
		: $scope.modalData.authCode;
	if ($scope.lastTender.paymentType === "Terminal") {
		$scope.modalCancelTitle = "hide";
		$scope.modalBackTitle = "hide";
	}

	$scope.$emit("modalLoading", false);

	if ($scope.deviceType == "iPhone") {
		$scope.modalSize = "vertical";
		$scope.canvas = document.querySelector(".signatureCanvasMobile");
	} else {
		$scope.modalSize = "big";
		$scope.canvas = document.querySelector(".signatureCanvasTablet");
	}

	$scope.signaturePad = new SignaturePad($scope.canvas, {
		onBegin: function () {
			$scope.$apply(function () {
				$scope.hasSignature = true;
			});
		},
	});

	//Functions
	$scope.clearSignature = function () {
		$scope.signaturePad.clear();
		$scope.hasSignature = false;
	};

	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		checkoutService.captureSignature(
			$scope.modalData.orderTenderID,
			$scope.signaturePad.toDataURL(),
			$scope.orderID,
			function (response) {
				if ($scope.deviceType == "iPhone") {
					rotatePortrait();
				}
				$scope.openModal(response.nextModal);
			}
		);
	};

	$scope.closeModal = function () {
		$scope.modalActionDefaults($scope.formData);
		checkoutService.removeOrderTender(
			$scope.modalData.orderTenderID,
			function () {
				globalFunctions.swipe("creditCard", true);
				$scope.$parent.closeModal("cancelTender");
			}
		);
	};

	$scope.backToTip = function () {
		$scope.modalActionDefaults($scope.formData);
		checkoutService.removeTipFromOrder(
			$scope.modalData.orderTenderID,
			$scope.orderID,
			function (response) {
				$scope.openModal(response.nextModal);
			}
		);
	};
});

//Tip & Signature Screen
pos.store.controller("tipAndSignature", function (
	$scope,
	$rootScope,
	$localStorage,
	session,
	globalData,
	globalFunctions,
	checkoutService,
	cartService
) {
	//Variables
	$scope.modalTitle = "Signature Required";
	$scope.modalSubmitTitle = "Done";
	$scope.modalCancelTitle = "Cancel";
	$scope.modalClearTitle = "Clear Signature";
	$scope.modalSize = "big";
	$scope.tip1 = Number(session.posProfile.customTip1);
	$scope.tip2 = Number(session.posProfile.customTip2);
	$scope.tip3 = Number(session.posProfile.customTip3);
	$scope.defaultSelectedTip = session.posProfile.defaultSelectedTip;
	$scope.tipArray = [0, $scope.tip1, $scope.tip2, $scope.tip3];
	$scope.formData = {
		tip: "0.00",
		tipType: "preset",
		GATracking: $scope.tipArray[$scope.defaultSelectedTip] + "%",
	};

	$scope.isRefund = globalFunctions.isRefund();
	if ($scope.isRefund) {
		$scope.orderID = session.refund.refundHeader.refundOrderID;
		$scope.header = session.refund.refundHeader;
	} else {
		$scope.orderID = session.order.orderHeader.orderID;
		$scope.header = session.order.orderHeader;
	}

	$scope.order = session.order;
	$scope.amountTendered = $scope.modalData.amountTendered;

	$scope.calculatedTip1Value = (
		$scope.amountTendered *
		($scope.tip1 / 100)
	).toFixed(2);
	$scope.calculatedTip2Value = (
		$scope.amountTendered *
		($scope.tip2 / 100)
	).toFixed(2);
	$scope.calculatedTip3Value = (
		$scope.amountTendered *
		($scope.tip3 / 100)
	).toFixed(2);

	$scope.signaturePad;
	$scope.hasSignature = false;
	$scope.modalData;
	$scope.orderTenders = $scope.header.orderTenders;
	$scope.lastTender = $scope.orderTenders[$scope.orderTenders.length - 1];
	$scope.cardType = $scope.lastTender.creditCardType
		? $scope.lastTender.creditCardType
		: $scope.modalData.cardType;
	$scope.cardNumber = $scope.lastTender.cardNumber
		? $scope.lastTender.cardNumber.substr(
				$scope.lastTender.cardNumber.length - 4
		  )
		: $scope.modalData.cardNumber;
	$scope.authCode = $scope.header.authCode
		? $scope.header.authCode
		: $scope.modalData.authCode;

	// Set the amount Tendered and Tip Based on Default Selected Tip coming in from posProfile
	$scope.formData.tip = (
		Number($scope.amountTendered) *
		($scope.tipArray[$scope.defaultSelectedTip] / 100)
	).toFixed(2);
	$scope.amountToTender = Number($scope.amountTendered) + $scope.formData.tip;

	//Logic
	if ($scope.deviceType == "iPhone") {
		rotateLandscape();
	}

	$scope.submitOnEnter = function (e) {
		var key = e.keyCode;
		if (key === 13 && $scope.hasSignature) {
			$scope.formSubmit();
		}
	};

	window.addEventListener("keypress", $scope.submitOnEnter, true);

	$scope.$emit("modalLoading", false);

	if ($scope.deviceType == "iPhone") {
		$scope.canvas = document.querySelector(".signatureCanvasMobile");
	} else {
		$scope.canvas = document.querySelector(".signatureCanvasTablet");
	}

	$scope.signaturePad = new SignaturePad($scope.canvas, {
		onBegin: function () {
			$scope.$apply(function () {
				$scope.hasSignature = true;
			});
		},
	});

	//Functions
	$scope.formSubmit = function () {
		$scope.formValidate(document.getElementsByName(formElements)) &&
			$scope.checkTip();
	};

	$scope.$watch("formData.tip", function (newValue, oldValue) {
		$scope.amountToTender = (
			Number($scope.amountTendered) + Number($scope.formData.tip)
		).toFixed(2);
	});

	$scope.destroyMaskingDiv = function () {
		document.activeElement.blur();
		document.getElementById("custom-tip-input").blur();
		document.getElementById("masking-signature-div").style.display = "none";
	};

	$scope.renderMaskingDiv = function () {
		document.getElementById("masking-signature-div").style.display =
			"block";
	};

	$scope.clearSignature = function () {
		$scope.signaturePad.clear();
		$scope.hasSignature = false;
	};

	// closing tipAndSignature modal
	$scope.closeModal = function () {
		$scope.modalActionDefaults($scope.formData);
		checkoutService.removeOrderTender(
			$scope.modalData.orderTenderID,
			function () {
				if ($scope.deviceType == "iPhone") {
					rotatePortrait();
				}
				globalFunctions.swipe("creditCard", true);
				$scope.$parent.closeModal("cancelTender");
			}
		);
	};

	$scope.checkTip = function () {
		if (!$scope.formData.tip) {
			$scope.formData.tip = 0.0;
			$scope.modalAction();
		} else if ($scope.formData.tip < 500 || !Number($scope.formData.tip)) {
			$scope.modalAction();
		} else {
			if (confirm("You've left a tip greater than $500, are you sure?")) {
				$scope.modalAction();
			}
		}
	};

	$scope.backToTip = function (response) {
		$scope.$emit("modalLoading", true);
		$scope.signaturePad.clear();
		$scope.hasSignature = false;
		$scope.formData.tip = 0;
		$scope.formData.tipType = "";
		$scope.$emit("modalLoading", false);
	};

	$scope.modalAction = function (bypass) {
		$rootScope.sendGA("Button", "click", $scope.formData.GATracking);
		$scope.modalActionDefaults($scope.formData);
		window.removeEventListener("keypress", $scope.submitOnEnter, true);
		checkoutService.captureTipAndSignature(
			$scope.formData.tip,
			$scope.modalData.orderTenderID,
			$scope.order.orderHeader.orderID,
			$scope.signaturePad.toDataURL(),
			session.posProfile.zeamsterProductTransactionID,
			function (response) {
				if ($scope.deviceType == "iPhone") {
					rotatePortrait();
				}
				$scope.openModal(response.nextModal);
			}
		);
	};
});

//Process Screen
pos.store.controller("process", function (
	$scope,
	$rootScope,
	$localStorage,
	$timeout,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	//Variables
	$scope.modalSubmitTitle = "hide";
	$scope.modalCancelTitle = "hide";
	$scope.isRefund = globalFunctions.isRefund();
	if ($scope.isRefund) {
		$scope.order = session.refund;
		$scope.orderTenders = session.refund.refundHeader.orderTenders;
	} else {
		$scope.order = session.order;
		$scope.orderTenders = $scope.order.orderHeader.orderTenders;
	}

	$scope.modalData;

	//Logic
	$scope.processOrder = function () {
		$scope.modalTitle = "Finalizing Order...";
		globalFunctions.alertMessage("loadingNotice", null, "");
		checkoutService.processOrder(function (response) {
			$scope.openModal(response.nextModal);
		});
	};

	//if credit card
	$scope.needsCapture = 0;
	$scope.needsReAuth = 0;
	$scope.defaultPaymentType = session.posProfile.defaultPaymentType;

	$scope.orderTenders.forEach(function (value, index, array) {
		$scope.tipOnTender = Number((value.tipOnTenderCents / 100).toFixed(2));

		// If the credit card isn't already paid then we need to capture payment
		if (
			globalData.posInfo.hasAuthorizedPOSOrders &&
			value.paymentType == "CreditCard" &&
			value.orderPaymentStatusID.toUpperCase() !=
				"055617C8-E7B5-49E2-87BA-9807868B8379"
		) {
			if ($scope.defaultPaymentType == "Purchased") {
				$scope.needsCapture = 1;
			} else if (
				$scope.defaultPaymentType == "Authorized" &&
				$scope.tipOnTender > 0
			) {
				// We need to re-auth for the tender amount + tip so it can be captured at a later date
				$scope.needsReAuth = 1;
			}
		} else {
			if (
				value.paymentType == "CreditCard" &&
				value.orderPaymentStatusID.toUpperCase() !=
					"055617C8-E7B5-49E2-87BA-9807868B8379"
			) {
				$scope.needsCapture = 1;
			}
		}
	});
	if ($scope.needsCapture == 1) {
		$scope.modalTitle = "Capturing Payment...";
		checkoutService.capturePayment(
			session.posProfile.zeamsterProductTransactionID,
			function () {
				$scope.processOrder();
		});
	} else if ($scope.needsReAuth == 1) {
		$scope.modalTitle = "Processing Funds...";
		checkoutService.voidAndReAuthPayment(function () {
			$scope.processOrder();
		});
	} else {
		$scope.processOrder();
	}
});

//Receipt Screen
pos.store.controller("receipt", function (
	$scope,
	$rootScope,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	//Variables
	$scope.modalTitle = "Receipt";
	$scope.posInfo = globalData.posInfo;
	$scope.modalSubmitTitle = "Email Receipt";
	$scope.modalCancelTitle = "No Thanks";
	$scope.isRefund = globalFunctions.isRefund();
	if ($scope.isRefund) {
		$scope.order = session.refund;
		$scope.order.orderHeader = $scope.order.refundHeader;
	} else {
		$scope.order = session.order;
		$scope.order.orderHeader = $scope.order.orderHeader;
	}

	$scope.emailRequired = 0;

	//Logic
	$scope.$emit("modalLoading", false);

	$scope.formData = {};
	$scope.displaySecondaryEmailCheckbox = 0;
	$scope.formData.includeSecondaryEmails = 0;

	if (
		globalData.posInfo.hasSecondaryEmails &&
		session.customer.secondaryEmails
	) {
		if (session.customer.secondaryEmails.length > 0) {
			$scope.displaySecondaryEmailCheckbox = 1;
			$scope.formData.includeSecondaryEmails = 1;
		}
	}

	// If there is a gift card/event ticket in this order then the email is Required
	angular.forEach($scope.order.orderItems, function (value, key) {
		if (
			value.productType == "GiftCard" ||
			value.productType == "EventTicket"
		) {
			$scope.emailRequired = 1;
			$scope.modalCancelTitle = "hide";
		}
	});

	if ($scope.order.orderHeader.billCustomer.firstName == "Guest") {
		$scope.order.orderHeader.billCustomer.firstName = "";
		$scope.order.orderHeader.billCustomer.lastName = "";
	}

	if ($scope.order.orderHeader.billCustomer.zipCode == "Default") {
		$scope.order.orderHeader.billCustomer.zipCode = "";
	}

	if ($scope.order.orderHeader.billCustomer.isCheckBoxSubscribe) {
		$scope.formData.isSingleOptIn = 1;
	}

	$scope.showNameFields = true;

	if (
		$scope.posInfo.hasCustomerCapture == 1 &&
		$scope.order.orderHeader.billCustomer.isCustomerCapture == 1
	) {
		$scope.formData.isCustomerCapture = 1;
	}

	if (
		(!$scope.formData.firstName || $scope.formData.firstName.length == 0) &&
		$scope.order.orderHeader.billCustomer.firstName.length > 0
	) {
		$scope.formData.firstName =
			$scope.order.orderHeader.billCustomer.firstName;
	}

	if (
		(!$scope.formData.lastName || $scope.formData.lastName.length == 0) &&
		$scope.order.orderHeader.billCustomer.lastName.length > 0
	) {
		$scope.formData.lastName =
			$scope.order.orderHeader.billCustomer.lastName;
	}

	var shipCustomer = $scope.order.orderHeader.shipCustomer;
	var isGuestOrder =
		shipCustomer.firstName == "Guest" &&
		shipCustomer.lastName == "Customer";
	var billCustomerHasName =
		$scope.order.orderHeader.billCustomer.firstName.length > 0 &&
		$scope.order.orderHeader.billCustomer.lastName.length > 0;

	if (!isGuestOrder && billCustomerHasName) {
		$scope.showNameFields = false;
	}

	//Functions
	$scope.closeModal = function () {
		$scope.modalActionDefaults($scope.formData);
		$scope.$emit("modalLoading", true);
		checkoutService.noReceipt(function (response) {
			$scope.openModal(response.nextModal, { sendEmail: 0 });
		});
	};

	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		var isCustomerCaptured =
			$scope.posInfo.hasCustomerCapture == 1 &&
			$scope.order.orderHeader.billCustomer.isCustomerCapture == 1;

		var capturedCustomer = isCustomerCaptured
			? $scope.order.orderHeader.billCustomer
			: false;
		var effectiveFirstName = capturedCustomer
			? capturedCustomer.firstName
			: $scope.formData.firstName;
		var effectiveLastName = capturedCustomer
			? capturedCustomer.lastName
			: $scope.formData.lastName;

		if (!effectiveFirstName) {
			effectiveFirstName = "";
		}

		if (!effectiveLastName) {
			effectiveLastName = "";
		}

		if (!$scope.formData.zipCode) {
			$scope.formData.zipCode = "";
		}

		checkoutService.emailAddress(
			$scope.formData.email,
			effectiveFirstName,
			effectiveLastName,
			$scope.formData.zipCode,
			$scope.formData.isSingleOptIn,
			$scope.formData.includeSecondaryEmails,
			function (response) {
				$scope.openModal(response.nextModal, { sendEmail: 1 });
			}
		);
	};
});

// Email Screen
pos.store.controller("emailReceipt", function (
	$scope,
	$rootScope,
	session,
	globalData,
	globalFunctions,
	checkoutService
) {
	//Variables
	$scope.modalTitle = "Email Receipt";
	$scope.posInfo = globalData.posInfo;
	$scope.modalSubmitTitle = "Send";
	$scope.modalCancelTitle = "Cancel";

	$scope.order = globalFunctions.fetchRefundOrderIfRefund();
	$scope.order.orderHeader = globalFunctions.isRefund()
		? $scope.order.refundHeader
		: $scope.order.orderHeader;

	$scope.emailRequired = 0;

	$scope.$emit("modalLoading", false);

	$scope.formData = {};
	$scope.displaySecondaryEmailCheckbox = 0;
	$scope.formData.includeSecondaryEmails = 0;

	if ($scope.posInfo.hasSecondaryEmails && session.customer.secondaryEmails) {
		if (session.customer.secondaryEmails.length > 0) {
			$scope.displaySecondaryEmailCheckbox = 1;
			$scope.formData.includeSecondaryEmails = 1;
		}
	}

	angular.forEach($scope.order.orderItems, function (value, key) {
		if (
			value.productType == "GiftCard" ||
			value.productType == "EventTicket"
		) {
			$scope.emailRequired = 1;
			$scope.modalCancelTitle = "hide";
		}
	});

	if ($scope.order.orderHeader.billCustomer.firstName == "Guest") {
		$scope.order.orderHeader.billCustomer.firstName = "";
		$scope.order.orderHeader.billCustomer.lastName = "";
	}

	if ($scope.order.orderHeader.billCustomer.zipCode == "Default") {
		$scope.order.orderHeader.billCustomer.zipCode = "";
	}

	if ($scope.order.orderHeader.billCustomer.isCheckBoxSubscribe) {
		$scope.formData.isSingleOptIn = 1;
	}

	$scope.showNameFields = true;

	if (
		$scope.posInfo.hasCustomerCapture === 1 &&
		$scope.order.orderHeader.billCustomer.isCustomerCapture === 1
	) {
		$scope.formData.isCustomerCapture = 1;
	}

	if (
		(!$scope.formData.firstName || $scope.formData.firstName.length == 0) &&
		$scope.order.orderHeader.billCustomer.firstName.length > 0
	) {
		$scope.formData.firstName =
			$scope.order.orderHeader.billCustomer.firstName;
	}

	if (
		(!$scope.formData.lastName || $scope.formData.lastName.length == 0) &&
		$scope.order.orderHeader.billCustomer.lastName.length > 0
	) {
		$scope.formData.lastName =
			$scope.order.orderHeader.billCustomer.lastName;
	}

	var shipCustomer = $scope.order.orderHeader.shipCustomer;
	var isGuestOrder =
		shipCustomer.firstName == "Guest" &&
		shipCustomer.lastName == "Customer";
	var billCustomerHasName =
		$scope.order.orderHeader.billCustomer.firstName.length > 0 &&
		$scope.order.orderHeader.billCustomer.lastName.length > 0;

	if (!isGuestOrder && billCustomerHasName) {
		$scope.showNameFields = false;
	}

	//Functions
	$scope.closeModal = function () {
		$scope.modalActionDefaults($scope.formData);
		$scope.$emit("modalLoading", true);
		checkoutService.cancelEmailReceipt(function (response) {
			$scope.openModal(response.nextModal);
		});
	};

	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		var isCustomerCaptured =
			$scope.posInfo.hasCustomerCapture == 1 &&
			$scope.order.orderHeader.billCustomer.isCustomerCapture == 1;

		var capturedCustomer = isCustomerCaptured
			? $scope.order.orderHeader.billCustomer
			: false;
		var effectiveFirstName = capturedCustomer
			? capturedCustomer.firstName
			: $scope.formData.firstName;
		var effectiveLastName = capturedCustomer
			? capturedCustomer.lastName
			: $scope.formData.lastName;

		if (!effectiveFirstName) {
			effectiveFirstName = "";
		}

		if (!effectiveLastName) {
			effectiveLastName = "";
		}

		if (!$scope.formData.zipCode) {
			$scope.formData.zipCode = "";
		}

		checkoutService.emailAddress(
			$scope.formData.email,
			effectiveFirstName,
			effectiveLastName,
			$scope.formData.zipCode,
			$scope.formData.isSingleOptIn,
			$scope.formData.includeSecondaryEmails,
			function (response) {
				$scope.openModal(response.nextModal, { sendEmail: 1 });
			}
		);
	};
});

// AdditionalOrder Data
pos.store.controller("additionalOrderData", function (
	$scope,
	$timeout,
	$rootScope,
	$filter,
	session,
	globalData,
	globalFunctions,
	checkoutService,
	productService
) {
	//Variables
	$scope.modalTitle = "Additional Order Info";
	$scope.modalSubmitTitle = "Apply";
	$scope.modalCancelTitle = "hide";
	$scope.modalData;
	$scope.isRefund = globalFunctions.isRefund();
	$scope.order = $scope.isRefund ? session.refund : session.order;
	$scope.additionalOrderData = globalData.additionalOrderData;
	$scope.showNonTaxable = session.posProfile.nonTaxableSetting;

	//Logic
	$scope.$emit("modalLoading", false);

	if (!$scope.modalData) {
		$scope.modalData = {};
	}

	if ($scope.modalData.outsideCheckout == 1) {
		$scope.modalCancelTitle = "Close";
	}

	//Functions
	$scope.init = function () {
		//Build Object
		$scope.formData = {
			salesAssociateID: "",
			salesAttributeID: "",
			inventoryPoolID: "",
			orderType: "",
			requestedShipDay: "",
			requestedShipMonth: "",
			requestedShipYear: "",
			sourceCode: "",
			isNonTaxable: "",
			orderNotes: "",
		};

		//Set Default Order Type
		$scope.formData.orderType = globalFunctions.findInObject(
			$scope.additionalOrderData.orderTypes,
			"default",
			1
		)[0].orderType;

		//Set Default Sales Associate
		if (
			globalFunctions.findInObject(
				$scope.additionalOrderData.salesAssociates,
				"default",
				1
			).length
		) {
			$scope.formData.salesAssociateID = globalFunctions.findInObject(
				$scope.additionalOrderData.salesAssociates,
				"default",
				1
			)[0].salesAssociateID;
		}

		if ($scope.$session.additionalOrderDataFormData) {
			$scope.formData = $scope.$session.additionalOrderDataFormData;
		}

		// Set values from order header (needed for held/resumed orders)
		if (typeof $scope.order.orderHeader !== "undefined") {
			if (
				$scope.order.orderHeader.additionalOrderData.salesAssociateID
					.length
			)
				$scope.formData.salesAssociateID =
					$scope.order.orderHeader.additionalOrderData.salesAssociateID;
			if (
				$scope.order.orderHeader.additionalOrderData.salesAttributeID
					.length
			)
				$scope.formData.salesAttributeID =
					$scope.order.orderHeader.additionalOrderData.salesAttributeID;
			if (
				$scope.order.orderHeader.additionalOrderData.inventoryPoolID
					.length
			)
				$scope.formData.inventoryPoolID =
					$scope.order.orderHeader.additionalOrderData.inventoryPoolID;
			if ($scope.order.orderHeader.additionalOrderData.orderType.length)
				$scope.formData.orderType =
					$scope.order.orderHeader.additionalOrderData.orderType;
			if ($scope.order.orderHeader.additionalOrderData.sourceCode.length)
				$scope.formData.sourceCode =
					$scope.order.orderHeader.additionalOrderData.sourceCode;
			if (
				typeof !!$scope.order.orderHeader.additionalOrderData
					.isNonTaxable === "boolean"
			)
				$scope.formData.isNonTaxable =
					$scope.order.orderHeader.additionalOrderData.isNonTaxable;
			if ($scope.order.orderHeader.additionalOrderData.orderNotes.length)
				$scope.formData.orderNotes =
					$scope.order.orderHeader.additionalOrderData.orderNotes;
		}
	};
	$scope.init();

	$scope.closeModal = function () {
		$scope.modalActionDefaults($scope.formData);

		if ($scope.modalData.outsideCheckout == 1) {
			$scope.$parent.closeModal();
		} else {
			checkoutService.additionalOrderData(
				$scope.formData.inventoryPoolID,
				orderNotes,
				$scope.formData.orderType,
				$scope.formData.requestedShipDay,
				$scope.formData.requestedShipMonth,
				$scope.formData.requestedShipYear,
				$scope.formData.salesAssociateID,
				$scope.formData.salesAttributeID,
				$scope.formData.sourceCode,
				$scope.modalData.outsideCheckout,
				function (response) {
					$scope.$session.additionalOrderDataFormData =
						$scope.formData;
					$scope.openModal(
						response.nextModal,
						$scope.modalData.sendEmail
					);
				}
			);
		}
	};

	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);

		checkoutService.additionalOrderData(
			$scope.formData.inventoryPoolID,
			$scope.formData.orderNotes,
			$scope.formData.orderType,
			$scope.formData.requestedShipDay,
			$scope.formData.requestedShipMonth,
			$scope.formData.requestedShipYear,
			$scope.formData.salesAssociateID,
			$scope.formData.salesAttributeID,
			$scope.formData.sourceCode,
			$scope.formData.isNonTaxable,
			$scope.modalData.outsideCheckout,
			function (response) {
				$scope.$session.additionalOrderDataFormData = $scope.formData;
				if ($scope.modalData.outsideCheckout == 1) {
					if (globalFunctions.isRefund())
						$rootScope.$emit("updateRefund", response.refund);
					$rootScope.$emit("updateChargeVariables", true);
					var clearCache = true;
					productService.listProducts(
						globalFunctions.retrieveCurrentCategoryID(),
						session.order.orderHeader.orderID,
						null,
						null,
						clearCache
					);
					$scope.$parent.closeModal();
					$scope.closeMenu();

					//If we cant process the order anymore, we have to close the charge screen
					if (response.order.orderHeader.isChargable == 0) {
						$rootScope.chargeOpen = false;
					}
				} else {
					$scope.openModal(response.nextModal, $scope.modalData);
				}
			}
		);
	};
});

// Simple Success
pos.store.controller("simpleSuccess", function (
	$scope,
	$rootScope,
	$timeout,
	$location,
	session,
	globalData,
	globalFunctions,
	checkoutService,
	cartService
) {
	//Variables
	$scope.modalTitle = "Success";
	$scope.modalSubmitTitle = "New Order";
	$scope.modalCancelTitle =
		session.posProfile.logoutAfterEachOrder === 1 ? "hide" : "Logout";
	$scope.order = angular.copy(session.order);
	$scope.modalData;
	$scope.posProfile = angular.copy(session.posProfile);

	//Listeners

	//Functions
	$scope.$emit("modalLoading", false);

	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		if ($scope.posProfile.logoutAfterEachOrder === 1) {
			$scope.logout();
		} else {
			checkoutService.deleteCartObject();
			cartService.init(null, function () {
				globalFunctions.clearSession();
				$timeout(function () {
					location.reload();
				}, 300);
			});
		}
	};

	$scope.loadEmailReceiptModal = function () {
		$scope.$emit("modalLoading", true);
		checkoutService.emailReceiptForm(function (response) {
			$scope.openModal(response.nextModal, $scope.modalData);
		});
	};

	$scope.print = function () {
		globalFunctions.printReceipt(
			globalData.printerSettings.defaultPrinterMacAddress,
			$scope.order.orderHeader.orderID,
			"printReceipt"
		);
	};

	$scope.printReceiptWithSignature = function () {
		globalFunctions.printReceipt(
			globalData.printerSettings.defaultPrinterMacAddress,
			$scope.order.orderHeader.orderID,
			"printReceiptWithSignature"
		);
	};

	$scope.printTenders = function () {
		globalFunctions.printReceipt(
			globalData.printerSettings.defaultPrinterMacAddress,
			$scope.order.orderHeader.orderID,
			"printTenders"
		);
	};

	$scope.flagAction = function (path) {
		$scope.modalActionDefaults($scope.formData);
		cartService.init(null, function () {
			globalFunctions.clearSession();
			$timeout(function () {
				$scope.closeModal();
				$location.path(path);
			}, 300);
		});
	};
});

//Success Screen
pos.store.controller("success", function (
	$scope,
	$rootScope,
	$timeout,
	$location,
	session,
	globalData,
	globalFunctions,
	checkoutService,
	cartService
) {
	//Variables
	$scope.modalTitle = "Success";
	$scope.modalSubmitTitle = "New Order";
	$scope.modalCancelTitle =
		session.posProfile.logoutAfterEachOrder === 1 ? "hide" : "Logout";
	$scope.order = angular.copy(session.order);
	$scope.modalData;
	$scope.posProfile = angular.copy(session.posProfile);

	//Listeners

	//Functions
	$scope.$emit("modalLoading", false);
	// Delete Cart Object - but don't wait for it to return to continue execution
	checkoutService.deleteCartObject();

	$scope.modalAction = function () {
		$scope.modalActionDefaults($scope.formData);
		if ($scope.posProfile.logoutAfterEachOrder === 1) {
			$scope.logout();
		} else {
			cartService.init(null, function () {
				globalFunctions.clearSession();
				$timeout(function () {
					location.reload();
				}, 300);
			});
		}
	};

	$scope.print = function () {
		globalFunctions.printReceipt(
			globalData.printerSettings.defaultPrinterMacAddress,
			$scope.order.orderHeader.orderID,
			"printReceipt"
		);
	};

	$scope.printReceiptWithSignature = function () {
		globalFunctions.printReceipt(
			globalData.printerSettings.defaultPrinterMacAddress,
			$scope.order.orderHeader.orderID,
			"printReceiptWithSignature"
		);
	};

	$scope.printTenders = function () {
		globalFunctions.printReceipt(
			globalData.printerSettings.defaultPrinterMacAddress,
			$scope.order.orderHeader.orderID,
			"printTenders"
		);
	};

	$scope.flagAction = function (path) {
		$scope.modalActionDefaults($scope.formData);
		cartService.init(null, function () {
			globalFunctions.clearSession();
			$timeout(function () {
				$scope.closeModal();
				$location.path(path);
			}, 300);
		});
	};
});
