pos.controller("desktopController", function($scope, $rootScope, session, globalData, globalFunctions, $timeout){
	

//Variables
$scope.printFrame = false;
$scope.keys = [];


//Functions

$scope.startBarcodeScan = function(){
	//Starts barcode scan
	$scope.waitingForBarcodeScan = true;
	$timeout(function(){
		angular.element('[name=barCodeData]').focus();
	},1)
}

$scope.barCodeScan = function(barCodeData){
	if(barCodeData.length > 1){
		barCodeScan(barCodeData)
		angular.element('[name=barCodeData]')[0].value = "";
	}
}

$scope.restartBarCodeData = function(){
	if(angular.element('[name=barCodeData]').length > 0){
		$scope.waitingForBarcodeScan = true;
		$timeout(function(){
			angular.element('[name=barCodeData]').focus();
		},1)
	}
}

$scope.cancelBarCodeScan = function(){
	$scope.barCodeData = "";
	angular.element('[name=barCodeForm]')[0].reset();
	$scope.waitingForBarcodeScan = false;
}

$scope.beginSwipe = function(){
	console.log("beginSwipe");
	$scope.enableSwipe = true;
	document.addEventListener("keydown", $scope._detectKeys, false);
}

$scope.cardSwipe = function(cardData){
	cardSwipe(cardData)
	angular.element('[name=cardData]').blur();
	angular.element('[name=cardSwipeForm]')[0].reset();
	$scope.cancelSwipe();
}

$scope.cancelSwipe = function(){
	//Cancel swipe
	angular.element('[name=cardData]').blur();
	document.removeEventListener("keydown", $scope._detectKeys, false);
}



$scope.openCashDrawer = function(){
	if(deviceType == "Desktop"){
		document.getElementById("blankIframe").contentWindow.print();
	}
}

$scope.requestReceiptPrinters = function(){
	//Doesnt do anything
}

$scope.rotateLandscape = function(){
	//Doesnt do anything
}

$scope.rotatePortrait = function(){
	//Doesnt do anything
}

$scope.printPDF = function(url){
	// $scope.printFrame = false;
	$timeout(function(){
		$scope.printFrame = true;
		url = url.replace("printType=receipt", "printType=html");
		url = url+"&isDesktop=true";
		console.log(url);
		$scope.printURL = "//"+url;
	},1)
	// printWindow = window.open("http://pos.vin654.com/store/index.cfm?method=checkout.prePrintReceipt&orderID=92380DB4-AF4C-55B7-3396-D0CC151D2458&printType=letter"); 
	// printWindow.print(); 
	// printWindow.close(); 
	//Prints
}

$scope.printFrameLoaded = function(){
	document.getElementById("PDFPrint").contentWindow.print();
	$timeout(function(){
		$scope.printFrame = false;
		$scope.printURL = "";
	},1)
}

$scope.starPrint = function(){
	//Doesnt do anything
}

$scope.restart = function(){
	location.reload();
}

$scope.openSafari = function(url){
	location.href="//"+url;
}

// Private Functions
$scope._detectKeys = function(event){
	$scope.keys.push(event.keyCode);
	var lastKey = $scope.keys[$scope.keys.length-2];
	if(lastKey == "16" && event.keyCode == "53"){
		angular.element('[name=cardSwipeForm]')[0].reset();
		angular.element('[name=cardData]').focus();
	}
}

//Logic

//Listeners

});
