pos.store.factory("tableService", function($http, session, globalData, globalFunctions){

	var deleteTable = function(tableID, callback){
		globalFunctions.v65http("/store/index.cfm?method=tables.deleteTable",{tableID:tableID,posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var editTable = function(tableID,tableName,clubCount,nonClubCount,callback){
		globalFunctions.v65http("/store/index.cfm?method=tables.editTable",{tableID:tableID,tableName:tableName,clubCount:clubCount,nonClubCount:nonClubCount,posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listTables = function(page,maxRows,callback){
		globalFunctions.v65http("/store/index.cfm?method=tables.listOpenTables",{page:page,maxRows:maxRows,posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listItemsAvailableToSend = function(tableID, callback){
		globalFunctions.v65http("/store/index.cfm?method=prepOrder.listItemsAvailableToSend",{tableID:tableID,posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var sendItemsToPrepArea = function(tableID,prepAreaID,itemsToSend,callback){
		globalFunctions.v65http("/store/index.cfm?method=prepOrder.sendItemsToPrepArea",{tableID:tableID,prepAreaID:prepAreaID,itemsToSend:[itemsToSend],posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, null, 0)
	}

	var startTable = function(tableName,clubCount,nonClubCount,callback){
		globalFunctions.v65http("/store/index.cfm?method=tables.startTable",{tableName:tableName,clubCount:clubCount,nonClubCount:nonClubCount,posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, null, 0);
	}

	var listKitchenTickets = function(prepOrderID, page, maxRows, filters, callback) {
		globalFunctions.v65http("/store/index.cfm?method=prepOrder.listKitchenTickets", {prepOrderID:prepOrderID, page: page, maxRows: maxRows, filters: JSON.stringify(filters), posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response) {
			(callback)(response);
		}, null, 15000);
	}

	var printReceipt = function (tableID, tableName, prepAreaID, locationName, itemsToSend, salesAssociate, clubCount, nonClubCount, orderID, orderNumber, printerMacAddress, ticketNumber, notes, printType){
		printType = (typeof printType !== 'undefined')? printType : 'receipt';
		ticketNumber = (typeof ticketNumber !== 'undefined')? ticketNumber : 1;
		salesAssociate = (typeof salesAssociate !== 'undefined')? salesAssociate : '';

		printUrl="/store/index.cfm?method=prepOrder.printPrepItems";
		printUrl+="&tableID="+tableID;
		printUrl+="&tableName="+encodeURIComponent(tableName);
		printUrl+="&prepAreaID="+prepAreaID;
		printUrl+="&location="+locationName;
		// Double encode to allow special characters in product title/SKU (we urlDecode in CF)
		printUrl+="&itemsToSend="+encodeURIComponent(encodeURIComponent(JSON.stringify(itemsToSend)));
		if (typeof notes !== 'undefined') printUrl+="&notes="+encodeURIComponent(notes);
		
		printUrl+="&salesAssociate="+salesAssociate;
		printUrl+="&clubCount="+clubCount;
		printUrl+="&nonClubCount="+nonClubCount;
		printUrl+="&orderID="+orderID;
		printUrl+="&orderNumber="+orderNumber;
		// printUrl+="&posProfile="+encodeURIComponent(JSON.stringify(session.posProfile));
		printUrl+="&ticketNumber="+ticketNumber;
		printUrl+="&printType="+printType;
		globalFunctions.print(printerMacAddress, printUrl);
	}

	return{
		deleteTable:deleteTable,
		editTable:editTable,
		listTables:listTables,
		listItemsAvailableToSend:listItemsAvailableToSend,
		sendItemsToPrepArea:sendItemsToPrepArea,
		startTable:startTable,
		listKitchenTickets: listKitchenTickets,
		printReceipt: printReceipt
	};
});