pos.store.controller("cart", function($scope, $rootScope, session, globalData, globalFunctions, cartService){
	//Variables
	$scope.loading = true;
	$rootScope.$emit("globalLoading", true);
	$scope.order = session.order;
	$scope.table = session.table;

	// Logic


	//Product Init Function
	if(globalData.reloaded == true){
		globalData.reloaded = false;
		cartService.init(null,function(){
			$rootScope.$emit("globalLoading", false);
			$scope.loading = false;
		});
	}else{
		$rootScope.$emit("globalLoading", false);
		$scope.loading = false;
	}

	// Listeners
	$rootScope.$on('cartLoading', function(event, data){
		$scope.loading = data;
		$scope.alertLevel = "";
		$scope.alertMessage = "";
	});

	$rootScope.$on('cartError', function(event, data){
		$rootScope.$emit("cartLoading", false);
		$rootScope.cartOpen = true; //Mobile Cart
		$scope.alertLevel = data.alertLevel;
		$scope.alertMessage = data.alertMessage;
		globalFunctions.scrollTo("cartAlert");
	});


	//Functions
	$scope.charge = function(order){
		$rootScope.$emit("cartLoading", true);
		cartService.prepareCharge(function(){
			$rootScope.$emit("chargeOpen", true);
			$rootScope.$emit("cartLoading", false);
			if($scope.order.orderHeader.amountDueCents == 0 && $scope.order.orderHeader.orderTenders.length){
				// For some reason the order didn't complete before, so we should try and complete it now
				$scope.openModal("v2/views/partials/modals/process");
			}
		})
	}

	$scope.clearCart = function(){
		$scope.loading = true;
		cartService.init(null,function(){
			globalFunctions.clearSession();
			$scope.loading = false;
		});
	}

	$scope.editItem = function(item){
		if(item.editable == 1 && !$rootScope.chargeOpen){
			$scope.openModal("v2/views/partials/modals/editItem", item);
		}
	}

	//Filter for only showing inuse Coupons
	$scope.inUseCoupons = function(coupon){
		return coupon.inUse == 0 ? false : true;
	};

});

pos.store.controller("editItem", function($scope, $rootScope, globalData, globalFunctions, cartService){
	// Variables
	$scope.modalTitle = "Edit Product";
	$scope.modalSubmitTitle = "Save";
	$scope.modalData;

	//Logic
	$scope.$emit('modalLoading', false);

	$scope.formData = {};

	if($scope.modalData.isPriceOverride == 1){	
		$scope.formData.price = Number($scope.modalData.priceNumeric);	
	}else{	
		$scope.formData.price = "";	
	}

	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		cartService.updateProduct($scope.formData, function(response){
			$scope.closeModal();
		});
	}

	$scope.removeItem = function(){
		$scope.modalActionDefaults($scope.formData);
		cartService.removeItem($scope.modalData, function(response){
			$scope.closeModal();
		});
	}

});



pos.store.controller("coupon", function($scope, $rootScope, session, globalData, globalFunctions, cartService){
	//Variables
	$scope.modalTitle = "Coupons";
	$scope.modalSubmitTitle = "Find";
	$scope.coupons = session.coupons;

	//Logic
	$scope.$emit('modalLoading', false);
	
	//Functions
	$scope.addCouponToCart = function(formData){
		$scope.modalActionDefaults($scope.formData);
		if($rootScope.isRefund){
			cartService.addCouponToRefund($scope.formData.couponCode, function(response){
				$rootScope.$emit("updateRefund", response.refund);
				$scope.closeModal();
			});
		} else {
			cartService.addCouponToCart($scope.formData.couponCode, function(response){
				$scope.closeModal();
			});
		}
	}

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.addCouponToCart($scope.formData);
	};

	$scope.removeAllCoupons = function(formData){
		$scope.$emit('modalLoading', true);
		if($rootScope.isRefund){
			cartService.removeAllCouponsFromRefund(function(response){
				$rootScope.$emit("updateRefund", response.refund);
				$scope.closeModal();
			});
		} else {
			cartService.removeAllCoupons(function(response){
				$scope.closeModal();
			});
		}
	}
});
