pos.customerManager.controller("creditCards", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.creditCards.listCreditCards";
	$scope.viewRoute = "customerManager.viewCustomer.creditCards.viewCreditCard";
	$scope.addRoute = "customerManager.viewCustomer.creditCards.addCreditCard";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("listCreditCards", function($scope, $rootScope, globalData, globalFunctions, creditCardService){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Credit Cards";
	$scope.creditCards = {};

	//Functions
	$scope.listCreditCards = function(page){
		$scope.$emit('formLoading', true);
		creditCardService.listCreditCards(page, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.creditCards = response.creditCards;
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		globalFunctions.scrollTo('top');
		$scope.listCreditCards(page);
	}

	//Listeners

	//Logic
	$scope.listCreditCards(1);
});


pos.customerManager.controller("viewCreditCard", function($scope, $rootScope, globalData, globalFunctions, creditCardService, clubMembershipService, $stateParams, $controller, $state){
	//Variables
	$scope.$emit('formLoading', true);
	$rootScope.currentManager.subSectionTitle = "View Credit Card";
	$scope.creditCard = {};
	$scope.clubMembershipSelection = {};
	$scope.expiryYears = getExpiryYears();		

	//Functions
	$scope.deleteCreditCard = function(customerCreditCardID){
		$scope.$emit('formLoading', true);
		creditCardService.deleteCreditCard(customerCreditCardID, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		})
	}

	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		creditCardService.editCreditCard($scope.formData.cardExpiryMo,	$scope.formData.cardExpiryYr,	$scope.formData.cardNumber, $scope.formData.customerCreditCardID,	$scope.formData.isPrimary,	$scope.formData.nameOnCard, $scope.clubMembershipSelection, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}

	//Listeners

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController

	creditCardService.viewCreditCard($stateParams.customerCreditCardID, function(response){
		$scope.creditCard = response.creditCard;
		$scope.formData = response.creditCard;
		
		clubMembershipService.listClubMemberships(1, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.clubMemberships = response.clubMemberships;
		});
	});
	
});

pos.customerManager.controller("addCreditCard", function($scope, $rootScope, globalData, globalFunctions, creditCardService, clubMembershipService, $controller, $state){
	//Variables
	$rootScope.currentManager.subSectionTitle = "Add Credit Card";
	$scope.creditCard = {};
	$scope.clubMembershipSelection = {};
	$scope.expiryYears = getExpiryYears();	
	
	//Functions
	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		creditCardService.addCreditCard($scope.formData.cardExpiryMo,	$scope.formData.cardExpiryYr,	$scope.formData.cardNumber,	$scope.formData.isPrimary,	$scope.formData.nameOnCard, $scope.clubMembershipSelection, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}
	
	$scope.listClubMemberships = function(){
		$scope.$emit('formLoading', true);
		clubMembershipService.listClubMemberships(1, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.clubMemberships = response.clubMemberships;
		});
	}
	//listeners

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController
	
	$scope.listClubMemberships();
});

pos.customerManager.controller("addCreditCardModal", function($scope, $rootScope, globalData, globalFunctions, creditCardService, $controller, $state){
	//Variables
	$scope.modalTitle = "Add Credit Card";
	$scope.modalSubmitTitle = "Save";
	$scope.expiryYears = getExpiryYears();
	
	//Functions
	$scope.formAction = function(){
		$scope.modalActionDefaults($scope.formData);
		creditCardService.addCreditCard($scope.formData.cardExpiryMo,	$scope.formData.cardExpiryYr,	$scope.formData.cardNumber,	$scope.formData.isPrimary,	$scope.formData.nameOnCard, '', function(response){
			$scope.closeModal("addedNewCreditCard", response);
		});
	}
	
	//listeners
	
	//Logic
	$scope.$emit('modalLoading', false);
});

getExpiryYears = function(){
	var expiryYears = [];
	var numberOfYears = 12;
	var startYear = (new Date()).getFullYear() - 2;

	for(var i = 0; i < numberOfYears; i++){
		expiryYears.push(startYear + i);
	}
	return expiryYears;
}