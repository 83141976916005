
pos.directive('ngstyle', function($compile) {
	return {
		restrict: 'E',
		link: function postLink(scope, element) {
			if (element.html()) {
				var stemplate = $compile('<style ng-bind-template="' + element.html() + '"></style>');
				element.replaceWith(stemplate(scope));
			}
		}
	};
});

angular.module('debounce', [])
	.service('debounce', ['$timeout', function ($timeout) {
		return function (func, wait, immediate) {
			var timeout, args, context, result;
			function debounce() {
				/* jshint validthis:true */
				context = this;
				args = arguments;
				var later = function () {
					timeout = null;
					if (!immediate) {
						result = func.apply(context, args);
					}
				};
				var callNow = immediate && !timeout;
				if (timeout) {
					$timeout.cancel(timeout);
				}
				timeout = $timeout(later, wait);
				if (callNow) {
					result = func.apply(context, args);
				}
				return result;
			}
			debounce.cancel = function () {
				$timeout.cancel(timeout);
				timeout = null;
			};
			return debounce;
		};
	}])
	.directive('debounce', ['debounce', function (debounce) {
		return {
			require: 'ngModel',
			priority: 999,
			scope: {
				debounce: '@',
				immediate: '@'
			},
			link: function ($scope, $element, $attrs, ngModelController) {
				var debouncedValue, pass;
				var prevRender = ngModelController.$render.bind(ngModelController);
				var commitSoon = debounce(function (viewValue) {
					pass = true;
					ngModelController.$setViewValue(viewValue);
					pass = false;
				}, parseInt($scope.debounce), $scope.immediate === 'true');
				ngModelController.$render = function () {
					prevRender();
					commitSoon.cancel();
					//we must be first parser for this to work properly,
					//so we have priority 999 so that we unshift into parsers last
					debouncedValue = this.$viewValue;
				};
				ngModelController.$parsers.unshift(function (value) {
					if (pass) {
						debouncedValue = value;
						return value;
					} else {
						commitSoon(ngModelController.$viewValue);
						return debouncedValue;
					}
				});
			}
		};
	}]);


pos.directive('iframeOnload', [function(){
	return {
			scope: {
					callBack: '&iframeOnload'
			},
			link: function(scope, element, attrs){
					element.on('load', function(){
							return scope.callBack();
					});
			}
	};
}]);

pos.directive('ngConfirmClick', [
	function(){
		return {
			priority: -1,
			restrict: 'A',
			link: function(scope, element, attrs){
				element.bind('click', function(e){
					var message = attrs.ngConfirmClick;
					if(message && !confirm(message)){
						e.stopImmediatePropagation();
						e.preventDefault();
					}
				});
			}
		};
	}
]);


pos.directive('errSrc', function() {
  return {
    link: function(scope, element, attrs) {
      element.bind('error', function() {
        if (attrs.src != attrs.errSrc) {
          attrs.$set('src', attrs.errSrc);
        }
      });
    }
  };
});


// Add this directive where you keep your directives
pos.directive('onLongPress', function($timeout) {
	return {
		restrict: 'A',
		link: function($scope, $elm, attrs) {

			angular.element(".products").bind("scroll", function() {
				$scope.longPress = false;
			});

			this.downEvent=function(evt,attrs){
				$scope.longPress = true;
				$scope.touchTime = $timeout(function() {
					if ($scope.longPress) {
						$scope.$apply(function() {
							$scope.$eval(attrs.onLongPress);
						});
					}
				}, 1000);
			}

			this.upEvent=function(evt,attrs){
				$timeout.cancel($scope.touchTime);
				$scope.longPress = false;
				if (attrs.onTouchEnd) {
					$scope.$apply(function() {
						$scope.$eval(attrs.onTouchEnd);
					});
				}
			};

			$elm.bind('touchstart', function(evt) {
				downEvent(evt,attrs);
			});

			$elm.bind('touchend', function(evt) {
				upEvent(evt,attrs);
			});


			$elm.bind('mousedown', function(evt) {
				downEvent(evt,attrs);
			});
 
			$elm.bind('mouseup', function(evt) {
				upEvent(evt,attrs);
			});
		}
	};
});