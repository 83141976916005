pos.storeManager.factory("orderService", function($http, session, globalData, globalFunctions, $timeout, cartArrayService){

	var addItemToRefund = function(item, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.addItemToRefund",{
			originalOrderItemID:item.originalOrderItemID,
			quantity:item.quantity,
			price:item.price,
			refundType:item.refundType,
			refundOrderID:session.refund.refundHeader.refundOrderID,
			inventoryPoolID: item.inventoryPoolID
		}, function(response){
			angular.copy(response.refund, session.refund); 
			(callback)(response);
		}, null, null, 0);
	}
	
	var addExchangeItemToRefund = function(product, callback){
		session.refundItems.push(product);
		cartArrayService.debounceAddToCartArray(callback);
	}

	var addTenderToRefund = function(originalOrderTenderID, amountTendered, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.addTenderToRefund",{
			originalOrderTenderID:originalOrderTenderID,
			amountTendered:amountTendered,
			refundOrderID:session.refund.refundHeader.refundOrderID,
			amountDue:session.refund.refundHeader.amountDue
		}, function(response){
			angular.copy(response.refund, session.refund);
			// Ensure that all tenders start off as being marked as not refunded.
			session.refund.refundHeader.orderTenders.forEach(function (value,index,array) {
				value.refunded = 0;
			});
			var terminalTenders = globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"paymentType", "Terminal");
			if(terminalTenders.length > 0){
				// We need to choose a single non-refunded tender to start off this process
				session.refund.chosenTender = globalFunctions.findInObject(terminalTenders,"refunded", 0)[0];
				response.nextModal = _nextModal("terminalRefund"); 
			}else{
				response.nextModal = _nextModal("refundProcess");
			}
			(callback)(response);
		}, null, null, 0);
	}
	
	var getAdditionalEmailsForContact = function(customerID, callback){
		globalFunctions.v65http("/store/index.cfm?method=checkout.getAdditionalEmailsForContact",{customerID:customerID},function(response){
			(callback)(response);
		}, null, 15000, 0);
	};
	
	var removeTenderFromRefund = function(orderTenderID,callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.removeTenderFromRefund",{
			orderTenderID:orderTenderID,
			refundOrderID:session.refund.refundHeader.refundOrderID
		}, function(response) {
			angular.copy(response.refund, session.refund); 
			(callback)(response)
		}, null, 15000);
	}
	
	var updateExchangeProduct = function(formData, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.updateExchangeItem",{
			productSKU:formData.productSKU,
			quantity:formData.quantity,
			price:formData.price,
			refundOrderID:session.refund.refundHeader.refundOrderID
		}, function(response){
			angular.copy(response.refund, session.refund);
			(callback)(response);
		}, null, 15000);
	}

	var authorizeLoyaltyPointsForRefund = function(orderTenderID,callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.authorizeLoyaltyPointsForRefund",{
			orderTenderID:orderTenderID,
			refundOrderID:session.refund.refundHeader.refundOrderID
		}, function(response) {
			(callback)(response)
		}, null, 15000);
	}

	var emailAddress = function(email, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.emailAddress", {email:email,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			(callback)(response)
		}, null, 15000)
	}

	var holdOrder = function(holdName, email, callback){
		globalFunctions.v65http("/store/index.cfm?method=orders.holdOrder",{holdName:holdName,email:email,posProfile:session.posProfile,orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, null, 0);
	}

	var listOrderSearchAttributes = function(callback){

		var callCompleteNumber = 0,
		response = {};
		
		function callComplete(partialResponseName, partialResponseObj){
			callCompleteNumber = callCompleteNumber + 1;
			response[partialResponseName] = partialResponseObj;
			if(callCompleteNumber == 6){
				(callback)(response)
			}
		}

		//Unifies 6 calls into one.
		globalFunctions.v65http("/orders/index.cfm?method=orders.listOrderTypes",{posProfile:session.posProfile},function(partialResponse){
			callComplete("orderTypes",partialResponse.orderTypes)
		}, null, 15000);

		globalFunctions.v65http("/orders/index.cfm?method=orders.listOrderStatuses",{posProfile:session.posProfile},function(partialResponse){
			callComplete("orderStatuses",partialResponse.orderStatuses)
		}, null, 15000);

		globalFunctions.v65http("/orders/index.cfm?method=orders.listOrderPaymentStatuses",{posProfile:session.posProfile},function(partialResponse){
			callComplete("orderPaymentStatuses",partialResponse.orderPaymentStatuses)
		}, null, 15000);

		globalFunctions.v65http("/orders/index.cfm?method=orders.listOrderShippingStatuses",{posProfile:session.posProfile},function(partialResponse){
			callComplete("orderShippingStatuses",partialResponse.orderShippingStatuses);
		}, null, 15000);
		
		globalFunctions.v65http("/orders/index.cfm?method=orders.listSalesAssociates",{posProfile:session.posProfile},function(partialResponse){
			callComplete("salesAssociates",partialResponse.salesAssociates)
		}, null, 15000);

		globalFunctions.v65http("/orders/index.cfm?method=orders.listWebserviceVendors",{posProfile:session.posProfile},function(partialResponse){
			callComplete("webserviceVendors",partialResponse.webserviceVendors)
		}, null, 15000);
	}

	var listOrders = function(request,callback){
		request.maxRows = request.maxRows || 20;
		globalFunctions.v65http("/orders/index.cfm?method=orders.listOrders",request,function(response){
			(callback)(response)
		}, null, 15000);
	}

	var listHeldOrders = function(page,maxRows,forCurrentSalesAssociate,callback){ 
		globalFunctions.v65http("/orders/index.cfm?method=orders.listHeldOrders",{page:page,maxRows:maxRows,forCurrentSalesAssociate:forCurrentSalesAssociate},function(response){
			(callback)(response)
		}, null, 15000);
	}
	
	var sendEmailConfirmation = function(orderID, email, posProfile, callback){
		globalFunctions.v65http("/orders/index.cfm?method=orders.sendEmailConfirmation",{orderID: orderID, email: email, posProfile:posProfile},function(response){
			if(callback) (callback)(response);
		}, null, null, 0);
	}

	var markOrderAsPickedUp = function(orderID,signature,callback){
		signature = signature ? signature.replace("data:image/png;base64,", "") : null;
		globalFunctions.v65http("/orders/index.cfm?method=pickup.markOrderAsPickedUp",{orderIDs:orderID,signature:signature},function(response){
			(callback)(response)
		},"POST", null, 0);
	}

	var initRefund = function(originalOrderID, posProfile, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.init",{originalOrderID:originalOrderID,posProfile:posProfile}, function(response){
			angular.copy(response.refund, session.refund); 
		 (callback)(response)
		}, null, null, 0);
	}
	
	var oneClickRefund = function(originalOrderID, posProfile, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.oneClickRefund",{originalOrderID:originalOrderID,posProfile:posProfile,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			angular.copy(response.refund, session.refund); 
		 (callback)(response)
		}, null, null, 0);
	}

	var refundHandling = function(handlingRefund, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.refundHandling",{handlingRefund:handlingRefund,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			angular.copy(response.refund, session.refund); 
			(callback)(response)
		}, null, null, 0);
	}

	var refundDeposit = function(depositRefund, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.refundDeposit",{depositRefund:depositRefund,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			angular.copy(response.refund, session.refund); 
			(callback)(response)
		}, null, null, 0);
	}
	
	var refundCrv = function(depositRefund, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.refundCrv",{crvRefund:crvRefund,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			angular.copy(response.refund, session.refund); 
			(callback)(response)
		}, null, null, 0);
	}

	var refundShipping = function(shippingRefund, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.refundShipping",{shippingRefund:shippingRefund,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			angular.copy(response.refund, session.refund); 
			(callback)(response)
		}, null, null, 0);
	}

	var refundTip = function(tipRefund, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.refundTip",{tipRefund:tipRefund,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			angular.copy(response.refund, session.refund); 
			(callback)(response)
		}, null, null, 0);
	}

	var resumeOrder = function(orderID, callback){
		globalFunctions.v65http("/store/index.cfm?method=orders.resumeOrder", {orderID:orderID,posProfile:session.posProfile}, function(response){
			angular.copy(response.customer, session.customer); 
			angular.copy(response.order, session.order);
			(callback)(response)
		}, null, null, 0);
	}

	var removeItemFromRefund = function(originalOrderItemID, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.removeItemFromRefund",{originalOrderItemID:originalOrderItemID,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			angular.copy(response.refund, session.refund); 
			(callback)(response)
		}, null, null, 0);
	}
	
	var removeExchangeItemFromRefund = function(formData, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.removeExchangeItemFromRefund",{productSKU:formData.productSKU,refundOrderID:session.refund.refundHeader.refundOrderID}, function(response){
			angular.copy(response.refund, session.refund); 
			(callback)(response)
		}, null, null, 0);
	}

	var refundWithTerminal = function(callback){
		response = {
			nextModal:_nextModal("terminalRefund")
		};
		if(callback) (callback)(response)
	};

	var unmarkOrderAsPickedUp = function(orderID, callback){
		globalFunctions.v65http("/orders/index.cfm?method=pickup.unmarkOrderAsPickedUp",{orderIDs:orderID},function(response){
			(callback)(response)
		}, null, null, 0);
	}

	var viewOrder = function(orderID, callback){
		globalFunctions.v65http("/orders/index.cfm?method=orders.viewOrder",{orderID:orderID},function(response){
			(callback)(response)
		}, null, 15000);
	}

	var processRefund = function(transactionInformation, sendEmail, includeSecondaryEmails, productTransactionID, locationID, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.processRefund", {transactionInformation:transactionInformation,sendEmail:sendEmail,includeSecondaryEmails:includeSecondaryEmails,refundOrderID:session.refund.refundHeader.refundOrderID,posProfile:session.posProfile, productTransactionID:productTransactionID, locationID:locationID}, function(response){
			(callback)(response)
		}, null, null, 0);
	}
	
	var validateRefund = function(callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.validateRefund",{refundOrderID:session.refund.refundHeader.refundOrderID},function(response){
			(callback)(response)
		}, null, null, 0);
	}
	
	//Private
	var _nextModal = function(nextStep){
		var amountDueCents = Number(session.refund.refundHeader.amountDueCents);
		switch(nextStep){	
			case "terminalRefund":
				// If there are no more terminal tenders to refund, then continue the refund process, otherwise call insertCardRefund again
				if(session.refund.chosenTender == undefined){
					nextModal = amountDueCents == 0 ? "v2/views/partials/modals/refundProcess" : "";
				} else {
					// Call payment request again recursively
					var modal = globalData.posInfo.hasEMV ? "v2/views/partials/modals/refundEMV" : "v2/views/partials/modals/insertCardRefund";
					nextModal = amountDueCents == 0 ? modal : "";
				}
			break;
			//Process Screen
			case "refundProcess":
				console.log("Process Refund");
				nextModal = amountDueCents == 0 ? "v2/views/partials/modals/refundProcess" : "";
			break;
		}
		return nextModal;
	}
	
	return {
		addItemToRefund:					addItemToRefund,
		addTenderToRefund:					addTenderToRefund,
		emailAddress:						emailAddress,
		getAdditionalEmailsForContact:		getAdditionalEmailsForContact,
		holdOrder: 							holdOrder,
		listOrders:							listOrders,
		listOrderSearchAttributes:			listOrderSearchAttributes,
		listHeldOrders:						listHeldOrders,
		sendEmailConfirmation:				sendEmailConfirmation,
		markOrderAsPickedUp:				markOrderAsPickedUp,
		processRefund:						processRefund,
		resumeOrder:						resumeOrder,
		refundHandling:						refundHandling,
		refundDeposit:						refundDeposit,
		refundCrv:							refundCrv,
		refundShipping:						refundShipping,
		refundWithTerminal:					refundWithTerminal,
		refundTip:							refundTip,
		removeItemFromRefund:				removeItemFromRefund,
		removeTenderFromRefund: 			removeTenderFromRefund,
		authorizeLoyaltyPointsForRefund: 	authorizeLoyaltyPointsForRefund,
		initRefund:							initRefund,
		unmarkOrderAsPickedUp:				unmarkOrderAsPickedUp,
		viewOrder:							viewOrder,
		addExchangeItemToRefund:			addExchangeItemToRefund,
		oneClickRefund:						oneClickRefund,
		updateExchangeProduct:				updateExchangeProduct,
		removeExchangeItemFromRefund:		removeExchangeItemFromRefund,
		validateRefund:						validateRefund
	}

});
