pos.store.controller("customer", function($scope, $rootScope, $location, session, globalFunctions, customerService, cartService){
 	//Variables
	$scope.customer = session.customer;
	$scope.order = session.order;

	//Functions
	$scope.clearCustomer = function(){
		$rootScope.$emit("cartLoading", true);
		cartService.init(null,function(){
			globalFunctions.clearSession();
			$rootScope.$emit("cartLoading", false);
		});
	}
	
	$scope.flagAction = function(path){
		$location.path(path);
	}
	
	$scope.removeCustomer = function(){
		$rootScope.$emit("cartLoading", true);
		customerService.removeCustomer(function(){
			$rootScope.$emit("cartLoading", false)
		});
	}
});


pos.store.controller("findCustomer", function($scope, globalData, globalFunctions, customerService){
	//Variables
	$scope.$emit('modalLoading', false);
	$scope.modalTitle = "Find Contact";
	$scope.modalSubmitTitle = "Search";
	$scope.customerResults = [];
	$scope.maxRows = 10;
	$scope.currentPage = 0;
	$scope.posInfo = globalData.posInfo;
	$scope.contactTypes = {}
	//Logic 


	customerService.listContactTypes(function(response){
		$scope.contactTypes = response.contactTypes.array;
	});

	//Functions
	$scope.searchCustomers = function(formData, currentPage, maxRows, callback){
		$scope.modalActionDefaults($scope.formData);
		customerService.searchCustomers(formData.firstName,
			formData.lastName,
			formData.email,
			formData.phone,
			formData.customerNumber,
			formData.city,
			formData.stateCode,
			formData.company,
			formData.contactTypeID,
			maxRows,
			currentPage+1, 
			function(response){
				// Clear messages from retries (if any)
				$scope.modalActionDefaults($scope.formData);
				$scope.searched = true;
				$scope.customerResults = response.customers.array;
				$scope.currentPage = currentPage;
				angular.element('.modal input').blur()
				globalFunctions.scrollTo('searched');
				if(callback) (callback)(response);
			}
		);
	}

	$scope.addCustomer = function(){
		$scope.searched = false;
		$scope.openModal("v2/views/partials/modals/addCustomer", $scope.formData)
	}

	$scope.closeModal = function(){
		$scope.$parent.closeModal();
		$scope.searched = false;
	}

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.$emit('modalLoading', true);
		$scope.searchCustomers($scope.formData, $scope.currentPage, $scope.maxRows, function(){
			$scope.$emit('modalLoading', false);
			$scope.modalButtonLoading = false
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('modalLoading', true);
		$scope.searchCustomers($scope.formData, page, $scope.maxRows, function(){
			$scope.$emit('modalLoading', false);
		});
	}

	$scope.selectCustomer = function(customer){
		$scope.modalActionDefaults($scope.formData);
		customerService.selectCustomer(customer, function(){
		// cartService.init(customer.customerID, function(){
			$scope.$parent.closeModal();
			$scope.searched = false;
		// });
		});
	}
});


pos.store.controller("addCustomer", function($scope, $rootScope, session, globalData, globalFunctions, customerService){
	//Variables
	$scope.modalTitle = "Add New Contact";
	$scope.modalSubmitTitle = "Add";
	$scope.modalData;
	$scope.posInfo = globalData.posInfo;
	$scope.showNonTaxable=session.posProfile.nonTaxableSetting;
	$scope.formData = {};
	$scope.formData.secondaryEmails = [];
	
	if(globalData.posInfo.hasSecondaryEmails){
		$scope.addField=function(){
			if($scope.formData.secondaryEmails.length < 5){
				$scope.formData.secondaryEmails.push({value: '', id: ''});
			}
		}

		$scope.deleteField=function(value){
			for(var secondaryEmail in $scope.formData.secondaryEmails){
				if(value.$$hashKey === $scope.formData.secondaryEmails[secondaryEmail].$$hashKey){
					$scope.formData.secondaryEmails.splice(secondaryEmail, 1)
				}
			}
		}
	}	

	//Logic
	$scope.$emit('modalLoading', false);

	//Functions
	$scope.closeModal = function(){
		$scope.$parent.closeModal();
	}

	$scope.modalAction = function(){
		$scope.modalActionDefaults();

		if($scope.formElements.$invalid){
			$scope.formValidation($scope);
			return false;
		}
		
		var isNonTaxable = $scope.showNonTaxable ? $scope.formData.isNonTaxable : 0;
		
		customerService.addCustomer(
			$scope.formData.firstName,
			$scope.formData.lastName,
			$scope.formData.phone,
			$scope.formData.smsNumber,
			$scope.formData.company,
			$scope.formData.birthDay,
			$scope.formData.birthMonth,
			$scope.formData.birthYear,
			$scope.formData.email,
			$scope.formData.secondaryEmails,
			$scope.formData.address,
			$scope.formData.address2,
			$scope.formData.city,
			$scope.formData.countryCode,
			$scope.formData.stateCode,
			$scope.formData.zipCode,
			$scope.formData.username,
			$scope.formData.password,
			$scope.formData.sourceCode,
			isNonTaxable,
			$scope.formData.FBProfileID,
			$scope.formData.twitterID,
			$scope.formData.salesAssociateID,
			function(response) {
				customerService.selectCustomer(response.customer, function(){
					$scope.$parent.closeModal();
				});
			}
		);
	}
});

