var pos =  angular.module('pos', ['store','storeManager', 'customerManager', 'ngTouch','ngStorage', 'ngAnimate','ui.router', 'duScroll', 'debounce', 'ngCookies', 'tandibar/ng-rollbar', 'ngSanitize'])
	// allow lodash DI for use in controllers
	.constant('_', window._)
	.run(function($rootScope, $location, coreService){
		//Run on application start

		// allow lodash to be used in views, ex. ng-repeat"x in _.range(3)"
		$rootScope._ = window._;
		$rootScope.features = window.vin65.features;

		$rootScope.sendGA = function(eventCategory, eventAction, eventLabel) {
			if(typeof(dataLayer) !== 'undefined') {
				dataLayer.push({
					'event' : 'GAEvent',
					'eventCategory' : eventCategory,
					'eventAction' : eventAction,
					'eventLabel' : eventLabel,
					'eventValue' : undefined
				});
			} else {
				console.info("Analytics not set up. Tracking captured.");
			}
		};

		// Refreshes POS every 2 hours
		$rootScope.lastActionTime = new Date().getTime();
		coreService.startLogoutTimeout();
	});

//Global Data
pos.factory("globalData", function($cookies){

	var 
	additionalOrderData = {},
	categories = [],
	coupons = [],
	customer = {},
	heldOrders = [],
	order = {},
	products = [],
	posInfo = {},
	posProfile = {},
	printerSettings = {},
	printers = [],
	reloaded = true;
	processingOrder = false;

	return{
		additionalOrderData:additionalOrderData,
		categories:categories,
		coupons:coupons,
		customer:customer,
		deviceType:deviceType,
		heldOrders:heldOrders,
		order:order,
		products:products,
		posInfo:posInfo,
		posProfile: posProfile,
		printerSettings:printerSettings,
		printers:printers,
		reloaded: reloaded,
		processingOrder: processingOrder
	};

});



pos.factory("storage", function($localStorage){
	return $localStorage;
});

//Global Functions
pos.factory("globalFunctions", function($http, $q, $rootScope, $location, $timeout, $anchorScroll, globalData, session, $localStorage, Rollbar, $cookieStore){
	
	var alertMessage = function(action,alertLevel,alertMessage,alertFields,response,loadingButton,loadingButtonType,isAlertActionable,alertActionableURL){
		var response = response || {}, //Set default
			loadingButton = (typeof loadingButton !== 'undefined') ? loadingButton : false,
			loadingButtonType = (typeof loadingButtonType !== 'undefined') ? loadingButtonType : 'dismiss';
		switch(action){

			// Something broke in a modal or modal form
			case "modal":
				$rootScope.$emit('modalError', {
					alertLevel:alertLevel,
					alertMessage:alertMessage,
					alertFields:alertFields,
					isAlertActionable:isAlertActionable,
					alertActionableURL:alertActionableURL
				});
			break;


			//Something went amiss in the charge area
			case "charge":
				$rootScope.$emit('chargeError', {
					alertLevel:alertLevel,
					alertMessage:alertMessage
				});
			break;

			//Something went amiss in the cart.
			case "cart":
				$rootScope.$emit('cartError', {
					alertLevel:alertLevel,
					alertMessage:alertMessage
				});
			break;
			
			//Something went amiss in the store/product area.
			case "store":
			$rootScope.$emit('storeError', {
				alertLevel:alertLevel,
				alertMessage:alertMessage
			});
			break;

			//Global Error
			case "global":
				$rootScope.$emit('globalError', {
					alertLevel:alertLevel,
					alertMessage:alertMessage,
					response:response
				});
			break;

			//iOS Notice (Used mainly for cash change)
			case "iosNotice":
				 $rootScope.$emit('iosNotice', {
					alertLevel:alertLevel,
					alertMessage:alertMessage
				 });
			break;
			
			// Message below loading spinner
			case "loadingNotice":
				$rootScope.$emit('loadingNotice', {
					loadingMessage:alertMessage,
					loadingButton:loadingButton,
					loadingButtonType:loadingButtonType
				});
			break;

			//Error in login screen
			case "login":
				$rootScope.$emit('loginNotice', {
					alertLevel:alertLevel,
					alertMessage:alertMessage
				});
			break;

			//Error with the form
			case "form":
				$rootScope.$emit('formError', {
					alertLevel:alertLevel,
					alertMessage:alertMessage,
					alertFields:alertFields
				});
			break;
			
			default:
				$rootScope.$emit('globalError', {
					alertLevel:alertLevel,
					alertMessage:alertMessage,
					response:response
				});
		}
	};

	var calculateCartHeight = function(){
		//Refactor, and place somewhere better than the global functions.
		$rootScope.cartHeights = {};
		$rootScope.cartHeights.documentHeight = angular.element(document).height();
		$rootScope.cartHeights.customerGroupHeight = angular.element(".cart").offset().top;
		$rootScope.cartHeights.orderHeaderHeight = angular.element(".orderHeader").height();
		angular.element(".orderItems").height($rootScope.cartHeights.documentHeight - ($rootScope.cartHeights.customerGroupHeight + $rootScope.cartHeights.orderHeaderHeight));
		scrollTo("bottom");
	};

	var clearSession = function(){
		angular.copy({}, session.table); //Resets table
	};

	var retrieveCurrentCategoryID = function(){
		//Refactor, and place somewhere better than the global functions.
		if(globalData.categories.length && $rootScope.currentCategory != "Search Results"){
			return findInObject(globalData.categories,'label', $rootScope.currentCategory)[0].POSCategoryID;
		}
	};

	//Closes Models and Dropdowns
	var closerFn = function(){
		//First global function I wrote, clean this up.
		$rootScope.closerFn = function(){
			$rootScope.closer = false;
		};
	};

	var findInObject = function(obj, key, val) {
		//Should create a utilities.js file
		var objects = [];
		for (var i in obj) {
			if (!obj.hasOwnProperty(i)) continue;
			if (typeof obj[i] == 'object') {
				objects = objects.concat(findInObject(obj[i], key, val));
			} else if (i == key && obj[key] == val) {
				objects.push(obj);
			}
		}
		return objects || "";
	};

	var flattenObject = function(ob) {
		//Should create a utilities.js file
		var toReturn = {};
		
		for (var i in ob) {
			if (!ob.hasOwnProperty(i)) continue;
			
			if ((typeof ob[i]) == 'object') {
				var flatObject = flattenObject(ob[i]);
				for (var x in flatObject) {
					if (!flatObject.hasOwnProperty(x)) continue;
					
					toReturn[x] = flatObject[x];
				}
			} else {
				toReturn[i] = ob[i];
			}
		}
		return toReturn;
	};

	var swipe = function(type, status){
		switch(type){
			case "creditCard":
				if(status == true){
					console.log("Credit Card Swipe On");
					swipeOn("creditCard");

				}else{
					console.log("Credit Card Swipe Off");
					swipeOff();
				}
			break;

			case "creditCardForm":
				if(status == true){
					console.log("Credit Card Swipe On");
					swipeOn("creditCardForm");

				}else{
					console.log("Credit Card Swipe Off");
					swipeOff();
				}
			break;

			case "driversLicense":
				if(status == true){
					console.log("Drivers License Swipe On");
					swipeOn("driversLicense");
				}else{
					console.log("Drivers License Swipe Off");
					swipeOff();
				}
			break;
			
			case "giftCard":
				if(status == true){
					console.log("Gift Card Swipe On");
					swipeOn("giftCard");
				}else{
					console.log("Gift Card Swipe Off");
					swipeOff();
				}
			break;

		}
	};

	var parseCFNumber = function(number){
		//Shouldn't be the frontends job to do this.
		// Replace spaces & "$" signs
		number = number.replace("$","").trim();
		// If negative value, replace brackets with negative number
		if(number.charAt(0) == "("){
			number = (number.replace("(","").replace(")","") * -1).toFixed(2);
		}

		return number;
	};

	var printReceipt = function(printer,orderID,receiptMethod, callback){
		if(!printer){
			url ="/store/index.cfm?method=checkout."+receiptMethod+"&orderID="+orderID+"&printType=letter";
		}else{
			url = "/store/index.cfm?method=checkout."+receiptMethod+"&orderID="+orderID+"&printType=receipt";
		}

		print(printer,url,callback);
	};

	var print = function(printer,url,callback){
		url += "&posProfileID="+session.posProfile.posProfileID;
		if(!printer){
			url = url.replace("&printType=receipt","&printType=letter");
			printPDF($location.host()+url);
			if(callback) (callback)();
		}else{
			requestReceiptPrinters(function(){
				globalData.printers.forEach(function(entry){
					if(printer == entry.macAddress){
						starPrint($location.host()+url,entry.index);
						if(callback) (callback)();
					}			
				});
			});
		}
	};

	var openCashDrawer = function(printer, callback){
		if(deviceType == "Desktop"){
			openCashDrawerApp();
		}else{
			requestReceiptPrinters(function(){
				globalData.printers.forEach(function(entry){
					if(printer == entry.macAddress){
						openCashDrawerApp(entry.index);
						console.log("Opened vin65://openCashDrawer");
						if(callback) (callback)();
					}			
				});
			});
		}
	};

	var scrollTo = function(hash){
		$timeout(function() {
			var currentHash = $location.hash();
			$location.hash(hash);
			$anchorScroll();
			$location.hash(currentHash);
		});
	};

	var emailError = function(type, response, headers, url, time){
		response = response || 0;
		switch(type){
			case "JSON":
				var message = "<h2>Error Detected</h2>"+
				"<strong>Method:</strong> "+url+"<br>"+
				"<strong>Device Name:</strong> "+globalData.posInfo.deviceName+" <br>"+
				"<strong>Website Name:</strong> "+ globalData.posInfo.websiteName+ "<br>" +
				"<br><br><hr><h2>Headers</h2>"+ headers+
				"<br><br><hr><h2>Response</h2>"+ response;


				if(response.length > 0){
					$.ajax({
					  type: "POST",
					  url: '/index.cfm?method=global.emailError',
					  data: {message:message}
					});
				}
				break;
		}
	};

	var checkJSON = function(response, headers, url, time, loadingButtonType){
		//Checks the JSON responses for errors.
		try {
			var jsonObject = JSON.parse(response); // verify that json is valid
			return jsonObject;
		}
		catch (e) {
			errorData = {
				response:response,
				headers:JSON.stringify(headers(), null, 4), 
				url:url,
				time:time
			};
			// HTML came back, most likely a cfdump, so hard error
			if (response.length && loadingButtonType !== 'retry') {
				alertMessage("global","JSON","Error, we got an invalid response from the server.",null, errorData);
			}
			// If response is blank, it's most likely a network connection issue, so retry call
			return errorData;
		}
	};

	var isDefined = function(object){
		var returnValue = false;
		try{
			if(eval(object)){
				returnValue = true;
			}
		} catch(e) {
			returnValue = false;
		} finally {
			return returnValue;
		}
	};
	
	// var randomLoadingMessage = function() {
	// 	var lines = new Array(
	// 		"Please wait... <span>Spinning up the hamster</span>",
	// 		"Please wait... <span>While we test your patience</span>",
	// 		"Please wait... <span>As if you had any other choice</span>",
	// 		"Please wait... <span>Would you like fries with that?</span>",
	// 		"Please wait... <span>At least you're not on hold!</span>",
	// 		"Please wait... <span>Sorry! We're really sorry! Sorry...</span>",
	// 		"Please wait... <span>Sorry a-boot this!</span>",
	// 		"Please wait... <span>Trying to wake up the sled dogs</span>",
	// 		"Please wait... <span>And enjoy the elevator music</span>",
	// 		"Please wait... <span>I should've had a V8 this morning</span>",
	// 		"Please wait... <span>Nice weather we're having eh?</span>"
	//
	// 	);
	// 	return lines[Math.round(Math.random()*(lines.length-1))];
	// };

	// var currentHttpRequest = 'asdasdasd';
	// var currentDefered = 'asdasdasd';

	var v65http = function(url, data, callback, requestType, httpTimeout, retryCount, loadingButtonType, failureCallback) {
		var headers = {},
			websiteID = localStorage.getItem("websiteID"),
			datasource = localStorage.getItem("datasource");
		if (websiteID !== null && datasource !== null) {
			Object.assign(headers, { 'websiteID': websiteID, 'datasource': datasource});
		}

		var deferred = $q.defer(),
			httpTimeout = httpTimeout || null,
			retryCount = (typeof retryCount !== 'undefined') ? retryCount : 3,
			slowRequestTimeout = $timeout(function() {
				$rootScope.sendGA(deviceType + ' POS HTTP Slow Request Message', 'hit', url);
				alertMessage("loadingNotice", null, "Your request is taking longer than usual.");
			}, 10000),
			clientTimeout = httpTimeout !== null ? $timeout(function() { deferred.resolve(); }, httpTimeout) : null;

		if (requestType == "POST") {
			Object.assign(headers, {'Content-Type': 'application/x-www-form-urlencoded'});
			$http({
				method: 'POST',
				url: url,
				headers: headers,
				transformRequest: function (data) {
					//Converts Request to work with objects.
					return angular.isObject(data) && String(data) !== '[object File]' ? convertPostData(data) : data;
				},
				transformResponse: function (response, headers) {
					var time = new Date();
					return checkJSON(response, headers, url, time, loadingButtonType);
				},
				data: data,
				timeout: deferred.promise
			}).then(function (response) {
				//Call Successful
				$timeout.cancel(slowRequestTimeout);
				if(clientTimeout !== null) $timeout.cancel(clientTimeout);
				alertMessage("loadingNotice", null, "");

				// If CF dump comes back, throw error and don't retry call
				if((typeof response.data.isCallSuccessful === 'undefined') && response.data.response.length) {
					Rollbar.error("POST Request - CFDUMP", response);
					throw 'cfdump';
				}

				successCallback(response.data, url, callback);
			}).catch(function (error) {
				console.log(error);
				$timeout.cancel(slowRequestTimeout);
				if(clientTimeout !== null) $timeout.cancel(clientTimeout);
				alertMessage("loadingNotice", null, "");

				if (loadingButtonType === 'retry' || error !== 'cfdump') {
					retryRequest(retryCount, loadingButtonType, url, function() {
						data.isRetry = true;
						v65http(url, data, callback, requestType, httpTimeout, retryCount-1, loadingButtonType);
					});
				}
			});
		} else {
			$rootScope.showModalLoading = false;

			return $http({
				url: url,
				method: 'GET',
				params: data,
				headers: headers,
				transformResponse: function (response, headers) {
					var time = new Date();
					return checkJSON(response, headers, url, time, loadingButtonType);
				},
				timeout: deferred.promise
			})
			.then(function (response) {
				//Call Successful
				$timeout.cancel(slowRequestTimeout);
				if(clientTimeout !== null) $timeout.cancel(clientTimeout);
				alertMessage("loadingNotice", null, "");

				// If CF dump comes back, throw error and don't retry call
				if((typeof response.data.isCallSuccessful === 'undefined') && response.data.response.length) {
					Rollbar.error("GET Request - CFDUMP",response);
					throw 'cfdump';
				}
				successCallback(response.data, url, callback);
			})
			.catch(function (error) {
				console.log(error);
				$timeout.cancel(slowRequestTimeout);
				if(clientTimeout !== null) $timeout.cancel(clientTimeout);
				alertMessage("loadingNotice", null, "");

				if(retryCount === 0 || error == 'cfdump'){
					if(failureCallback)(failureCallback)();
				}
				if (loadingButtonType === 'retry' || error !== 'cfdump') {
					retryRequest(retryCount, loadingButtonType, url, function() {
						data.isRetry = true;
						v65http(url, data, callback, requestType, httpTimeout, retryCount-1, loadingButtonType, failureCallback);
					});
				}
			});
		}
	};
	
	var retryRequest = function(retryCount, loadingButtonType, url, callback) {
		if (retryCount > 0) {
			$rootScope.sendGA(deviceType + ' POS HTTP Retry', 'hit', url);
			Rollbar.error("POS Retry",url);
			alertMessage("loadingNotice", null, "Your request is taking longer than usual. We'll retry in 3...");

			$timeout(function() {
				alertMessage("loadingNotice", null, "Your request is taking longer than usual. We'll retry in 2...");
			}, 1000)
				.then(function(){
					return $timeout(function() {
						alertMessage("loadingNotice", null, "Your request is taking longer than usual. We'll retry in 1...");
					}, 1000);
				})
					.then(function(){
						return $timeout(function() {
							alertMessage("loadingNotice", null, "");
							if(callback) (callback)();
						}, 1000);
					});
		} else {
			$rootScope.sendGA(deviceType + ' POS HTTP Retry Failed', 'hit', url);
			Rollbar.error("POS Retry Failed",url);
			alertMessage("loadingNotice", null, "Sorry! Something went wrong with your request, please try again in a moment.", null, null, true, loadingButtonType);
		}
	};

	// Private methods
	var convertPostData = function(obj) {
		var query = '';
		var name, value, fullSubName, subName, subValue, innerObj, i;

		for(name in obj){
			value = obj[name];

			if(value instanceof Array){
				for(i=0; i<value.length; ++i){
					subValue = value[i];
					fullSubName = name + '[' + i + ']';
					innerObj = {};
					innerObj[fullSubName] = subValue;
					query += convertPostData(innerObj) + '&';
				}
			} else if(value instanceof Object){
				for(subName in value){
					subValue = value[subName];
					fullSubName = name + '[' + subName + ']';
					innerObj = {};
					innerObj[fullSubName] = subValue;
					query += convertPostData(innerObj) + '&';
				}
			} else if(value !== undefined && value !== null){
				query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
			}
		}
		return query.length ? query.substr(0, query.length - 1) : query;
	};

	var successCallback = function(response, url, callback) {
		if(response.isCallSuccessful == 1) {
			var method = url.substring(url.length, url.lastIndexOf('.') + 1);
			if(response[method].isSuccessful == 1){
				$rootScope.lastActionTime = new Date().getTime();
				if (typeof callback == 'function') {
					callback(response);
				}

				//Cart items and forms can still be successful but have a alert message.
				if(response[method].alertMessage && response[method].alertAction.match(/^(cart|form|store|modal|charge)$/)){
					alertMessage(response[method].alertAction,response[method].alertLevel,response[method].alertMessage,response[method].alertFields,null,null,null,response[method].isAlertActionable,response[method].alertActionableURL);
				}
			}else{
				//The specific call failed.
				response[method].alertFields = [];

				//If we got back an order, we should update that.
				if(response.order){
					angular.copy(response.order, session.order);
				}
				if(response.refund){
					angular.copy(response.refund, session.refund);
				}

				if(response.errorArray){
					response[method].alertMessage = [];
					response[method].alertLevel = "error";
					angular.forEach(response.errorArray, function(value, key){
						response[method].alertMessage.push(value.message);
						response[method].alertFields.push(value.field);
					});
					response[method].alertMessage = response[method].alertMessage.join(", ");
				}
				alertMessage(response[method].alertAction,response[method].alertLevel,response[method].alertMessage,response[method].alertFields);
			}
		} else {
			//Something with the server failed
			alertMessage("global",response.errorArray[0].alertLevel,response.errorArray[0].alertMessage,null,response.errorLogID);
		}
	};
	
	var retrieveOrderIDFromSession = function(){
		return isRefund() ? session.refund.refundHeader.refundOrderID : session.order.orderHeader.orderID;
	};
	
	var fetchRefundOrderIfRefund = function(){
		return isRefund() ? session.refund : session.order;
	};
	
	var isRefund = function(){
		var url = window.location.href;
		return url.includes('refundOrder');
	};
	
	var validatePaymentTerminal = function(paymentGateway, hasOldEMV){
		if($localStorage.paymentTerminal && (paymentGateway !== 'USAePay' || !hasOldEMV)){
			delete $localStorage.paymentTerminal;
		}
	}
	
	var validateEMVTerminal = function(hasEMV){
		if($localStorage.emvTerminal && !hasEMV){
			delete $localStorage.emvTerminal;
		}
	}

	var validateNewZeamsterSettings = function(hasEMV, callback) {
		if (!hasEMV) return;
		var cookieName = 'NEWZEAMSTERCONFIG';
		if ($cookieStore.get(cookieName) == "1") return;
		if (callback) callback();
		$cookieStore.put(cookieName, "1");
	}

	// Set a flag that is enabled in the checkout controller when a payment method is open.
	// Prevents products to be added to the order when a payment is already being processed.
	var setProcessingFlag = function (flagValue) {
		globalData.processingOrder = flagValue;
	}

	return{
		alertMessage:alertMessage,
		calculateCartHeight:calculateCartHeight,
		clearSession:clearSession,
		retrieveCurrentCategoryID:retrieveCurrentCategoryID,
		closerFn:closerFn(),
		emailError:emailError,
		findInObject:findInObject,
		flattenObject:flattenObject,
		isDefined:isDefined,
		openCashDrawer:openCashDrawer,
		parseCFNumber:parseCFNumber,
		printReceipt:printReceipt,
		print:print,
		swipe:swipe,
		scrollTo:scrollTo,
		v65http:v65http,
		retryRequest:retryRequest,
		retrieveOrderIDFromSession:retrieveOrderIDFromSession,
		fetchRefundOrderIfRefund:fetchRefundOrderIfRefund,
		isRefund:isRefund,
		validateEMVTerminal:validateEMVTerminal,
		validateNewZeamsterSettings:validateNewZeamsterSettings,
		validatePaymentTerminal:validatePaymentTerminal,
		setProcessingFlag:setProcessingFlag
	};
});

pos.config(['RollbarProvider', function(RollbarProvider) {
	RollbarProvider.init({
		accessToken: "f4a6150f61e74220acacef070ec7e027",
		captureUncaught: false,
      	captureUnhandledRejections: false,
		scrubFields: ['creditCardNumber', 'loginData', 'password', 'cvv2', 'cardSwipe', 'cardNumber', 'cardExpiryMo', 'cardExpiryYr'],
		scrubTelemetryInputs: true,
		payload: {
			environment: window.location.hostname,
			client: {
				javascript: {
					source_map_enabled: true,
					code_version: version,
					guess_uncaught_frames: true
				}
			}
		}
	});
  }]);