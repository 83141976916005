pos.store.factory("inventoryService", function($http, session, globalData, globalFunctions, productService){

	var listInventoryPools = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=inventory.listInventoryPools",{posProfile:session.posProfile}, function(response){
			if(callback) (callback)(response);
		}, null, 15000);
	}
	
	var viewInventory = function(inventoryPoolID, productSKU, callback){
		globalFunctions.v65http("/store/index.cfm?method=inventory.viewInventory",{inventoryPoolID:inventoryPoolID,productSKU:productSKU,posProfile:session.posProfile,orderID:session.order.orderHeader.orderID},function(response){
			if(callback)	(callback)(response);
		}, null, 15000);
	}

	return{
		listInventoryPools: listInventoryPools,
		viewInventory: viewInventory
	};
});