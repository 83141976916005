pos.store.controller("shipping", function($scope, $rootScope, session, globalData, globalFunctions, shippingService, customerService, $timeout){
	//Variables
	$scope.modalTitle = "Shipping";
	$scope.modalSubmitTitle = "Apply";
	$scope.posInfo = globalData.posInfo;
	$scope.modalData;
	
	$scope.customer = globalFunctions.isRefund() ? session.refund.refundHeader.billCustomer : session.customer;
	$scope.order = globalFunctions.isRefund() ? session.refund : session.order;
	$scope.order.orderHeader = globalFunctions.isRefund() ? session.refund.refundHeader : session.order.orderHeader;
	$scope.addressBlock = shippingService.loadAddressBlock();
	
	//Functions
	$scope.addressBookEntry = function(address){
		if (!address) {
			$scope.formData = angular.copy(addNewAddress);
		} else {
			$scope.formData = address;
		}

		$scope.formData.giftMessage=''; //Needs to be in here because of object building...
	};

	$scope.loadAddressBlock = function(countryCode){
		$scope.addressBlock = shippingService.loadAddressBlock(countryCode);
	}

	$scope.copyBillingAddress = function(state){
		if(state === true){
				$scope.formData.shipNickName = "Add New Shipping Address";
				$scope.formData.shipBirthDate = $scope.customer.birthDate;
				$scope.formData.shipBirthDay = $scope.customer.birthDay;
				$scope.formData.shipBirthMonth = $scope.customer.birthMonth;
				$scope.formData.shipBirthYear = $scope.customer.birthYear;
				$scope.formData.shipFirstName = $scope.customer.firstName;
				$scope.formData.shipLastName = $scope.customer.lastName;
				$scope.formData.shipCompany = $scope.customer.company;
				$scope.formData.shipAddress = $scope.customer.address;
				$scope.formData.shipAddress2 = $scope.customer.address2;
				$scope.formData.shipCity = $scope.customer.city;
				$scope.formData.shipCountryCode = $scope.customer.countryCode;
				$scope.formData.shipStateCode = $scope.customer.stateCode;
				$scope.formData.shipZipCode = $scope.customer.zipCode;
				$scope.formData.shipPhone = $scope.customer.phone;
				$scope.formData.shipEmail = $scope.customer.email;
		}else{
			$scope.formData = angular.copy(addNewAddress);
		}
	}

	$scope.editCustomer = function(alertLevel, alertMessage){
		$scope.openModal('v2/views/customer/dashboard/editCustomer',{toBeFilled:1, alertLevel:alertLevel, alertMessage: alertMessage});
		$rootScope.$on("editedCustomer", function(event, data){
			$scope.customer = data.customer;
			$scope.openModal('v2/views/partials/modals/shipping');
			$rootScope.$$listeners.editedCustomer=[];
		});
	}

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$rootScope.selectedAddressBookIndex = $scope.listAddressBook.indexOf($scope.address);

		for(item in billingAddress){
			if(!$scope.formData[item]){
				$scope.formData[item] = billingAddress[item];
			}
		}

		shippingService.addShippingAddress($scope.formData.billAddress, $scope.formData.billAddress2, $scope.formData.billBirthDate, $scope.formData.billBirthDay, $scope.formData.billBirthMonth, $scope.formData.billBirthYear, $scope.formData.billCity, $scope.formData.billCompany, $scope.formData.billEmail, $scope.formData.billFirstName, $scope.formData.billLastName, $scope.formData.billNickName, $scope.formData.billPhone, $scope.formData.billCountryCode, $scope.formData.billStateCode, $scope.formData.billZipCode, $scope.formData.shipAddress, $scope.formData.shipAddress2, $scope.formData.shipBirthDate, $scope.formData.shipBirthDay, $scope.formData.shipBirthMonth, $scope.formData.shipBirthYear, $scope.formData.shipCity, $scope.formData.shipCompany, $scope.formData.shipEmail, $scope.formData.shipFirstName, $scope.formData.shipLastName, $scope.formData.shipNickName, $scope.formData.shipPhone, $scope.formData.shipCountryCode, $scope.formData.shipStateCode, $scope.formData.shipZipCode, $scope.formData.giftMessage, function(response){
			$scope.openModal("v2/views/partials/modals/shippingTypes",$scope.formData);
		})

		if($scope.address && $scope.address['nickName'] == "Add New Shipping Address"){
			customerService.addAddressBookEntry($scope.formData.address, $scope.formData.address2, $scope.formData.birthDate, $scope.formData.birthDay, $scope.formData.birthMonth, $scope.formData.birthYear, $scope.formData.city, $scope.formData.company, $scope.formData.email, $scope.formData.firstName, $scope.formData.isPrimary, $scope.formData.lastName, $scope.formData.nickName, $scope.formData.phone, $scope.formData.shipCustomerID, $scope.formData.stateCode, $scope.formData.zipCode, function(response){
				//Callback.
			});
		}
	};


 	//This needs to be in an init() like I do with additional order..
	var addNewAddress = {"shipCustomerID":"",
	"shipNickName":"Add New Shipping Address",
	"shipShipBirthDate":"",
	"shipBirthDay":"",
	"shipBirthMonth":"",
	"shipBirthYear":"",
	"shipFirstName":"",
	"shipLastName":"",
	"shipCompany":"",
	"shipAddress":"",
	"shipAddress2":"",
	"shipCity":"",
	"shipCountryCode":"",
	"shipStateCode":"",
	"shipZipCode":"",
	"shipHtmlFormattedAddress":"",
	"shipStringAddress":"",
	"shipPhone":"",
	"shipEmail":"",
	"giftMessage":""};

	var billingAddress = {"shipCustomerID":"BillingAddress",
	"billNickName":$scope.customer.nickName,
	"billBirthDate":$scope.customer.birthDate,
	"billBirthDay":$scope.customer.birthDay,
	"billBirthMonth":$scope.customer.birthMonth,
	"billBirthYear":$scope.customer.birthYear,
	"billFirstName":$scope.customer.firstName,
	"billLastName":$scope.customer.lastName,
	"billCompany":$scope.customer.company,
	"billAddress":$scope.customer.address,
	"billAddress2":$scope.customer.address2,
	"billCity":$scope.customer.city,
	"billCountryCode":$scope.customer.countryCode,
	"billStateCode":$scope.customer.stateCode,
	"billZipCode":$scope.customer.zipCode,
	"billPhone":$scope.customer.phone,
	"billEmail":$scope.customer.email};

	//Logic
	if($scope.customer.customerID){
		if(!$scope.customer.address || !$scope.customer.firstName || !$scope.customer.phone || !$scope.customer.lastName || !$scope.customer.city || !$scope.customer.stateCode){
			$scope.editCustomer('warning', 'In order to ship, user must have a full billing address.');
		}
	}
	
	$scope.copyShipCustomerFromOrderHeader = function(){
		if(!$scope.modalData){
			// If its a refund order, don't fill the form if it was a guest customer order
			if(!globalFunctions.isRefund() || (globalFunctions.isRefund() && !$scope.isGuestCustomerAddress())){
				$scope.formData = $scope.order.orderHeader.shipCustomer;
				$scope.formData.shipNickName = $scope.order.orderHeader.shipCustomer.nickName;
				$scope.formData.shipShipBirthDate = $scope.order.orderHeader.shipCustomer.birthDate;
				$scope.formData.shipBirthDay = $scope.order.orderHeader.shipCustomer.birthDay;
				$scope.formData.shipBirthMonth = $scope.order.orderHeader.shipCustomer.birthMonth;
				$scope.formData.shipBirthYear = $scope.order.orderHeader.shipCustomer.birthYear;
				$scope.formData.shipFirstName = $scope.order.orderHeader.shipCustomer.firstName;
				$scope.formData.shipLastName = $scope.order.orderHeader.shipCustomer.lastName;
				$scope.formData.shipCompany = $scope.order.orderHeader.shipCustomer.company;
				$scope.formData.shipAddress = $scope.order.orderHeader.shipCustomer.address;
				$scope.formData.shipAddress2 = $scope.order.orderHeader.shipCustomer.address2;
				$scope.formData.shipCity = $scope.order.orderHeader.shipCustomer.city;
				$scope.formData.shipCountryCode = $scope.order.orderHeader.shipCustomer.countryCode;
				$scope.formData.shipStateCode = $scope.order.orderHeader.shipCustomer.stateCode;
				$scope.formData.shipZipCode = $scope.order.orderHeader.shipCustomer.zipCode;
				$scope.formData.shipHtmlFormattedAddress = $scope.order.orderHeader.shipCustomer.htmlFormattedAddress;
				$scope.formData.shipStringAddress = $scope.order.orderHeader.shipCustomer.stringAddress;
				$scope.formData.shipPhone = $scope.order.orderHeader.shipCustomer.phone;
				$scope.formData.shipEmail = $scope.order.orderHeader.shipCustomer.email;
			}
		}
		$scope.setGiftMessage();
	}
	
	$scope.setGiftMessage = function(){
		//Triggers when back button is clicked
		if($scope.modalData){
			$scope.formData.giftMessage = (typeof($scope.modalData.giftMessage) != 'undefined' && $scope.modalData.giftMessage.length) ? $scope.modalData.giftMessage : '' ;
		}else{
			$scope.formData.giftMessage=''; //Needs to be in here...
		}
	}
	
	$scope.isGuestCustomerAddress = function(){
		return $scope.order.orderHeader.shipCustomer.firstName == 'Guest' && $scope.order.orderHeader.shipCustomer.lastName == 'Customer';
	}

	$scope.listAddressBook = shippingService.listAddressBook($scope.customer.customerID,function(response){
		$scope.$emit('modalLoading', false);

		if($scope.order.orderHeader.isPickup == 0){
			$scope.copyShipCustomerFromOrderHeader();
		}else if($scope.customer.customerID && !globalFunctions.isRefund()){
			$rootScope.selectedAddressBookIndex = 0;
			$scope.formData = response.addressBook[0];
			$scope.formData.giftMessage=''; //Needs to be in here...
		}

		$scope.listAddressBook = response.addressBook;
		$scope.listAddressBook.map(function(element){
			if(element.shipCustomerID == "BillTo"){
				element.shipNickName = "Billing Address";
			}
		});
		
		// Logic for refund shipping window
		if(globalFunctions.isRefund()) {
			$scope.copyShipCustomerFromOrderHeader();
			
			// We want to try grab the correct address book from the original order based on shipMemberID
			if(typeof($rootScope.selectedAddressBookIndex) === 'undefined'){
				if($scope.order.orderHeader.shipCustomer.customerID.length){
					// It is possible that the shipMember has been deleted in the time since the order was placed, so no match will be found
					var index = $scope.listAddressBook.map(function(obj, index) {
						if(obj.shipCustomerID == $scope.order.orderHeader.shipCustomer.customerID) {
							return index;
						}
					}).filter(isFinite)
					$rootScope.selectedAddressBookIndex = index >= 0 ? index : 0;
				}else if(response.addressBook.length){
					// If it was a POS order, we likely didn't save the shipMemberID to the order, so we default to billTo
					$rootScope.selectedAddressBookIndex = 0;
				}
			}
			if(typeof($rootScope.selectedAddressBookIndex) != 'undefined'){
				$scope.formData = response.addressBook[$rootScope.selectedAddressBookIndex];
				$scope.setGiftMessage(); //Needs to be in here...
			}
		};

		$scope.address = response.addressBook[$rootScope.selectedAddressBookIndex];

		if($scope.customer.customerID){
			$scope.listAddressBook.push(angular.copy(addNewAddress));
		}
	});
});



pos.store.controller("shippingTypes", function($scope, $rootScope, session, globalData, globalFunctions, shippingService){
	//Variables	
	$scope.modalTitle = "Shipping Types";
	$scope.modalCancelTitle = "Back";
	$scope.modalSubmitTitle= "Apply";
	
	$scope.customer = globalFunctions.isRefund() ? session.refund.refundHeader.billCustomer : session.customer;
	$scope.order = globalFunctions.isRefund() ? session.refund : session.order;
	$scope.order.orderHeader = globalFunctions.isRefund() ? session.refund.refundHeader : session.order.orderHeader;
	
	$scope.modalData;
	$scope.formData = {};


	//Logic
	shippingService.listShippingTypes(function(response){
		$scope.$emit('modalLoading', false);
		$scope.shippingStrategies = response.shippingStrategies;
		$scope.fulfillment = response.fulfillment;
		$scope.formData.fulfillmentHouse = $scope.order.orderHeader.fulfillmentHouse;
	});


	//Functions
	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/shipping",$scope.modalData);
	};

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
			shippingService.selectFulfillment($scope.formData.fulfillmentHouse, function(response){ //Need to submit the selected fulfillment option even if no shipping type is chosen
				if($rootScope.isRefund) $rootScope.$emit("updateRefund", response.refund);
				$scope.$parent.closeModal();
			});
	};

	$scope.selectShippingType = function(shippingStrategy, shippingTypeID, fulfillmentHouse, closeModal){
		shippingService.selectShippingType(shippingStrategy.shippingStrategyID, shippingTypeID, fulfillmentHouse, function(response){
			if(closeModal == 1){
				if($rootScope.isRefund) $rootScope.$emit("updateRefund", response.refund);
				$scope.$parent.closeModal();
			}
		});
	}
});



pos.store.controller("overrideShipping", function($scope, $rootScope, globalData, globalFunctions, shippingService){
	//Variables	
	$scope.modalTitle = "Override Shipping";
	$scope.modalData;

	//Logic
	$scope.$emit('modalLoading', false);

	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		if(globalFunctions.isRefund()){
			shippingService.overrideExchangeShipping($scope.formData.price, function(response){
				$rootScope.$emit("updateRefund", response.refund);
				$scope.closeModal();
			});
		}else{
			shippingService.overrideShipping($scope.formData.price, function(){
				$scope.closeModal();
			});
		}
	}

	$scope.removeShippingAddress = function(){
		$scope.modalActionDefaults($scope.formData);
		shippingService.removeShippingAddress(function(response){
			if(globalFunctions.isRefund()) $rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
});
