pos.factory("paymentTerminalService", function($http, session, globalData, globalFunctions, $localStorage){
	
	var addPaymentTerminal = function(terminalData,callback){
		globalFunctions.v65http("/index.cfm?method=paymentTerminals.addPaymentTerminal",{terminalData:terminalData,posProfile:session.posProfile,posDeviceProfile:session.posDeviceProfile},function(response){
			if(callback) (callback)(response);
		}, null, null, 0);
	};

	var listPaymentTerminals = function(callback){
		globalFunctions.v65http("/index.cfm?method=paymentTerminals.listPaymentTerminals",{posProfile:session.posProfile,posDeviceProfile:session.posDeviceProfile,localStorage:$localStorage},function(response){
			if(callback) (callback)(response);
		}, null, 15000);
	};

	var editPaymentTerminal = function(formData, callback){
		console.log(formData);
		globalFunctions.v65http("/index.cfm?method=paymentTerminals.editPaymentTerminal", {paymentTerminalID:formData.paymentTerminalID,nickName:formData.nickName,paymentTerminalID:formData.paymentTerminalID,posProfile:session.posProfile,posDeviceProfile:session.posDeviceProfile}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var deletePaymentTerminal = function(formData, callback){
		globalFunctions.v65http("/index.cfm?method=paymentTerminals.deletePaymentTerminal", {paymentTerminalID:formData.paymentTerminalID}, function(response){
			(callback)(response);
		}, null, 15000);
	}
	
	var getPairingCode = function(nickname, callback){
		globalFunctions.v65http("/index.cfm?method=paymentTerminals.getPairingCode", {paymentTerminalNickname:nickname}, function(response){
			(callback)(response);
		}, null, 15000);
	}
	
	return{
		addPaymentTerminal: 	addPaymentTerminal,
		deletePaymentTerminal: deletePaymentTerminal,
		editPaymentTerminal: 	editPaymentTerminal,
		listPaymentTerminals: 	listPaymentTerminals,
		getPairingCode: getPairingCode
	};
});