pos.store.factory("customerService", function($http, $rootScope, session, globalData, globalFunctions, productService){

	var addAddressBookEntry = function(address, address2, birthDate, birthDay, birthMonth, birthYear, city, company, email, firstName, isPrimary, lastName, nickName, phone, shipCustomerID, stateCode, zipCode, callback){

		globalFunctions.v65http("/customer/index.cfm?method=addressbook.addAddressBookEntry", {address:address,
		address2:address2,
		birthDate:birthDate, 
		birthDay:birthDay,
		birthMonth:birthMonth,
		birthYear:birthYear,
		city:city,
		company:company,
		email:email,
		firstName:firstName,
		isPrimary:0,
		lastName:lastName,
		nickName:firstName+" "+lastName,
		phone:phone,
		shipCustomerID:shipCustomerID, 
		stateCode:stateCode,
		zipCode:zipCode,
		posProfile:session.posProfile,
		orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, null, 0);
	}

	var addCustomer = function(firstName,lastName,phone,smsNumber,company,birthDay,birthMonth,birthYear,email,secondaryEmails, address,address2,city,countryCode,stateCode,zipCode,username,password,sourceCode,isNonTaxable,FBProfileID,twitterID,salesAssociateID,callback){
		var secondaryEmailArray = JSON.stringify(_separateSecondaryEmails(secondaryEmails));
		
		globalFunctions.v65http("/customer/index.cfm?method=customer.addCustomer",{firstName:firstName,
		lastName:lastName,
		phone:phone,
		smsNumber:smsNumber,
		company:company,
		birthDay:birthDay,
		birthMonth:birthMonth,
		birthYear:birthYear,
		email:email,
		secondaryEmails:secondaryEmailArray,
		address:address,
		address2:address2,
		city:city,
		countryCode:countryCode,
		stateCode:stateCode,
		zipCode:zipCode,
		username:username,
		password:password,
		sourceCode:sourceCode,
		isNonTaxable:isNonTaxable,
		FBProfileID:FBProfileID,
		twitterID:twitterID,
		salesAssociateID:salesAssociateID}, function(response){
			angular.copy(response.customer, session.customer);  
			(callback)(response);
		}, "POST", null, 0);
	}
	
	var _separateSecondaryEmails = function(secondaryEmails){
		var emailArray = [];
		for(var secondaryEmail in secondaryEmails){
			if((secondaryEmails[secondaryEmail].value).length > 0){
				emailArray.push(secondaryEmails[secondaryEmail])
			}
		}

		return emailArray;
	}

	var listCreditCards = function(callback){
		var customerID = globalFunctions.isRefund() ? session.refund.refundHeader.billCustomer.customerID : session.customer.customerID;
		globalFunctions.v65http("/store/index.cfm?method=customers.listCreditCards",{customerID:customerID,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listContactTypes = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=customers.listContactTypes",{maxRows:1000,posProfile:session.posProfile,orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var searchCustomers = function(firstName,lastName,email,phone,customerNumber,city,stateCode,company,contactTypeID,maxRows,page,callback){
		globalFunctions.v65http("/store/index.cfm?method=customers.searchCustomers",{firstName:firstName,
			lastName:lastName,
			email:email,
			phone:phone,
			customerNumber:customerNumber,
			city:city,
			stateCode:stateCode,
			company:company,
			contactTypeID:contactTypeID,
			maxRows:maxRows,
			page:page,
			posProfile:session.posProfile, orderID:session.order.orderHeader.orderID
		},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var selectCustomer = function(customer, callback){
		globalFunctions.v65http("/store/index.cfm?method=customers.selectCustomer", {customerID:customer.customerID,posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response){
			angular.copy(response.order, session.order); 
			angular.copy(response.customer, session.customer);
			productService.listProducts(globalFunctions.retrieveCurrentCategoryID(), session.order.orderHeader.orderID, function(){
				(callback)(response);
			}, false, true);
		}, null, 15000);
	}
	
	var removeCustomer = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=customers.removeCustomer", {posProfile:session.posProfile, orderID:session.order.orderHeader.orderID}, function(response){
			angular.copy(response.order, session.order); 
			angular.copy(response.customer, session.customer);
			productService.listProducts(globalFunctions.retrieveCurrentCategoryID(), session.order.orderHeader.orderID, function(){
				(callback)(response);
			}, false, true);
		}, null, 15000);
	}

	var swipeDriversLicense = function(cardSwipe, callback){
		globalFunctions.v65http("/customer/index.cfm?method=customer.swipeDriversLicense", {cardSwipe:cardSwipe,posProfile:session.posProfile,orderID:session.order.orderHeader.orderID}, function(response){
			(callback)(response);
		}, null, null, 0);
	}

	var viewCustomer = function(customerID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=customer.viewCustomer", {customerID:customerID, posProfile:session.posProfile, orderID:globalFunctions.retrieveOrderIDFromSession()}, function(response){
			angular.copy(response.customer, session.customer); 
			if(callback)(callback)(response)
		}, null, 15000);
	}

	return{
		addAddressBookEntry:addAddressBookEntry,
		addCustomer:addCustomer,
		listCreditCards:listCreditCards,
		listContactTypes:listContactTypes,
		searchCustomers:searchCustomers,
		selectCustomer:selectCustomer,
		removeCustomer:removeCustomer,
		swipeDriversLicense:swipeDriversLicense,
		viewCustomer:viewCustomer
	};
});