pos.service("pinSessionService", pinSessionService);

function pinSessionService ($http, $cookies, session, globalData, globalFunctions) {
	var service = this;
	
	service.init = init;
	service.pinsEnabled = pinsEnabled;
	service.deleteSessionCookies = deleteSessionCookies;
	service.getCookie = getCookie;	
	service.cookieExists = cookieExists;
	
	function init (response){
		return new Promise(function(resolve, reject){
			createCookie('posPinWebsiteID', response.websiteID);
			createCookie('posPinWebsiteName', response.websiteName);
			resolve(true);
		});
	}
	
	function pinsEnabled(){
		return (service.cookieExists('posPinWebsiteID'));
	}
	
	function createCookie(cookieName, cookieValue){
		document.cookie = cookieName + "=" + cookieValue + ";" + cookieExpiryDate() + ";path=/";
	}
	
	function getCookie(cookieName){
		return service.cookieExists(cookieName) ? $cookies[cookieName] : null;
	}
	
	function cookieExpiryDate(){
		var tomorrowsDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
		return "expires=" + tomorrowsDate.toUTCString();
	}
	
	function cookieExists(cookieName){
		return typeof $cookies[cookieName] !== "undefined";
	}
	
	function deleteSessionCookies(){
		delete $cookies.posPinWebsiteID;
		delete $cookies.posPinWebsiteName;
	}
}