pos.factory("settingsService", function($http, session, globalData, globalFunctions, $cookies){

	var editPrinter = function(hasCashDrawer,macAddress,nickName,posPrinterProfileID, callback){
		globalFunctions.v65http("/index.cfm?method=printers.editPrinter", {hasCashDrawer:hasCashDrawer,macAddress:macAddress,nickName:nickName,posPrinterProfileID:posPrinterProfileID,posProfile:session.posProfile,posDeviceProfile:session.posDeviceProfile}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var editSettings = function(formData, callback){
		session.posDeviceProfile.defaultPrinterProfileID = formData.defaultPrinterProfileID || "";
		session.posDeviceProfile.defaultCashDrawerProfileID = formData.defaultCashDrawerProfileID || "";

		globalFunctions.v65http("/index.cfm?method=printers.editSettings", {
			defaultPrinterProfileID: formData.defaultPrinterProfileID,
			defaultCashDrawerProfileID: formData.defaultCashDrawerProfileID,
			posProfileID: formData.posProfileID,
			posDeviceProfile: session.posDeviceProfile
		}, function(response){
			angular.copy(response.posProfile, session.posProfile);
			(callback)(response);
		}, null, 15000);
	}

	var printersInit = function(printers, callback){
		globalFunctions.v65http("/index.cfm?method=printers.init",{printers:printers,posDeviceProfile:JSON.stringify(session.posDeviceProfile)},function(response){
			angular.copy(response, globalData.printerSettings); 
			angular.copy(printers, globalData.printers); 
			if(callback){
				(callback)(response);
			}
		},"POST", null, 0);
	}

	var getIPAddress = function(callback){
		globalFunctions.v65http("/index.cfm?method=global.getIPAddress", {}, function(response){
			(callback)(response);
		});
	}

	var sendSupportEmail = function(clientNotes, errorMessage, callback){

		globalFunctions.v65http("/index.cfm?method=global.supportEmail", {	globalData:JSON.stringify(globalData),
																			additionalOrderData: session.additionalOrderData ? JSON.stringify(session.additionalOrderDataFormData) : "",
																			posDeviceProfile:JSON.stringify(session.posDeviceProfile),
																			posProfile:JSON.stringify(session.posProfile),
																			v65User:$cookies["V65USER"],
																			clientNotes: clientNotes,
																			errorMessage: errorMessage,
																			latencyStat: typeof session.speedtest != 'undefined' && session.speedtest.latencyStat ? JSON.stringify(session.speedtest.latencyStat) : "",
																			downloadStat: typeof session.speedtest != 'undefined' && session.speedtest.downloadStat ? JSON.stringify(session.speedtest.downloadStat) : ""

		}, function(response){
			(callback)(response);
		},"POST");
	}

	var viewSettings = function(printers, callback){
		globalFunctions.v65http("/index.cfm?method=printers.viewSettings", {printers:printers,posDeviceProfile:JSON.stringify(session.posDeviceProfile),posProfile:JSON.stringify(session.posProfile)}, function(response){
			(callback)(response);	
		},"POST", 15000);
	}


	return{
		editPrinter: 	editPrinter,
		editSettings:	editSettings,
		printersInit:	printersInit,
		getIPAddress: 	getIPAddress,
		sendSupportEmail: sendSupportEmail,
		viewSettings:	viewSettings
	};
});