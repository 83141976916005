pos.store.controller("holdOrder", function($scope, $rootScope, $timeout, session, globalData, globalFunctions, orderService, cartService){
	//Variables
	$scope.modalTitle = "Hold Order";
	$scope.modalSubmitTitle = "Apply";
	$scope.customer = session.customer;
	$scope.order = session.order;

	//Logic
	$scope.$emit('modalLoading', false);
	
	if(globalData.posInfo.posWorkflow){
		$timeout(function(){
			document.getElementById('holdOrder').focus();
		},1)
	}	

	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.holdOrder($scope.formData.holdName, $scope.formData.email, function(response){
			cartService.init(null, function(){
				globalFunctions.clearSession();
				$scope.closeModal();
				$scope.toggleMenu();
			});
		});
	};
});

pos.store.controller("resumeOrder", function($scope, $rootScope, session, globalFunctions, orderService, checkoutService){
	//Variables
	$scope.modalTitle = "Resume Order";
	$scope.modalSubmitTitle = "hide";

	//Logic
	$scope.$emit('modalLoading', false);

	//Functions
	$scope.resumeOrder = function(orderID){
		$scope.$emit('modalLoading', true);
		orderService.resumeOrder(orderID, function(response){
			if(response.order.orderHeader.orderTenders.length){
				$rootScope.isSplitPayment = 1;
			}
			if(session.paymentTypes.length == 0){
				checkoutService.listPaymentTypes(function(){
					$rootScope.$emit('cartLoading', false);
				});
			} else {
				$rootScope.$emit('cartLoading', false);
			}
			$scope.$emit('modalLoading', false);
			globalFunctions.clearSession();
			angular.copy(response.table, session.table);
			$scope.closeModal();
			$scope.toggleMenu();
		});
	}
});

pos.store.controller("listHeldOrders", function($scope, globalFunctions, orderService){
	//Variables
	$scope.heldOrders = [];
	$scope.pagination = { maxRows: 10, currentPage: 0, recordCount: 0 };
	$scope.forCurrentSalesAssociate = false;
	$scope.currentSalesAssociateID = '';
	
	//Logic
	$scope.loading = true;
	$scope.numberOfPages = function(){
		return Math.ceil($scope.pagination.recordCount/$scope.pagination.maxRows);
	}

	//Functions
	$scope.listHeldOrders = function(currentPage, callback){
		orderService.listHeldOrders(currentPage+1, $scope.pagination.maxRows, $scope.forCurrentSalesAssociate, function(response){
			$scope.currentSalesAssociateID = response.currentSalesAssociateID;
			$scope.heldOrders = response.orders.array;
			$scope.pagination.recordCount = response.orders.pagination.totalRecordCount;
			$scope.pagination.currentPage = currentPage;
			$scope.loading = false;
			if(callback) (callback)(response);
		});
	}

	$scope.goToPage = function(page){
		$scope.loading = true;
		$scope.listHeldOrders(page, function(){
			$scope.loading = false;
			globalFunctions.scrollTo("alert");
		});
	}
});

pos.store.controller("listOpenTables", function($scope, globalFunctions, tableService){
	//Variables
	$scope.openTables = [];
	$scope.pagination = { maxRows: 10, currentPage: 0, recordCount: 0 };

	//Logic
	$scope.loading = true;
	$scope.numberOfPages = function(){
		return Math.ceil($scope.pagination.recordCount/$scope.pagination.maxRows);
	}

	//Functions
	$scope.listOpenTables = function(currentPage, callback){
		tableService.listTables(currentPage+1, $scope.pagination.maxRows, function(response){
			$scope.openTables = response.openTables.array;
			$scope.pagination.recordCount = response.openTables.pagination.totalRecordCount;
			$scope.pagination.currentPage = currentPage;
			$scope.loading = false;
			if(callback) (callback)(response);
		});
	}

	$scope.deleteTable = function(tableID){
		$scope.loading = true;
		tableService.deleteTable(tableID, function(){
			$scope.listOpenTables(0);
		});
	}

	$scope.goToPage = function(page){
		$scope.loading = true;
		$scope.listOpenTables(page, function(){
			$scope.loading = false;
			globalFunctions.scrollTo("alert");
		});
	}
	
	if($scope.posProfile.hasTableSeating) $scope.listOpenTables(0);
});
