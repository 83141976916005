pos.factory("rollbarService", function(globalData, Rollbar){
	
	var setUserInfo = function(){
		try {
			Rollbar.configure({
				payload: {
					person: {
						id: localStorage.websiteID,
						username: globalData.posInfo.websiteName
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	};
	
	return{
		setUserInfo:setUserInfo
	};
});