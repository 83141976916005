pos.factory("coreService", function($rootScope, $interval, $sessionStorage){

	var startLogoutTimeout = function(){
		var logoutTime = 21600000; //2 Hours @MPINEDA_TODO RESTORE to 2HS

		$interval(function(){
			var currentTime = new Date().getTime();
			if((currentTime - $rootScope.lastActionTime) >= logoutTime) {
				$sessionStorage.$reset();
				location.href="/index.cfm";
			}
		},5000);
	}

	return{
		startLogoutTimeout:	startLogoutTimeout
	};
});
