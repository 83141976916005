var cacheKeys = [
	{name: 'table', default: {}, clearOnReset: false},
	{name: 'coupons', default: [], clearOnReset: false},
	{name: 'order', default: {}, clearOnReset: false},
	{name: 'customer', default: {}, clearOnReset: false},
	{name: 'posProfile', default: {}, clearOnReset: false},
	{name: 'posDeviceProfile', default: {}, clearOnReset: false},
	{name: 'refund', default: {}, clearOnReset: false},
	{name: 'paymentTypes', default: [], clearOnReset: false},
	{name: 'cartItems', default: [], clearOnReset: false},
	{name: 'refundItems', default: [], clearOnReset: false},
	{name: 'productsByCategory', default: {}, clearOnReset: true},
	{name: 'posCategories', default: [], clearOnReset: true}
];

pos.factory("session", function($sessionStorage){
	cacheKeys.forEach(function(key){
		$sessionStorage[key.name] = $sessionStorage[key.name] || key.default;
	})
	
	return $sessionStorage;
});

pos.factory("cacheService", function(session){

	var save = function(cacheKey, data){
		session[cacheKey] = data;
	};
	
	var fetch = function(cacheKey){
		return session[cacheKey];
	};
	
	var reset = function(){
		cacheKeys.forEach(function(key){
			if(key.clearOnReset){
				session[key.name] = key.default;
			}
		})
	}
	
	return{
		save:	save,
		fetch: 	fetch,
		reset:	reset
	};
	
});