
pos.store.factory("dashboardService", function($http, session, globalData, globalFunctions){

	var editCustomer = function(firstName, lastName, phone, smsNumber, company, birthDay, birthMonth, birthYear, email, secondaryEmails, address, address2, city, countryCode, stateCode, zipCode, username, password, sourceCode, isNonTaxable, FBProfileID, twitterID, salesAssociateID, customerID, callback){
		var customer = globalFunctions.isRefund() ? session.refund.refundHeader.billCustomer : session.customer;
		var secondaryEmailArray = JSON.stringify(_separateSecondaryEmails(secondaryEmails));
		
		globalFunctions.v65http("/customer/index.cfm?method=customer.editCustomer",{firstName:firstName,
		lastName:lastName,
		phone:phone,
		smsNumber:smsNumber,
		company:company,
		birthDay:birthDay,
		birthMonth:birthMonth,
		birthYear:birthYear,
		email:email,
		secondaryEmails:secondaryEmailArray,
		address:address,
		address2:address2,
		city:city,
		countryCode:countryCode,
		stateCode:stateCode,
		zipCode:zipCode,
		username:username,
		password:password,
		sourceCode:sourceCode,
		isNonTaxable:isNonTaxable,
		FBProfileID:FBProfileID,
		twitterID:twitterID,
		salesAssociateID:salesAssociateID,
		customerID:customerID},function(response){
			angular.copy(response.customer, customer);
			(callback)(response);
		}, "POST", 15000);
	}

	var viewCustomer = function(customerID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=customer.viewCustomer", {customerID:customerID}, function(response){
			(callback)(response)
		}, null, 15000);
	}
	
	var _separateSecondaryEmails = function(secondaryEmails){
		var emailArray = [];
		for(var secondaryEmail in secondaryEmails){
			if((secondaryEmails[secondaryEmail].value).length > 0){
				emailArray.push(secondaryEmails[secondaryEmail])
			}
		}
		
		return emailArray;
	}

	return{
		editCustomer:	editCustomer,
		viewCustomer:	viewCustomer
	};
});