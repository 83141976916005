pos.factory("cartArrayService", function($http, $rootScope, session, globalData, globalFunctions, _){

	var debounceAddToCartArray = _.debounce(function(callback){
		// Set session variable to update based on if refund or not
		var cartItems = globalFunctions.isRefund() ? session.refundItems : session.cartItems;
		
		// clone session array
		var tempCartItems = cartItems.slice();

		var productSKUs = [];
		for (var i=0; i < tempCartItems.length; i++) {
			var productSKU = $.grep(productSKUs, function (e) { return e.sku == tempCartItems[i].sku; });
			if(productSKU && productSKU.length == 1) {
				productSKU[0].quantity++;
			} else {
				productSKUs.push({"sku":tempCartItems[i].sku,"quantity":1,"price":tempCartItems[i].price});
			}
		}

		angular.copy(cartItems.slice(tempCartItems.length, cartItems.length), cartItems);

		if (globalFunctions.isRefund()) {
			globalFunctions.v65http("/orders/index.cfm?method=refund.addExchangeItemToRefund", {productSKUs:JSON.stringify(productSKUs),posProfile:JSON.stringify(session.posProfile),refundOrderID:session.refund.refundHeader.refundOrderID},function(response){
				angular.copy(response.refund, session.refund);
				(callback)(response);
			}, "POST", null, 0);
		} else {
			globalFunctions.v65http("/store/index.cfm?method=cart.addToCart", {productSKUs:JSON.stringify(productSKUs),posProfile:JSON.stringify(session.posProfile),orderID:session.order.orderHeader.orderID},function(response){
				angular.copy(response.order, session.order);
				(callback)(response);
			}, "POST", null, 0);
		}

		// Note: If leading and trailing options are true,
		// func is invoked on the trailing edge of the timeout
		// only if the debounced function is invoked more than once during the wait timeout.
	}, 500, {'trailing': true});

	return{
		debounceAddToCartArray:	debounceAddToCartArray
	};
});
