pos.customerManager.controller("tasks", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.tasks.listTasks";
	$scope.viewRoute = "customerManager.viewCustomer.tasks.viewTask";
	$scope.addRoute = "customerManager.viewCustomer.tasks.addTask";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("listTasks", function($scope, $rootScope, globalData, globalFunctions, taskService){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Tasks";
	$scope.tasks = {};

	//Functions
	$scope.listTasks = function(page){
		$scope.$emit('formLoading', true);
		taskService.listTasks(page, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.tasks = response.tasks;
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		globalFunctions.scrollTo('top');
		$scope.listTasks(page);
	}

	//Listeners

	//Logic
	$scope.listTasks(1);
});


pos.customerManager.controller("viewTask", function($scope, $rootScope, globalData, globalFunctions, taskService, $stateParams, $controller, $state){
	//Variables
	$scope.$emit('formLoading', true);
	$rootScope.currentManager.subSectionTitle = "View Task";
	$scope.task = {};
	$scope.users = [];

	//Functions
	$scope.deleteTask = function(taskID){
		$scope.$emit('formLoading', true);
		taskService.deleteTask(taskID, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		})
	}

	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		taskService.editTask($stateParams.taskID,$scope.formData.assignToID, $scope.formData.comments, $scope.formData.dueDay, $scope.formData.dueMonth, $scope.formData.dueYear, $scope.formData.emailNotificationTo, $scope.formData.isTaskComplete, $scope.formData.sendEmailNotification, $scope.formData.subject, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}

	//Listeners

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController

	taskService.listUsers(function(response){
		$scope.users = response.users;
	});

	taskService.viewTask($stateParams.taskID, function(response){
		$scope.$emit('formLoading', false);
		$scope.task = response.task;
		$scope.formData = response.task;
	});
});

pos.customerManager.controller("addTask", function($scope, $rootScope, globalData, globalFunctions, taskService, $controller, $state){
	//Variables
	$rootScope.currentManager.subSectionTitle = "Add Task";
	$scope.users = [];
	$scope.$emit('formLoading', false);

	//Functions
	$scope.formAction = function(){
		$scope.$emit('formLoading', true);
		taskService.addTask($scope.formData.assignToID, $scope.formData.comments, $scope.formData.dueDay, $scope.formData.dueMonth, $scope.formData.dueYear, $scope.formData.emailNotificationTo, $scope.formData.isTaskComplete, $scope.formData.sendEmailNotification, $scope.formData.subject, function(response){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}
	//listeners

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController
	taskService.listUsers(function(response){
		$scope.users = response.users;
	});
});