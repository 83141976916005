pos.config(function($stateProvider, $urlRouterProvider, $httpProvider){
	
	$httpProvider.interceptors.push(['globalData', function(globalData) {
			return {
				'request': function(config) {
						if (globalData.posInfo.posPaymentFix) {
							var re = /.*(ht|cf){1}(m|c){1}(l)?.*/g;
							if (re.exec(config.url) !== null) {
								config.cache = false;
								var newURL = null;
								if (config.url.indexOf('?') !== -1) {
									var currentURL = config.url.split('?');
									newURL = currentURL[0] + '?nts=' + Date.now() + '&' + currentURL[1];
								} else {
									newURL = config.url + '?nts=' + Date.now();
								}
								config.url = newURL;
							}
						} else {
							// For big HTML changes, update this by running `Date.now()` in your console and adding value here
							var timestamp = 1594955589888; // Thu Jul 16 2020 20:13:28 GMT-0700 (Pacific Daylight Time)
							if (config.url.indexOf('v2/views') !== -1) {
								// Use a JSON parser or library to determin the ?
								// Look into cache busting for angularJS
								var urlAttributeSymbol = (config.url.indexOf('?') !== -1) ? '&' : '?';
								config.url = config.url + urlAttributeSymbol + 'ts=' + timestamp;
							}
						}
						return config;
					},
			};
	}]);

	// Global Default
	$urlRouterProvider.otherwise("/login"); 

	//Store Manager Defaults
	$urlRouterProvider.when('/storeManager', '/storeManager/order/listOrders');
	$urlRouterProvider.when('/storeManager/order', '/storeManager/order/listOrders');
	
	//Customer Area Defaults
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID', '/customerManager/viewCustomer/:customerID/dashboard/viewDashboard');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/dashboard', '/customerManager/viewCustomer/:customerID/dashboard/viewDashboard');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/notes', '/customerManager/viewCustomer/:customerID/notes/listNotes');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/tasks', '/customerManager/viewCustomer/:customerID/tasks/listTasks');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/emails', '/customerManager/viewCustomer/:customerID/emails/listEmails');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/contactTypes', '/customerManager/viewCustomer/:customerID/contactTypes/listContactTypes');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/addressBook', '/customerManager/viewCustomer/:customerID/addressBook/listAddressBook');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/creditCards', '/customerManager/viewCustomer/:customerID/creditCards/listCreditCards');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/products', '/customerManager/viewCustomer/:customerID/products/listProducts');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/orders', '/customerManager/viewCustomer/:customerID/orders/listOrders');
	$urlRouterProvider.when('/customerManager/viewCustomer/:customerID/clubMemberships', '/customerManager/viewCustomer/:customerID/clubMemberships/listClubMemberships');


	$stateProvider
		.state('login', {
			url: "/login",
			templateUrl: "/v2/views/login.htm",
			controller: "login"
		})
		.state('login_cf', {
			url: "/login/:cfAttrs",
			templateUrl: "/v2/views/login.htm",
			controller: "login"
		})
		.state('store', {
			url: "/store",
			templateUrl: "/v2/views/store.htm",
			controller: "store"
		})


		// Customer Manager
		.state('customerManager', {
			url: "/customerManager",
			templateUrl: "/v2/views/manager.htm",
			controller: "customerManager"
		})

			.state('customerManager.viewCustomer', {
				url: "/viewCustomer/:customerID",
				templateUrl: "/v2/views/customer/viewCustomer.htm",
				controller: "viewCustomer"
			})

				//Notes
				.state('customerManager.viewCustomer.notes', {
					url: "/notes",
					templateUrl: "/v2/views/customer/notes/notes.htm",
					controller: "notes"
				})

					.state('customerManager.viewCustomer.notes.listNotes', {
						url: "/listNotes",
						templateUrl: "/v2/views/customer/notes/listNotes.htm",
						controller: "listNotes"
					})

					.state('customerManager.viewCustomer.notes.viewNote', {
						url: "/viewNote/:noteID",
						templateUrl: "/v2/views/customer/notes/noteForm.htm",
						controller: "viewNote"
					})

					.state('customerManager.viewCustomer.notes.addNote', {
						url: "/addNote",
						templateUrl: "/v2/views/customer/notes/noteForm.htm",
						controller: "addNote"
					})


				//Tasks
				.state('customerManager.viewCustomer.tasks', {
					url: "/tasks",
					templateUrl: "/v2/views/customer/tasks/tasks.htm",
					controller: "tasks"
				})

					.state('customerManager.viewCustomer.tasks.listTasks', {
						url: "/listTasks",
						templateUrl: "/v2/views/customer/tasks/listTasks.htm",
						controller: "listTasks"
					})

					.state('customerManager.viewCustomer.tasks.viewTask', {
						url: "/viewTask/:taskID",
						templateUrl: "/v2/views/customer/tasks/taskForm.htm",
						controller: "viewTask"
					})

					.state('customerManager.viewCustomer.tasks.addTask', {
						url: "/addTask",
						templateUrl: "/v2/views/customer/tasks/taskForm.htm",
						controller: "addTask"
					})

				//Products
				.state('customerManager.viewCustomer.products', {
					url: "/products",
					templateUrl: "/v2/views/customer/products/products.htm",
					controller: "products"
				})

					.state('customerManager.viewCustomer.products.listProducts', {
						url: "/listProducts",
						templateUrl: "/v2/views/customer/products/listProducts.htm",
						controller: "listProducts"
					})


				//Contact Types
				.state('customerManager.viewCustomer.contactTypes', {
					url: "/contactTypes",
					templateUrl: "/v2/views/customer/contactTypes/contactTypes.htm",
					controller: "contactTypes"
				})

					.state('customerManager.viewCustomer.contactTypes.listContactTypes', {
						url: "/listContactTypes",
						templateUrl: "/v2/views/customer/contactTypes/listContactTypes.htm",
						controller: "listContactTypes"
					})


				//Emails
				.state('customerManager.viewCustomer.emails', {
					url: "/emails",
					templateUrl: "/v2/views/customer/emails/emails.htm",
					controller: "emails"
				})

					.state('customerManager.viewCustomer.emails.listEmails', {
						url: "/listEmails",
						templateUrl: "/v2/views/customer/emails/listEmails.htm",
						controller: "listEmails"
					})

					.state('customerManager.viewCustomer.emails.viewEmail', {
						url: "/viewEmail/:emailDocumentSentID",
						templateUrl: "/v2/views/customer/emails/viewEmail.htm",
						controller: "viewEmail"
					})


				//Credit Cards
				.state('customerManager.viewCustomer.creditCards', {
					url: "/creditCards",
					templateUrl: "/v2/views/customer/creditCards/creditCards.htm",
					controller: "creditCards"
				})

					.state('customerManager.viewCustomer.creditCards.listCreditCards', {
						url: "/listCreditCards",
						templateUrl: "/v2/views/customer/creditCards/listCreditCards.htm",
						controller: "listCreditCards"
					})

					.state('customerManager.viewCustomer.creditCards.viewCreditCard', {
						url: "/viewCreditCard/:customerCreditCardID",
						templateUrl: "/v2/views/customer/creditCards/creditCardForm.htm",
						controller: "viewCreditCard"
					})

					.state('customerManager.viewCustomer.creditCards.addCreditCard', {
						url: "/addCreditCard",
						templateUrl: "/v2/views/customer/creditCards/creditCardForm.htm",
						controller: "addCreditCard"
					})



				//Address Book
				.state('customerManager.viewCustomer.addressBook', {
					url: "/addressBook",
					templateUrl: "/v2/views/customer/addressBook/addressBook.htm",
					controller: "addressBook"
				})

					.state('customerManager.viewCustomer.addressBook.listAddressBook', {
						url: "/listAddressBook",
						templateUrl: "/v2/views/customer/addressBook/listAddressBook.htm",
						controller: "listAddressBook"
					})

					.state('customerManager.viewCustomer.addressBook.viewAddressBookEntry', {
						url: "/viewAddressBookEntry/:shipCustomerID",
						templateUrl: "/v2/views/customer/addressBook/addressBookEntryForm.htm",
						controller: "viewAddressBookEntry"
					})

					.state('customerManager.viewCustomer.addressBook.addAddressBookEntry', {
						url: "/addAddressBookEntry",
						templateUrl: "/v2/views/customer/addressBook/addressBookEntryForm.htm",
						controller: "addAddressBookEntry"
					})



				//Clubs
				.state('customerManager.viewCustomer.clubMemberships', {
					url: "/clubMemberships",
					templateUrl: "/v2/views/customer/clubMemberships/clubMemberships.htm",
					controller: "clubMemberships"
				})

					.state('customerManager.viewCustomer.clubMemberships.listClubMemberships', {
						url: "/listClubMemberships",
						templateUrl: "/v2/views/customer/clubMemberships/listClubMemberships.htm",
						controller: "listClubMemberships"
					})

					.state('customerManager.viewCustomer.clubMemberships.viewClubMembership', {
						url: "/viewClubMembership/:clubMembershipID",
						templateUrl: "/v2/views/customer/clubMemberships/clubMembershipForm.htm",
						controller: "viewClubMembership"
					})

					.state('customerManager.viewCustomer.clubMemberships.chooseClub', {
						url: "/chooseClub",
						templateUrl: "/v2/views/customer/clubMemberships/chooseClub.htm",
						controller: "chooseClub"
					})

					.state('customerManager.viewCustomer.clubMemberships.addClubMembership', {
						url: "/addClubMembership/:clubID",
						templateUrl: "/v2/views/customer/clubMemberships/addClubMembership.htm",
						controller: "addClubMembership"
					})


				//Dashboard
				.state('customerManager.viewCustomer.dashboard', {
					url: "/dashboard",
					templateUrl: "/v2/views/customer/dashboard/dashboard.htm",
					controller: "dashboard"
				})

					.state('customerManager.viewCustomer.dashboard.viewDashboard', {
						url: "/viewDashboard",
						templateUrl: "/v2/views/customer/dashboard/viewDashboard.htm",
						controller: "viewDashboard"
					})


				//Orders
				.state('customerManager.viewCustomer.orders', {
					url: "/orders",
					templateUrl: "/v2/views/customer/orders/orders.htm",
					controller: "orders"
				})

					.state('customerManager.viewCustomer.orders.listOrders', {
						url: "/listOrders/:filter",
						templateUrl: "/v2/views/customer/orders/listOrders.htm",
						controller: "listCustomerOrders"
					})

		// Store Manager
		.state('storeManager', {
			url: "/storeManager",
			templateUrl: "/v2/views/manager.htm",
			controller: "storeManager"
		})
			//Default for Orders - Not sure if need
			.state('storeManager.order', {
				url: "/order",
				templateUrl: "/v2/views/partials/order.htm",
				controller : "order"
			})
				//Direct List Orders
				.state('storeManager.order.listOrders', {
					url: "/listOrders",
					templateUrl: "/v2/views/partials/listOrders.htm",
					controller : "listOrders"
				})

				.state('storeManager.order.viewOrder', {
					url: "/viewOrder/:orderID",
					templateUrl: "/v2/views/partials/viewOrder.htm",
					controller : "viewOrder"
				})

				.state('storeManager.order.refundOrder', {
					url: "/refundOrder/:orderID",
					templateUrl: "/v2/views/partials/refundOrder.htm",
					controller : "refundOrder"
				})
		;
});