
pos.store.factory("taskService", function($http, session, globalData, globalFunctions){

	var addTask = function(assignToID, comments, dueDay, dueMonth, dueYear, emailNotificationTo, isTaskComplete, sendEmailNotification, subject, callback){

		globalFunctions.v65http("/customer/index.cfm?method=tasks.addTask",{assignToID:assignToID,
		comments:comments,
		dueDay:dueDay,
		dueMonth:dueMonth,
		dueYear:dueYear,
		emailNotificationTo:emailNotificationTo,
		isTaskComplete:isTaskComplete,
		sendEmailNotification:sendEmailNotification,
		subject:subject,
		customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, null, 0);
	}

	var deleteTask = function(taskID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=tasks.deleteTask",{taskID:taskID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}


	var editTask = function(taskID,assignToID, comments, dueDay, dueMonth, dueYear, emailNotificationTo, isTaskComplete, sendEmailNotification, subject, callback){
		globalFunctions.v65http("/customer/index.cfm?method=tasks.editTask",{
		taskID:taskID,
		assignToID:assignToID,
		comments:comments,
		dueDay:dueDay,
		dueMonth:dueMonth,
		dueYear:dueYear,
		emailNotificationTo:emailNotificationTo,
		isTaskComplete:isTaskComplete,
		sendEmailNotification:sendEmailNotification,
		subject:subject,
		customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listTasks = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=tasks.listTasks", {page:page, maxRows:maxRows,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listUsers = function(callback){
		globalFunctions.v65http("/customer/index.cfm?method=tasks.listUsers", null, function(response){
			(callback)(response);
		}, null, 15000);
	}


	var viewTask = function(taskID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=tasks.viewTask",{taskID:taskID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	return{
		addTask:	addTask,
		deleteTask:	deleteTask,
		editTask:	editTask,
		listTasks:	listTasks,
		listUsers:	listUsers,
		viewTask:	viewTask
	};
});