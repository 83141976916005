pos.factory("loginService", function($http, session, globalData, $localStorage, globalFunctions, rollbarService){

	var aboutPOS = function(callback){
		globalFunctions.v65http("/index.cfm?method=login.aboutPOS",{posProfileID:session.posProfile.posProfileID,localStorage:$localStorage},function(response){
			angular.copy(response.posInfo, globalData.posInfo);
			angular.copy(response.posProfile, session.posProfile);
			globalData.posInfo.loaded = true;
			rollbarService.setUserInfo();
			if(callback) (callback)(response);
		}, null, 60000);
	};

	var loginCheck = function(formData, callback){
		globalFunctions.v65http("/index.cfm?method=login.loginCheck", formData, function(response){
			(callback)(response);
		}, "POST", null, 0);
	};
	
	var pinLoginCheck = function(formData, callback){
		globalFunctions.v65http("/index.cfm?method=login.pinLoginCheck", formData, function(response){
			(callback)(response);
		}, null, null, 0);
	};
	
	var savePin = function(websiteID, websiteContactID, pinCode, confirmPinCode, callback){
		globalFunctions.v65http("/index.cfm?method=login.savePin", {websiteID:websiteID, websiteContactID:websiteContactID, pinCode: pinCode, confirmPinCode: confirmPinCode}, function(response){
			(callback)(response);
		}, null, null, 0);
	};
	
	var pinsEnabledForWebsite = function(websiteID, callback){
		globalFunctions.v65http("/index.cfm?method=login.pinsEnabledForWebsite", {websiteID:websiteID}, function(response){
			(callback)(response);
		}, null, null, 0);
	};
	
	var fetchPinForWebsiteContact = function(websiteID, websiteContactID, callback){
		globalFunctions.v65http("/index.cfm?method=login.fetchPinForWebsiteContact", {websiteID:websiteID, websiteContactID:websiteContactID}, function(response){
			(callback)(response);
		}, null, 15000);
	};

	var listWebsites = function(callback){
		globalFunctions.v65http("/index.cfm?method=login.listWebsites", null, function(response){
			(callback)(response);
		}, null, 15000);
	};

	var loadWebsite = function(websiteID, callback){
		globalFunctions.v65http("/index.cfm?method=login.loadWebsite", {websiteID:websiteID}, function(response){
			angular.copy(response.posProfile, session.posProfile);
			angular.copy(response.posDeviceProfile, session.posDeviceProfile);
			(callback)(response);
		}, null, 60000);
	};

	var logout = function(callback){
		globalFunctions.v65http("/index.cfm?method=login.logout", {redirectURL:location.origin}, function(response){
			(callback)(response);
		}, null, null, 0);
	};
	
	var encryptLoginData = function(loginData, callback){
		globalFunctions.v65http("/index.cfm?method=login.encryptLoginData", {loginData:loginData}, function(response){
			(callback)(response);
		}, "POST", null, 0);
	};
	
	var decryptLoginData = function(loginData, callback){
		globalFunctions.v65http("/index.cfm?method=login.decryptLoginData", {loginData:loginData}, function(response){
			(callback)(response);
		}, null, null, 0);
	};

	return{
		aboutPOS:aboutPOS,
		loginCheck:loginCheck,
		pinLoginCheck:pinLoginCheck,
		savePin:savePin,
		pinsEnabledForWebsite:pinsEnabledForWebsite,
		fetchPinForWebsiteContact:fetchPinForWebsiteContact,
		listWebsites:listWebsites,
		loadWebsite:loadWebsite,
		logout:logout,
		encryptLoginData:encryptLoginData,
		decryptLoginData:decryptLoginData
	};
});
