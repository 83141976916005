
pos.store.factory("emailService", function($http, session, globalData, globalFunctions){

	var listEmails = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=emails.listEmails",{page:page, maxRows:maxRows,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var resendEmail = function(emailDocumentSentID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=emails.resendEmail",{emailDocumentSentID:emailDocumentSentID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, null, 0);	
	}

	var viewEmail = function(emailDocumentSentID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=emails.viewEmail",{emailDocumentSentID:emailDocumentSentID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	return{
		listEmails:		listEmails,
		resendEmail:	resendEmail,
		viewEmail:		viewEmail
	};
});