pos.factory("emvTerminalService", function(session, globalFunctions, $localStorage){
	
	var addEMVTerminal = function(nickName,serialNumber,isBleTerminal, callback){
		globalFunctions.v65http(
			"/index.cfm?method=emvTerminals.addEMVTerminal",
			{
				nickName:nickName,
				serialNumber:serialNumber,
				posProfile:session.posProfile,
				posDeviceProfile:session.posDeviceProfile,
				isBleTerminal: isBleTerminal
			},
			function(response){
				if(callback) (callback)(response);
			},
			null,
			null,
			0
		);
	};
	
	var editEMVTerminal = function(terminalID,nickName, isBleTerminal, enableTips,enableReceipts,enableCustomTips,customTip1,customTip2,customTip3,callback){
		globalFunctions.v65http(
			"/index.cfm?method=emvTerminals.editEMVTerminal",
			{
				terminalID:terminalID,
				nickName:nickName,
				isBleTerminal:isBleTerminal,
				enableReceipts:enableReceipts || 0,
				enableTips:enableTips || 0,
				enableCustomTips:enableCustomTips || 0,
				customTip1:customTip1 || 0,
				customTip2:customTip2 || 0,
				customTip3:customTip3 || 0,
				posProfile:session.posProfile,
				posDeviceProfile:session.posDeviceProfile
			},
			function(response){
				(callback)(response);
			},
			null,
			15000
		);
	}
	
	var deleteEMVTerminal = function(terminalID, callback){
		globalFunctions.v65http("/index.cfm?method=emvTerminals.deleteEMVTerminal", {terminalID:terminalID}, function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listEMVTerminals = function(callback){
		globalFunctions.v65http("/index.cfm?method=emvTerminals.listEMVTerminals",{posProfile:session.posProfile,posDeviceProfile:session.posDeviceProfile,localStorage:$localStorage},function(response){
			if(callback) (callback)(response);
		}, null, 15000);
	};

	var listMerchantAccounts = function(callback) {
		globalFunctions.v65http("index.cfm?method=emvTerminals.listMerchants", {posProfile:session.posProfile,posDeviceProfile:session.posDeviceProfile,localStorage:$localStorage}, function (response){
			if (callback) (callback)(response);
		});
	};

	var validateEMVTerminal = function(callback){
		globalFunctions.v65http("/index.cfm?method=emvTerminals.validateEMVTerminal",{posProfile:session.posProfile,posDeviceProfile:session.posDeviceProfile,localStorage:$localStorage}, function(response) {
			if (callback) (callback)(response);
		}, null, 15000);
	};

	var sendTransaction = function(
		amount,
		terminalID,
		locationID,
		productTransactionID,
		orderID,
		terminalNickname,
		transactionType,
		orderNumber,
		previousOrderNumber,
		orderType,
		isSplitPayment,
		splitCount,
		callback
	) {
		var timestamp = Date.now();
		// var requestTimeout = 600000; // Keep request open for 600 seconds: https://docs.zeamster.com/developers/api/endpoints/routertransactions
		var requestTimeout = 300000; // Keep request open for 600 seconds: https://docs.zeamster.com/developers/api/endpoints/routertransactions
		var data = {
			amount: amount,
			terminalID: terminalID,
			locationID: locationID,
			productTransactionID: productTransactionID,
			terminalNickname: terminalNickname,
			transactionType: transactionType,
			orderID: orderID,
			orderNumber: orderNumber,
			previousOrderNumber: previousOrderNumber,
			orderType: orderType,
			ts:timestamp,
			isSplitPayment: isSplitPayment,
			splitCount: splitCount
		};
		globalFunctions.v65http("/index.cfm?method=emvTerminals.sendTransactionToTerminal", data, function(response){
			(callback)(response);
		}, null, requestTimeout, 0, null, function(){
			Rollbar.error("EMV Terminal Service - error in sendTransaction");
		});
	};

	return{
		addEMVTerminal: 		addEMVTerminal,
		editEMVTerminal: 		editEMVTerminal,
		deleteEMVTerminal: 		deleteEMVTerminal,
		listEMVTerminals: 		listEMVTerminals,
		validateEMVTerminal:	validateEMVTerminal,
		sendTransaction: 		sendTransaction
	};
});
