pos.controller("deviceSettings", function($timeout, $scope, $rootScope, $localStorage, session, globalData, globalFunctions, settingsService, paymentTerminalService, emvTerminalService, $parse, loginService){
	//Variables
	$scope.modalTitle = "Device Settings";
	$scope.modalSubmitTitle = "Save";
	$scope.posInfo = globalData.posInfo;
	$scope.printers = globalData.printers;
	$scope.showDepositAccount = globalData.posInfo.hasEMV || globalData.posInfo.paymentGateway == 'Zeamster';
	$scope.viewSettings;
	$scope.paymentTerminals = [];
	$scope.emvTerminals = [];
	$scope.storage = $localStorage;
	$scope.session = session;
	$scope.modalData;
	$scope.paymentTerminal = null;
	$scope.$emit('modalLoading', true);

	//Logic
	requestReceiptPrinters();

	settingsService.viewSettings($scope.printers, function(response){
		$scope.viewSettings = response;
		$scope.formData = {};
		$scope.formData.defaultPrinterProfileID = "";
		$scope.formData.defaultCashDrawerProfileID = "";
		$scope.formData.productTransaction = "";

		//Selects POS Profile
		angular.forEach( $scope.viewSettings.posProfiles, function(posProfileItem, key){
			if(posProfileItem.isPrimary == 1) {
				$scope.posProfile = posProfileItem;
				$scope.formData.posProfileID = $scope.posProfile.posProfileID;
				$scope.formData.productTransactionList = $scope.posProfile.productTransactionData;
				if ($scope.formData.productTransaction == "" && typeof session.posProfile.zeamsterProductTransactionID !== 'undefined' && $scope.session.posProfile.zeamsterProductTransactionID != '') {
					$scope.formData.productTransaction = globalFunctions.findInObject($scope.formData.productTransactionList, 'id', $scope.session.posProfile.zeamsterProductTransactionID)[0];
				}
				return;
			}
		});

		// //Selects POS Profile
		// angular.forEach( $scope.viewSettings.posProfiles, function(data, key){
		// 	if(data.isPrimary == 1) {
		// 		$scope.formData.posProfileID = $scope.viewSettings.posProfiles[key].posProfileID;
		// 		$scope.posProfile = $scope.viewSettings.posProfiles[key];
		// 		return;
		// 	}
		// });

		//Selects Printer
		angular.forEach( $scope.viewSettings.printerArray, function(data, key){
			if(data.isPrimary == 1) {
				$scope.formData.defaultPrinterProfileID = $scope.viewSettings.printerArray[key].posPrinterProfileID;
				$scope.printer = $scope.viewSettings.printerArray[key];
				return;
			}
		});

		//Selects Cash Drawer
		angular.forEach( $scope.viewSettings.cashDrawerArray, function(data, key){
			if(data.isPrimary == 1) {
				$scope.formData.defaultCashDrawerProfileID = $scope.viewSettings.cashDrawerArray[key].posPrinterProfileID;
				$scope.cashDrawer = $scope.viewSettings.cashDrawerArray[key];
				return;
			}
		});

		if ($scope.posInfo.hasEMV) {
			$scope.listEMVTerminals();
		} else if ($scope.posInfo.hasOldEMV) {
			$scope.listPaymentTerminals();
		} else {
			$scope.$emit('modalLoading', false);
		}
	});

	//Functions
	$scope.goToAdminPanel = function(posProfileID){
		globalFunctions.v65http("/index.cfm?method=global.siteadmin", null, function(response){
			openSafari(response.siteadminLink.trim()+"/2014/settings/index.cfm?method=posProfiles.view&posProfileID="+posProfileID);
		});
	};

	$scope.hasMultiMerchant = function() {
		console.log(typeof session.posProfile.zeamsterMultiMerchant !== 'undefined' && session.posProfile.zeamsterMultiMerchant);
		return typeof session.posProfile.zeamsterMultiMerchant !== 'undefined' && session.posProfile.zeamsterMultiMerchant;
	}

	$scope.listEMVTerminals = function(){
		emvTerminalService.listEMVTerminals(function(response){
			$scope.emvTerminals = response.emvTerminals;
			// If we just added a terminal on a previous modal then we want to have that one selected
			if(typeof ($scope.modalData && $scope.modalData.terminalID) !== 'undefined'){
				$scope.formData.emvTerminal = globalFunctions.findInObject($scope.emvTerminals,'terminalID', $scope.modalData.terminalID)[0];
			// Otherwise select the terminal from storage
			}else if(typeof $scope.storage.emvTerminal !== 'undefined'){
				$scope.formData.emvTerminal = globalFunctions.findInObject($scope.emvTerminals,'terminalID', $scope.storage.emvTerminal.terminalID)[0];
			}

			$scope.$emit('modalLoading', false);
		});
	}

	$scope.changePosProfile = function(posProfile) {
		if (posProfile == null) return;
		$scope.formData.posProfileID = posProfile.posProfileID;
		angular.forEach($scope.viewSettings.posProfiles, function(posProfileItem, key) {
			if (posProfileItem.posProfileID == $scope.formData.posProfileID) {
				$scope.formData.productTransactionList = posProfileItem.productTransactionData;
			}
		});
	}

	$scope.listPaymentTerminals = function(){
		paymentTerminalService.listPaymentTerminals(function(response){
			$scope.paymentTerminals = response.paymentTerminals;
			// If we just added a terminal on a previous modal then we want to have that one selected
			if(typeof ($scope.modalData && $scope.modalData.paymentTerminalKey) !== 'undefined'){
				$scope.formData.paymentTerminal = globalFunctions.findInObject($scope.paymentTerminals,'paymentTerminalKey', $scope.modalData.paymentTerminalKey)[0];
			// Otherwise select the terminal from storage
			}else if(typeof $scope.storage.paymentTerminal !== 'undefined'){
				$scope.formData.paymentTerminal = globalFunctions.findInObject($scope.paymentTerminals,'paymentTerminalID', $scope.storage.paymentTerminal.paymentTerminalID)[0];
			}
			$scope.$emit('modalLoading', false);
		});
	}

	$scope.changePrinterID = function(printer){
		if(printer){
			var printer = angular.fromJson(printer);
			$scope.formData.defaultPrinterProfileID = printer.posPrinterProfileID;
		}else{
			$scope.formData.defaultPrinterProfileID = "";
		}
	}

	$scope.changeCashDrawerID = function(cashDrawer){
		if(cashDrawer){
			var cashDrawer = angular.fromJson(cashDrawer);
			$scope.formData.defaultCashDrawerProfileID = cashDrawer.posPrinterProfileID;
		}else{
			$scope.formData.defaultCashDrawerProfileID = "";
		}
	}

	$scope.editPaymentTerminal = function(paymentTerminal){
		var paymentTerminal = angular.fromJson(paymentTerminal)
		$scope.openModal("v2/views/partials/modals/editPaymentTerminal", paymentTerminal)
	}

	$scope.editEMVTerminal = function(emvTerminal){
		var emvTerminal = angular.fromJson(emvTerminal)
		$scope.openModal("v2/views/partials/modals/editEMVTerminal", emvTerminal)
	}

	$scope.editPrinter = function(printer){
		var printer = angular.fromJson(printer)
		$scope.openModal("v2/views/partials/modals/editPrinter", printer)
	}

	$scope.modalAction = function(){
		var emvConfirmationMessage = "EMV will not be available until you select/create a new terminal and select a deposit account. Do you wish to continue without EMV enabled?";
		if($scope.posInfo.hasEMV && $scope.posInfo.prodTransByName) {
			var emvConfirmationMessage = "EMV will not be available until you select/create a new terminal. Do you wish to continue without EMV enabled?";
		}
		var continueWithoutEMV = true;
		if ($scope.posInfo.hasEMV && !$scope.formData.emvTerminal) {
			continueWithoutEMV = confirm(emvConfirmationMessage);
		}
		if (continueWithoutEMV) {
			$scope.modalActionDefaults($scope.formData);
			settingsService.editSettings($scope.formData, function(response){
				if($scope.formData.paymentTerminal){
					$scope.storage.paymentTerminal = $scope.formData.paymentTerminal;
				}else{
					$scope.storage.paymentTerminal = {};
				}
				if($scope.formData.emvTerminal){
					$scope.storage.emvTerminal = $scope.formData.emvTerminal;
				}else{
					$scope.storage.emvTerminal = {};
				}
				requestReceiptPrinters();
				loginService.aboutPOS(function(){
					$rootScope.$emit("notificationListen", true);
					$scope.closeModal();
					$scope.toggleMenu();
					$scope.reloadPOS();
				});
			});
		}
	};

	$scope.reloadList = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/deviceSettings");
	}
});


pos.controller("editPrinter", function($timeout, $scope, $rootScope, session, globalData, globalFunctions, settingsService, $parse){
	//Variables
	$scope.$emit('modalLoading', false);
	$scope.modalData;
	$scope.modalTitle = "Edit Printer";
	$scope.modalSubmitTitle = "Save";
	$scope.modalCancelTitle = "Back";
	$scope.order = session.order;


	//Logic


	//Functions
	
	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/deviceSettings");
	};


	$scope.testPrint = function(){
		globalFunctions.printReceipt(globalData.printerSettings.defaultPrinterMacAddress, $scope.order.orderHeader.orderID, "prePrintReceipt", function(){
			//Callback
		});
	};

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		settingsService.editPrinter($scope.formData.hasCashDrawer,$scope.formData.macAddress,$scope.formData.nickName,$scope.formData.posPrinterProfileID, function(response){
			$scope.openModal("v2/views/partials/modals/deviceSettings");
		});
	};

});

pos.controller("errorReport", function($timeout, $scope, $rootScope, session, globalData, globalFunctions, settingsService){
	//Variables
	$scope.$emit('modalLoading', false);
	$scope.modalData;
	$scope.modalCancelTitle = "hide";
	$scope.modalTitle = "Error Report";
	$scope.modalSubmitTitle = "Send";
	$scope.errorString = '';


	// Modal Actions

	$scope.modalCloseAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.closeModal();
	};

	$scope.isValidJSON = function(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	if (typeof $scope.modalData.errorData != 'undefined' && typeof $scope.modalData.errorData === 'string') {
		$scope.errorString = $scope.modalData.errorData;
	}

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);

		settingsService.sendSupportEmail($scope.formData.clientNotes, $scope.errorString, function(response){
			$scope.logout();
		});
	};
});

pos.controller("debug", function($timeout, $scope, $rootScope, session, globalData, globalFunctions, settingsService){
	//Variables
	$scope.$emit('modalLoading', false);
	$scope.modalData;
	$scope.modalTitle = "Troubleshooting";
	$scope.modalSubmitTitle = "Send Report";
	$scope.IPAddress = '';
	$scope.net;
	$scope.delay = 8000;
	$scope.haveTested = false;
	$scope.vin65LatencyTestWasRun = false;
	$scope.vin65DownloadTestInProgress = false;
	$scope.vin65DownloadTestComplete = false;
	$scope.speedtestDownloadTestWasRun = false;
	$scope.sendingErrorReportInProgress = false;
	$scope.loading = false;
	$scope.onDesktop = globalData.deviceType === "Desktop";
	$scope.latestLatency = '';
	$scope.latestDownload = '';
	$scope.downloadProgress = '';
	session.speedtest = {};


	// Helper methods
	$scope.instantSpeeds = [];
	$scope.getAvg = function(values) {
		return values.reduce(function(total, value) {
				return total + value;
			}) / values.length;
	}
	$scope.megabits = 1;
	$scope.round = function(value) {
		return Number.parseFloat((value * $scope.megabits).toFixed(2));
	}

	// Modal Init
	$scope.init = function() {
		settingsService.getIPAddress(function(response){
			$scope.IPAddress = response.ipAddress;
		});

		// clear the default 150 request limit set by Performance API
		if (window.performance.clearResourceTimings) window.performance.clearResourceTimings();
		$scope.net = new Network({
			endpoint: '/index.cfm?method=global.bandwidth',
			download: {
				delay: $scope.delay, // have 8 seconds before the 'end' event is triggered
				data: {
					size: 20 * 1024 * 1024, // use 20MB data initially
					multiplier: 1
				}
			}
		});

		// Setup Vin65 Latency Test
		$scope.net.latency
			.on('start', function(averageLatency, allLatencies) {
				console.log('startLatency', averageLatency, allLatencies);
			})
			.on('end', function(averageLatency, allLatencies) {
				// "allLatencies" is an array containing the five calculated latencies in ms.
				// "averageLatency" is an average of those five.
				console.log('endLatency', averageLatency, allLatencies);
				$scope.$apply(function(){
					$scope.haveTested = true;
					$scope.vin65LatencyTestWasRun = true;
					$scope.latestLatency = Math.round(averageLatency * 1000); // latency in ms
					session.speedtest.latencyStat = $scope.latestLatency;
				});
			});

		// Setup Vin65 Download Test
		$scope.net.download
			.on('start', function(dataSize) {
				$scope.haveTested = true;
				$scope.vin65DownloadTestInProgress = true;
				console.log('start', dataSize);
			})
			.on('progress', function(averageSpeed, instantSpeed) {
				$scope.instantSpeeds.push(instantSpeed);
				// console.log('progress', averageSpeed, instantSpeed);
				console.log('avg', 'instant', $scope.round($scope.getAvg($scope.instantSpeeds)), $scope.round(instantSpeed));
				$scope.$apply(function(){
					session.vin65DownloadLastRun = Date.now();
					// 1000000 is used to convert bytes to megabytes
					$scope.downloadProgress = $scope.round($scope.getAvg($scope.instantSpeeds) / 1000000);
				});
			})
			.on('restart', function(dataSize) {
				// Triggered when module didn't have time to take all the measures.
				// A new request will start with data size increased by multiplier value.
				console.log('restart', dataSize);
			})
			.on('end', function(averageSpeed, allInstantSpeeds) {
				console.log('endDownload', averageSpeed, allInstantSpeeds);
				// 1000000 is used to convert bytes to megabytes
				$scope.latestDownload = $scope.round($scope.getAvg($scope.instantSpeeds) / 1000000);
				session.speedtest.downloadStat = $scope.latestDownload;
				$scope.instantSpeeds = [];
				$scope.vin65DownloadTestComplete = true;
				$scope.vin65DownloadTestInProgress = false;
				$scope.speedtestDownloadTestWasRun = true;
			});
	}
	$scope.init();

	$scope.startLatencyTest = function() {
		// Once all the configuration is done, start the requests for this module.
		$scope.net.latency.start();
	}

	$scope.startDownloadTest = function() {

		if ((typeof session.vin65DownloadLastRun == 'undefined') || ((Date.now() - session.vin65DownloadLastRun) > 30000)) {
			// if ($scope.sendingErrorReportInProgress || (typeof session.vin65DownloadLastRun == 'undefined') || ((Date.now() - session.vin65DownloadLastRun) > 30000)) {
			globalFunctions.alertMessage("modal","","",null);
			$scope.net.download.start();
			// custom logic to return averageSpeed after 8 seconds max
			$timeout(function(){
				$scope.abortDownload();
			}, $scope.delay);
		} else {
			globalFunctions.alertMessage("modal","warning","Download test was run under 30 seconds ago, please try again in a bit.",null);
		}
	}

	$scope.abortDownload = function() {
		$scope.net.download.abort();
		if ($scope.sendingErrorReportInProgress) {
			$scope.modalTitle = "Sending...";
			globalFunctions.alertMessage("loadingNotice", null, "");
			settingsService.sendSupportEmail($scope.formData.clientNotes, '', function(response){
				$scope.openModal("v2/views/partials/modals/debugSuccess");
			});
		}
	}

	// Process order
	$scope.submitErrorReport = function() {
		console.log("submitting error report");
		if (!$scope.haveTested) {
			$scope.modalTitle = "Generating...";
			$scope.sendingErrorReportInProgress = true;
			$scope.startDownloadTest();
		}
	};

	// Modal Actions

	$scope.openLatencyMonitor = function(){
		$scope.abortDownload();
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/debugMonitor");
	}

	$scope.modalCloseAction = function(){
		// abort download test if it's running
		$scope.abortDownload();
		$scope.modalActionDefaults($scope.formData);
		// close modal instead of going back to device settings
		// $scope.openModal("v2/views/partials/modals/deviceSettings");
		$scope.closeModal();
	};


	$scope.modalAction = function(){
		$scope.abortDownload();
		$scope.modalActionDefaults($scope.formData);

		if (!$scope.haveTested) {
			$scope.submitErrorReport();
		} else {
			settingsService.sendSupportEmail($scope.formData.clientNotes, '', function(response){
				$scope.openModal("v2/views/partials/modals/debugSuccess");
			});
		}
	};
});

pos.controller("debugSuccess", function(globalFunctions, globalData, $scope){
	//Variables
	$scope.modalTitle = "Report Sent Successfully";
	$scope.modalSubmitTitle = "hide";
	$scope.modalCancelTitle = "hide";
	$scope.modalData;

	//Logic
	$scope.$emit('modalLoading', false);


	//Listeners


	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.closeModal();
	}
});

pos.controller("debugMonitor", function(globalFunctions, globalData, $scope, speedtestService){
	//Variables
	$scope.modalTitle = "Latency Monitor";
	$scope.modalSubmitTitle = "hide";
	$scope.modalCancelTitle = "Back";
	$scope.modalData;
	$scope.toggleBlip = speedtestService.toggleBlip;

	//Logic
	$scope.$emit('modalLoading', false);


	$scope.init = function(){
		speedtestService.initSpeedTestService();
	}
	$scope.init();
	//Listeners

	//Functions
	$scope.closeModal = function(){
		speedtestService.disableBlip();
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/debug");
	}
});
