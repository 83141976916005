pos.storeManager.controller("order", function($scope, $rootScope, orderService, $location){
	$rootScope.searchQuery = {};

	$scope.markOrder = function(markAs, orderIDs, callback){

		if(markAs == true){

			if($scope.deviceType == "Desktop"){
				orderService.markOrderAsPickedUp(orderIDs,null, function(response){
					(callback)(response)
				});
			}else{
				$scope.openModal("v2/views/partials/modals/markOrderSignature",{orderIDs:orderIDs,callback:callback});
				$rootScope.$on('finishedMarkOrderSignature', function(event, data){
					(data.callback)(data.response)
				});
			}

		}else{
			orderService.unmarkOrderAsPickedUp(orderIDs, function(response){
				(callback)(response)
			});
		}
	}
});

pos.storeManager.controller("markOrderSignature", function(globalFunctions, $scope, $rootScope, orderService){
	//Variables
	$scope.modalTitle = "Signature Required";
	$scope.modalSubmitTitle = "Process";
	$scope.modalCancelTitle = "Clear";
	$scope.modalSize = "big";
	$scope.signaturePad;
	$scope.modalData;
	$scope.hasSignature = false;

	if($scope.deviceType == 'iPhone'){
		rotateLandscape()
	}

	//Logic
	$scope.$emit('modalLoading', false);

	if($scope.deviceType == "iPhone"){
		$scope.canvas = document.querySelector(".signatureCanvasMobile");
	}else{
		$scope.canvas = document.querySelector(".signatureCanvasTablet");
	}

	$scope.signaturePad = new SignaturePad($scope.canvas, {
		onBegin: function(){
			$scope.$apply(function(){
				$scope.hasSignature = true;
			})
		}
	});

	//Functions

	$scope.closeModal = function(){
		$scope.signaturePad.clear();
		$scope.hasSignature = false;
	}

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.markOrderAsPickedUp($scope.modalData.orderIDs, $scope.signaturePad.toDataURL(), function(response){
			$scope.$emit('modalLoading', false);
			if($scope.deviceType == 'iPhone'){
				rotatePortrait()
			}
			$scope.$parent.closeModal();
			$scope.$emit('finishedMarkOrderSignature', {response:response,callback:$scope.modalData.callback});
		});
	};
});

pos.storeManager.controller("resendEmailConfirmation", function(globalFunctions, session, $scope, $rootScope, $timeout, orderService, $location){
	//Variables
	$scope.modalTitle = "Email Receipt";
	$scope.modalSubmitTitle = "Send";
	$scope.modalCancelTitle = "Cancel";
	$scope.modalData;
	$scope.formData;
	$scope.email = $scope.modalData.order.orderHeader.billCustomer.email;
	$scope.orderID = $scope.modalData.order.orderHeader.orderInformation.orderID;

	//Logic
	$scope.$emit('modalLoading', false);
	$timeout(function(){
		angular.element('[name=email]').focus();
	},1)

	//Functions
	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.$parent.closeModal();	
	}
	
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.sendEmailConfirmation($scope.orderID, $scope.email, session.posProfile, function(response){
			$scope.$emit('modalLoading', false);
			$scope.$emit('emailConfirmationSent', {response:response});
			$scope.$parent.closeModal();
		});
	};
});

pos.storeManager.controller("listOrders", function(globalFunctions, $scope, $rootScope, orderService, $location, $sce){
	//Variables
	$scope.loading = true;
	$scope.orders = [];
	$scope.selectedOrders = [];
	$scope.$parent.$parent.enableSearch = true;
	$scope.$parent.leftNav = false;
	$rootScope.currentManager.subSectionTitle = "List Orders";
	$rootScope.isRefund = false;
	$scope.currentPage = 1;
	$scope.maxRows = 20;
	
	//Logic

	//Listeners
	// hold a reference to the listener to destroy it
	var destroySearchListener = $rootScope.$on("searchlistOrders", function(event, searchForm){
		$scope.currentPage = 1;
		$scope.loading = true;
		$scope.listOrders(searchForm);
		$rootScope.searchQuery = searchForm;
	})
	
	$scope.$on('$destroy', function() {
        destroySearchListener();
    });

	//Functions
	$scope.listOrders = function(request){
		var request = request || {};
		$scope.currentPage = request.page || 1;
		orderService.listOrders(request, function(response){
			$scope.loading = false;
			$scope.orders = response.orders;
			$scope.selected = false;
		});
	}

	$scope.listOrders($rootScope.searchQuery);

	$scope.renderHTML = function(html){
		return $sce.trustAsHtml(html);
	}

	$scope.goToPage = function(page){
		$scope.loading = true;
		globalFunctions.scrollTo('top');
		$rootScope.searchQuery.page = page;
		$scope.currentPage = page;
		$scope.listOrders($rootScope.searchQuery);
	}

	$scope.markOrders = function(markAs, orders){
		$scope.loading = true;
		$scope.actionsDropdown = false;

		var orderIDs = "";
		angular.forEach($scope.selectedOrders, function(element,index){
			orderIDs += element.orderID;

			if(index != $scope.selectedOrders.length-1){
				orderIDs += ",";
			}
		});

		$scope.$parent.markOrder(markAs, orderIDs, function(response){
			$scope.listOrders($rootScope.searchQuery);
		});
	}

	$scope.toggleActions = function(order){
		var index = $scope.selectedOrders.indexOf(order);
		if (index > -1) {
			$scope.selectedOrders.splice(index, 1);
		}else{
			$scope.selectedOrders.push(order);
		}

		if($scope.selectedOrders.length){
			$scope.selected = true;
			var isPickup = globalFunctions.findInObject($scope.selectedOrders,"isPickup", "1").length,
			toBePickedUp = globalFunctions.findInObject($scope.selectedOrders,"toBePickedUp", "1").length,
			completedSubmitted = globalFunctions.findInObject($scope.selectedOrders,"orderStatus", "Completed").length + globalFunctions.findInObject($scope.selectedOrders,"orderStatus", "Submitted").length;

			if(isPickup == $scope.selectedOrders.length && completedSubmitted == $scope.selectedOrders.length){
				$scope.canMarkOrders = true;
			}else{
				$scope.canMarkOrders = false;
			}

		}else{
			$scope.selected = false;
		}

		return false;
	}

});

pos.storeManager.controller("viewOrder", function(session, globalData, globalFunctions, $scope, $rootScope, orderService, $stateParams, $sce, $location, $timeout){
	
	//Variables
	$scope.loading = true;
	$scope.order = {};
	$scope.$parent.$parent.enableSearch = false;
	$scope.orderID = $stateParams.orderID;
	$scope.$parent.leftNav = true;
	$rootScope.currentManager.subSectionTitle = "View Order";
	$rootScope.isRefund = globalFunctions.isRefund();
	$scope.customer = session.customer;


	//Logic
	orderService.viewOrder($scope.orderID, function(response){
		$scope.loading = false;
		$scope.order = response.order;
	});

	//Functions
	$scope.printReceipt = function(){
		$scope.actionsDropdown = false;
		globalFunctions.printReceipt(globalData.printerSettings.defaultPrinterMacAddress, $scope.orderID, "printReceipt");
	}
	
	$rootScope.$on('emailConfirmationSent', function(event, data){
		$scope.order = data.response.order;
	});

	$scope.renderHTML = function(html){
		return $sce.trustAsHtml(html);
	}

	$scope.markOrder = function(markAs, orderIDs){
		$scope.loading = true;
		$scope.actionsDropdown = false;

		$scope.$parent.markOrder(markAs, orderIDs, function(response){
			$scope.loading = false;
			orderService.viewOrder(orderIDs, function(response){
				$scope.order = response.order;
			});
		});
	}

	$scope.resumeOrder = function(){
		$scope.loading = true;	
		$scope.actionsDropdown = false;
		orderService.resumeOrder($scope.orderID, function(response){
			if(response.order.orderHeader.orderTenders.length){
				$rootScope.isSplitPayment = 1;
			}
			globalFunctions.clearSession();
			angular.copy(response.table, session.table);
			$scope.loading = false;
			$location.path("/store");
		});
	}

	$scope.initRefund = function(order){
		$scope.loading = true;	
		$location.path("/storeManager/order/refundOrder/"+$scope.orderID);
	}
	
	if(globalData.posInfo.posWorkflow){
		$scope.sendConfirmationEmail = function(order){
			$scope.loading = true;
			$scope.openModal("v2/views/partials/modals/resendEmailConfirmation",{order: order});
			$scope.loading = false;
		}
	}	
	
	$scope.viewOrder = function(orderID) {
		$location.path("/storeManager/order/viewOrder/"+orderID);
	}
});

pos.storeManager.controller("refundOrder", function(session, globalFunctions, globalData, $scope, $rootScope, $timeout, orderService, $stateParams, $sce, $location){
	//Variables
	$scope.loading = true;
	$scope.initLoading = true;
	$scope.refundDetails = {};
	$scope.orderItem = {};
	$scope.$parent.$parent.enableSearch = false;
	$scope.orderID = $stateParams.orderID;
	$scope.reviewRefund = false;
	$scope.$parent.leftNav = false;
	$scope.refundOpen = false;
	$scope.chargeOpen = false;
	$scope.couponsAndShippingApplicable = false;
	$rootScope.currentManager.subSectionTitle = "Refund Items";
	$rootScope.isRefund = globalFunctions.isRefund();
	$rootScope.hideBack = false;
	$scope.alertLevel = "";
	$scope.alertMessage = "";
	$scope.removeTenderMessage = "Cancel Refund";
	$scope.refundDueMessage = "Refund Due";
	

	//Logic

	//Listeners
	$rootScope.$on("updateRefund", function(event,data){
		$scope.refundDetails.refund = data;
		if(data.clearAlerts === 1){
			$scope.clearAlerts();
		}
		$scope.couponsAndShippingApplicable = $scope.areCouponsOrShippingApplicable();
		$timeout(function(){
			globalFunctions.calculateCartHeight();
		},100)
	});
	
	$rootScope.$on("updateRefundTender", function(){
		$scope.alertLevel = "success";
		$scope.alertMessage = "The tender has been refunded, review the remaining amount.";
	});
	
	$rootScope.$on('cartLoading', function(event, data){
		$scope.loading = data;
	});
	
	$rootScope.$on('chargeError', function(event, data){
		$scope.alertLevel = data.alertLevel;
		$scope.alertMessage = data.alertMessage;
		$rootScope.$emit('closeModal');
	});
	
	$rootScope.$on('chargeOpen', function(event, data){
		$scope.chargeOpen = $rootScope.hideBack = data;
	});
	
	$rootScope.$on('refundOpen', function(event, data){
		$scope.refundOpen = $rootScope.hideBack = data;
	});

	//Functions
	$scope.clearAlerts = function(){
		$scope.alertLevel = "";
		$scope.alertMessage = "";
	};
	
	$scope.openEditShippingModal = function(){
		$scope.exchangeShippingTotal = $scope.refundDetails.refund.refundHeader.exchangeShippingTotalCents;
		$scope.refundShippingTotal = $scope.refundDetails.refund.refundHeader.refundShippingTotalCents;
		
		$scope.shippingRefundedButNotCharged = ($scope.refundShippingTotal < 0 && $scope.exchangeShippingTotal == 0);
		$scope.shippingRefundedAndCharged = ($scope.refundShippingTotal < 0 && $scope.exchangeShippingTotal > 0);
		$scope.shippingChargedButNotRefunded = ($scope.refundShippingTotal == 0 && $scope.exchangeShippingTotal > 0);
		
		if($scope.shippingRefundedButNotCharged){ // Shipping has only been refunded
			$scope.openModal('v2/views/partials/modals/editRefundShipping', $scope.refundDetails) 
		}else if ($scope.shippingChargedButNotRefunded){ // Shipping has only been charged
			$scope.openModal('v2/views/partials/modals/overrideShipping', $scope.refundDetails)
		}else if ($scope.shippingRefundedAndCharged){ // Shipping has been refunded and charged
			$scope.openModal('v2/views/partials/modals/editExchangeAndRefundShipping', $scope.refundDetails)
		}
	}
	
	$scope.areCouponsOrShippingApplicable = function(){
		return globalFunctions.findInObject($scope.refundDetails.refund.orderItems,"refundType","Exchange").length > 0;
	}

	$scope.initRefund = function(){
		orderService.initRefund($scope.orderID, session.posProfile, function(response){
			$scope.loading = false;
			$scope.initLoading = false;
			
			if(globalData.posInfo.hasShippingExchanges || response.originalOrder.originalOrderHeader.isPickup === 1){
				$rootScope.$emit("refundInitialized", response.refund);
			}
			
			$rootScope.$emit("updateRefund", response.refund);
			$scope.refundDetails.originalOrder = response.originalOrder;
		});
	}
	$scope.initRefund();
	
	$rootScope.currentManager.goBack = function(){
		$location.path("/storeManager/order/viewOrder/"+$scope.orderID);
	}
	
	$scope.oneClickRefund = function(){
		$scope.loading = true;
		orderService.oneClickRefund($scope.orderID, session.posProfile, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.refundDetails.originalOrder = response.originalOrder;
			$scope.loading = false;
		});
	}

	$scope.originalItem = function(cartItem){
		if(cartItem.refundType != 'Exchange'){
			$scope.orderItem = globalFunctions.findInObject($scope.refundDetails.originalOrder.orderItems,"productSKUID", cartItem.productSKUID);
		} else {
			var results = globalFunctions.findInObject($scope.refundDetails.refund.orderItems,"productSKUID", cartItem.productSKUID);
			results.forEach(function(item){
				if (item.refundType == 'Exchange') {
					$scope.orderItem = item;
				}
			})
		}
		return $scope.orderItem;
	}

	$scope.itemInRefund = function(cartItem){
		$scope.itemFound = false;
		var matchedResults = globalFunctions.findInObject($scope.refundDetails.refund.orderItems,"productSKUID", cartItem.productSKUID);
		matchedResults.forEach(function(item){
			if(item.refundType != 'Exchange'){
				$scope.itemFound = true;
			}
		})
		return $scope.itemFound;
	}
	
	$scope.hasBeenRefunded = function(lineItem, returnType, buttonText){
		var isRefunded = false;
		if(typeof $scope.refundDetails.refund !== 'undefined'){
			var isRefunded = $scope.refundDetails.refund.refundHeader[lineItem].length && $scope.refundDetails.refund.refundHeader[lineItem].trim() !== "$0.00";
		}
		if(returnType === 'class'){
			return isRefunded ? 'grey' : 'blue';
		} else if(returnType === 'text'){
			return (isRefunded ? 'Edit Refund ' : 'Refund ') + buttonText;
		}
		return isRefunded;
	}

	$scope.processRefund = function(){
		if(globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"paymentType", "Terminal").length > 0){
			$scope.openModal('v2/views/partials/modals/insertCardRefund', {});
		}else{
			$scope.openModal('v2/views/partials/modals/refundProcess', {});
		}
	};

	$scope.tenderArray = function() {
		if($scope.refundDetails.originalOrder){
			var orderTender = $scope.refundDetails.originalOrder.originalOrderHeader.orderTenders;
			var addedOrderTender = $scope.refundDetails.refund.refundHeader.orderTenders;
			for(var i in orderTender){
				orderTender[i].amountRefunded = '';
				orderTender[i].newOrderTenderID = '';
				orderTender[i].isRefunded = 0;

				for(var j in addedOrderTender){
					if(orderTender[i].orderTenderID == addedOrderTender[j].previousOrderTenderID){
						orderTender[i].amountRefunded = addedOrderTender[j].amountTendered;
						orderTender[i].newOrderTenderID = addedOrderTender[j].orderTenderID;
						orderTender[i].isRefunded = 1;
					}
				}

				if(addedOrderTender.length == 0){
					orderTender[i].isRefunded = 0;
				}

				if (orderTender[i].paymentStatusID == 'BDCBD117-503C-4E5F-8EFD-0D6B53E551E7') {
					orderTender[i].amountRefunded = orderTender[i].amountTendered;
					orderTender[i].isRefunded = 1;
				}
			}
			return orderTender;
		}
	}

	$scope.removeTenderFromRefund = function(orderTender){
		$scope.loading = true;
		orderService.removeTenderFromRefund(orderTender.newOrderTenderID, function(response){
			$scope.loading = false;
			$scope.refundDetails.refund.refundHeader.orderTenders = response.refund.refundHeader.orderTenders;
			$rootScope.$emit("updateRefund", response.refund);
		});
	}

	$scope.authorizeLoyaltyPointsForRefund = function(orderTender){
		$scope.loading = true;
		orderService.authorizeLoyaltyPointsForRefund(orderTender.orderTenderID, function(response){
			$scope.loading = false;
			$scope.refundDetails.refund.refundHeader.orderTenders = response.refund.refundHeader.orderTenders;
			$rootScope.$emit("updateRefund", response.refund);
		});
	}
	
	$scope.nextStep = function(orderID,refundDetails){
		$scope.loading = true;
		//Timeouts used for effect.
		$timeout(function(){
			$scope.loading = false;
			$scope.reviewRefund = true;
		},400)
	}

	$scope.cancelRefund = function(){
		$scope.loading = true;

		//Timeouts used for effect.
		$timeout(function(){
			$scope.loading = false;
			$scope.reviewRefund = false;
		},400)
	}
	
	$scope.clearCart = function(){
		$scope.loading = true;
		$scope.initRefund();
	}

	$scope.renderHTML = function(html){
		return $sce.trustAsHtml(html);
	}
	
	$scope.openRefundScreen = function(type){
		$scope.loading = true;
		orderService.validateRefund(function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.loading = false;
			if(response.refund.processable == 1){
				switch(type){
					case 'refund':
						$rootScope.$emit('refundOpen', true);
						break;
					case 'charge':
						$rootScope.$emit('chargeOpen', true);
						break;
				}
			}
		});
	}
	
	$scope.closeRefundWindow = function(){
		$rootScope.$emit('refundOpen', false);
	};
});

pos.storeManager.controller("refundEMV", function(globalFunctions, $scope, $rootScope, $localStorage, orderService, emvTerminalService, session, $timeout){
	$scope.modalTitle = "Complete Refund";
	$scope.modalSubmitTitle = "hide";
	$scope.storage = $localStorage;
	$scope.emvTerminal = $scope.storage.emvTerminal;
	$scope.modalData;
	$scope.refundComplete=0;
	$scope.orderNumber = session.refund.refundHeader.orderInformation.orderNumber;
	$scope.previousOrderNumber = session.refund.refundHeader.orderInformation.previousOrderNumber;
	$scope.orderType = session.refund.refundHeader.orderInformation.orderType;
	$scope.splitCount = session.refund.refundHeader.orderTenders.length;

	$scope.transactionInformation = {};
	$scope.transactionInformation.payments = angular.isArray($scope.modalData.payments) ? $scope.modalData.payments : []; // If we have called this recursively then the modalData will be an array of transactionInformation
	
	// Grab all terminal tenders, and then filter for non-refunded terminal tenders
	$scope.terminalTenders = globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"paymentType", "Terminal");
	$scope.notRefundedTerminalTenders = globalFunctions.findInObject($scope.terminalTenders,"refunded", 0);
	
	if(angular.isArray($scope.notRefundedTerminalTenders)){
		$scope.countNotRefundedTerminalTenders = $scope.notRefundedTerminalTenders.length;
		
		// We need to choose a single non-refunded tender to start off this process, and store it in the session
		session.refund.chosenTender = $scope.notRefundedTerminalTenders[0];
	}else{
		session.refund.chosenTender = $scope.notRefundedTerminalTenders;
		$scope.countNotRefundedTerminalTenders = 1;
	}
	
	// Counter to track whether any terminal tenders have been refunded already
	$scope.refundCounter = $scope.terminalTenders.length - $scope.countNotRefundedTerminalTenders + 1;
	
	$scope.unformattedAmountTendered = session.refund.chosenTender.unformattedAmountTendered;
	$scope.amountTendered = session.refund.chosenTender.amountTendered;
	$scope.creditCardType = session.refund.chosenTender.creditCardType;
	$scope.cardNumberLast4 = session.refund.chosenTender.cardNumber.slice(-4);
	
	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.nextModal();
	}
	
	$scope.nextModal = function(){
		orderService.refundWithTerminal(function(response){
			$scope.openModal(response.nextModal,$scope.transactionInformation);
		});	
	}
	
	$scope.setModalCancelTitle = function(){
		// Don't allow the user to cancel if they have already refunded a tender
		$scope.modalCancelTitle = ($scope.refundCounter > 1 || $scope.refundComplete) ? "hide" : "Cancel";
	}
	
	$scope.closeModal = function(){
		$scope.loading = true;
		$scope.removeAllTendersAndCloseModal();
	}
	
	$scope.removeAllTendersAndCloseModal = function(){
		$scope.loading = true;
		var tendersRemoved = 0
		var countTendersToRemove = session.refund.refundHeader.orderTenders.length;
		angular.forEach(session.refund.refundHeader.orderTenders, function(tender){
			orderService.removeTenderFromRefund(tender.orderTenderID, function(response){
				tendersRemoved++;
				response.refund.clearAlerts = 1;
				$rootScope.$emit("updateRefund", response.refund);
				
				if(tendersRemoved === countTendersToRemove){
					$scope.loading = false;
					$scope.$parent.closeModal();
				}
			});
		});
	}
	
	$scope.getCardPresentTenderIDs = function(){
		var cardPresentTenders = globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"cardPresent", 1);
		var cardPresentTenderIDs = [];
		if(angular.isArray(cardPresentTenders)){
			angular.forEach(cardPresentTenders, function(tender){
				cardPresentTenderIDs.push(tender.orderTenderID);
			});
		}else{
			cardPresentTenderIDs.push(cardPresentTenders.orderTenderID);
		}
		
		return cardPresentTenderIDs;
	}
	
	$scope.markTenderAsRefunded = function(){
		// Mark the tender as refunded so we don't refund it again when recursively calling this.
		globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"orderTenderID", session.refund.chosenTender.orderTenderID)[0].refunded = 1;
	}
	
	$scope.setCardPresentStatusOnTender = function(cardPresentStatus){
		// Need to keep track of which tenders are not being refunded via card present refund
		globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"orderTenderID", session.refund.chosenTender.orderTenderID)[0].cardPresent = cardPresentStatus;
	}
	
	$scope.chooseNextNonRefundedTenderAndProceed = function(){
		session.refund.chosenTender = globalFunctions.findInObject($scope.terminalTenders,"refunded", 0)[0];
		
		// If there is no other tender, proceed to next step
		if (session.refund.chosenTender == undefined) {
			$scope.modalAction();
		} else {
			$scope.refundComplete = 1;
			$scope.setModalCancelTitle();

			$scope.$emit('modalLoading', true);
			globalFunctions.alertMessage("loadingNotice", null, "Preparing to refund the next tender.");
			$timeout(function(){
				$scope.modalAction();
			},5000);
		}
	}
	
	$scope.buildTransactionInformation = function(cardPresent, transactionResponse){
		if(cardPresent) transactionResponse.orderTenderID = session.refund.chosenTender.orderTenderID;
		$scope.transactionInformation.cardPresentTenders = $scope.getCardPresentTenderIDs();
		$scope.transactionInformation.payments.push(transactionResponse);
	}
	
	//Initiate Refund
	$scope.sendTransactionToTerminal = function(){
		emvTerminalService.sendTransaction(
			$scope.unformattedAmountTendered,
			$scope.emvTerminal.terminalID,
			$scope.emvTerminal.locationID,
			session.posProfile.zeamsterProductTransactionID,
			$scope.emvTerminal.nickName,
			'refund',
			$scope.orderNumber,
			$scope.previousOrderNumber,
			$scope.orderType,
			$rootScope.isSplitPayment,
			$scope.splitCount,
			function(response){
				// Transaction returning means the EMV process is done, deal with results
				// If a transactionID came back, record payment and continue if it was successful
				if (response.transactionResults.transactionID) {
					var transaction = response.apiResults.routertransaction; 
					var terminalTransactionInfo = {
						successful: response.transactionResults.successful,
						transactionID: transaction.id,
						authCode: transaction.auth_code || '',
						cardNumber: transaction.first_six+'000000'+transaction.last_four,
						nameOnCard: transaction.account_holder_name || '',
						reasonCode: response.transactionResults.reasonCode
					}
					
					if (response.transactionResults.successful) {
						$scope.markTenderAsRefunded();
						$scope.setCardPresentStatusOnTender(1);
						$scope.buildTransactionInformation(true, terminalTransactionInfo);
						$scope.chooseNextNonRefundedTenderAndProceed();
					} else {
						$scope.setCardPresentStatusOnTender(1);
						$scope.buildTransactionInformation(true, terminalTransactionInfo);
						$scope.alertLevel = 'error';
						$scope.alertMessage = 'Refund failed with the following error: ' + terminalTransactionInfo.reasonCode;
						$scope.setModalCancelTitle();
					}
				} else {
					$scope.setModalCancelTitle();
				}
			}
		);
	}
	
	$scope.retryTransaction = function(){
		$scope.modalTitle = "Complete Refund";
		$scope.alertLevel = "";
		$scope.alertMessage = "";
		$scope.setModalCancelTitle();
		$scope.sendTransactionToTerminal();
	}

	// This switches the refund flow to go through our normal non-EMV flow
	$scope.cardNotPresentRefund = function(){
		$scope.markTenderAsRefunded();
		$scope.buildTransactionInformation(false, {});
		$scope.chooseNextNonRefundedTenderAndProceed();
	}

	// Listeners
	$rootScope.$on('modalError', function(event, data){
		$scope.$emit('modalLoading', false);
		
		$scope.alertLevel = data.alertLevel;
		$scope.alertMessage = data.alertMessage;
		$scope.modalTitle = "Refund Error";
	});
	
	
	//Logic
	$scope.modalCancelTitle = "hide";
	
	if($scope.refundComplete == 0){
		$scope.$emit('modalLoading', false);
		$scope.cardNotPresentRefund();
		// $scope.sendTransactionToTerminal();
	}
});

pos.storeManager.controller("insertCardRefund", function(globalFunctions, $timeout, $scope, $rootScope, $localStorage, orderService, paymentService, session, $filter){
	$scope.modalTitle = "Complete Refund";
	$scope.modalSubmitTitle = "hide";
	$scope.storage = $localStorage;
	$scope.paymentTerminal = $scope.storage.paymentTerminal;
	$scope.modalData;
	$scope.refundComplete=0;
	$scope.orderNumber = session.refund.refundHeader.orderInformation.previousOrderNumber;
	$scope.refundOrderNumber = session.refund.refundHeader.orderInformation.orderNumber;
	$scope.orderType = session.refund.refundHeader.orderInformation.orderType;
	$scope.paymentRequestID = '';

	$scope.transactionInformation = {};
	$scope.transactionInformation.payments = angular.isArray($scope.modalData.payments) ? $scope.modalData.payments : []; // If we have called this recursively then the modalData will be an array of transactionInformation
	
	// Grab all terminal tenders, and then filter for non-refunded terminal tenders
	$scope.terminalTenders = globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"paymentType", "Terminal");
	$scope.notRefundedTerminalTenders = globalFunctions.findInObject($scope.terminalTenders,"refunded", 0);
	
	if(angular.isArray($scope.notRefundedTerminalTenders)){
		$scope.countNotRefundedTerminalTenders = $scope.notRefundedTerminalTenders.length;
		
		// We need to choose a single non-refunded tender to start off this process, and store it in the session
		session.refund.chosenTender = $scope.notRefundedTerminalTenders[0];
	}else{
		session.refund.chosenTender = $scope.notRefundedTerminalTenders;
		$scope.countNotRefundedTerminalTenders = 1;
	}
	
	// Counter to track whether any terminal tenders have been refunded already
	$scope.refundCounter = $scope.terminalTenders.length - $scope.countNotRefundedTerminalTenders + 1;
	
	$scope.unformattedAmountTendered = session.refund.chosenTender.unformattedAmountTendered;
	$scope.amountTendered = session.refund.chosenTender.amountTendered;
	$scope.creditCardType = session.refund.chosenTender.creditCardType;
	$scope.cardNumberLast4 = session.refund.chosenTender.cardNumber.slice(-4);
	
	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$rootScope.$broadcast("cancelTimer", session.refund);
		$scope.nextModal();
	}
	
	$scope.nextModal = function(){
		orderService.refundWithTerminal(function(response){
			$scope.openModal(response.nextModal,$scope.transactionInformation);
		});	
	}
	
	$scope.setModalCancelTitle = function(){
		// Don't allow the user to cancel if they have already refunded a tender
		$scope.modalCancelTitle = ($scope.refundCounter > 1 || $scope.refundComplete) ? "hide" : "Cancel";
	}
	
	$scope.closeModal = function(){
		$rootScope.$broadcast("cancelTimer", session.refund);
		$scope.loading = true;
		paymentService.cancelPaymentRequest($scope.paymentRequestID,function(){
			$scope.removeAllTendersAndCloseModal();
		});
	}
	
	$scope.removeAllTendersAndCloseModal = function(){
		$scope.loading = true;
		var tendersRemoved = 0
		var countTendersToRemove = session.refund.refundHeader.orderTenders.length;
		angular.forEach(session.refund.refundHeader.orderTenders, function(tender){
			orderService.removeTenderFromRefund(tender.orderTenderID, function(response){
				tendersRemoved++;
				response.refund.clearAlerts = 1;
				$rootScope.$emit("updateRefund", response.refund);
				
				if(tendersRemoved === countTendersToRemove){
					$scope.loading = false;
					$scope.$parent.closeModal();
				}
			});
		});
	}
	
	$scope.getCardPresentTenderIDs = function(){
		var cardPresentTenders = globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"cardPresent", 1);
		var cardPresentTenderIDs = [];
		if(angular.isArray(cardPresentTenders)){
			angular.forEach(cardPresentTenders, function(tender){
				cardPresentTenderIDs.push(tender.orderTenderID);
			});
		}else{
			cardPresentTenderIDs.push(cardPresentTenders.orderTenderID);
		}
		
		return cardPresentTenderIDs;
	}
	
	$scope.markTenderAsRefunded = function(){
		// Mark the tender as refunded so we don't refund it again when recursively calling this.
		globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"orderTenderID", session.refund.chosenTender.orderTenderID)[0].refunded = 1;
	}
	
	$scope.setCardPresentStatusOnTender = function(cardPresentStatus){
		// Need to keep track of which tenders are not being refunded via card present refund
		globalFunctions.findInObject(session.refund.refundHeader.orderTenders,"orderTenderID", session.refund.chosenTender.orderTenderID)[0].cardPresent = cardPresentStatus;
	}
	
	$scope.chooseNextNonRefundedTender = function(){
		session.refund.chosenTender = globalFunctions.findInObject($scope.terminalTenders,"refunded", 0)[0];
	}
	
	$scope.buildTransactionInformation = function(cardPresent, transactionResponse){
		if(cardPresent) transactionResponse.orderTenderID = session.refund.chosenTender.orderTenderID;
		$scope.transactionInformation.cardPresentTenders = $scope.getCardPresentTenderIDs();
		$scope.transactionInformation.payments.push(transactionResponse);
	}
	
	//Initiate Refund
	$scope.initiatePaymentRequest = function(){
		paymentService.initiatePaymentRequest($scope.unformattedAmountTendered,$scope.storage.paymentTerminal.paymentTerminalKey,'refund',$scope.orderNumber,$scope.refundOrderNumber,function(response){
			$scope.paymentRequestID=response.paymentRequestID;
			$scope.$emit('modalLoading', false);
			$scope.getPaymentStatus();
		});
	}
	
	$scope.getPaymentStatus = function(){
		paymentService.getPaymentStatus($scope.paymentRequestID,function(response){
			$scope.markTenderAsRefunded();
			$scope.setCardPresentStatusOnTender(1);
			$scope.buildTransactionInformation(true, response);
			$scope.chooseNextNonRefundedTender();
			$scope.refundComplete = 1;
			$scope.setModalCancelTitle();
		});
	}
	
	$scope.retryPaymentStatus = function(){
		$scope.alertLevel = "";
		$scope.alertMessage = "";
		$scope.getPaymentStatus();
	}
	
	$scope.retryPaymentRequest = function(){
		$scope.modalTitle = "Complete Refund";
		$scope.alertLevel = "";
		$scope.alertMessage = "";
		$scope.setModalCancelTitle();
		$scope.initiatePaymentRequest();
	}

	// This switches the refund flow to go through our normal non-EMV flow
	$scope.cardNotPresentRefund = function(){
		$rootScope.$broadcast("cancelTimer", session.refund);
		paymentService.cancelPaymentRequest($scope.paymentRequestID,function(response){
			$scope.markTenderAsRefunded();
			$scope.buildTransactionInformation(false, {});
			$scope.chooseNextNonRefundedTender();
			$scope.modalAction();
		});
	}

	// Listeners
	$rootScope.$on('modalError', function(event, data){
		$rootScope.$broadcast("cancelTimer", session.refund);
		$scope.$emit('modalLoading', false);
		
		$scope.alertLevel = data.alertLevel;
		$scope.alertMessage = data.alertMessage;
		$scope.modalTitle = "Refund Error";
	});
	
	
	//Logic
	$scope.setModalCancelTitle();
	
	if($scope.refundComplete == 0){
		$scope.initiatePaymentRequest();
	}
});

pos.storeManager.controller("processRefund", function(globalFunctions, globalData, $scope, $localStorage, $rootScope, $stateParams, orderService, session){
	//Variables
	$scope.modalTitle = "Process Refund";
	$scope.modalSubmitTitle = "Send Receipt";
	$scope.modalCancelTitle = "No Thanks";
	$scope.orderID = $stateParams.orderID;
	$scope.modalData;
	$scope.refund = session.refund;
	$scope.receiptMessage = "Would you like to send a receipt for this refund for " + $scope.refund.refundHeader.totalAfterTip + "?";
	$scope.emailLabel = "Email Refund Receipt";
	
	if($scope.refund.refundHeader.totalAfterTipCents == 0){
		$scope.modalTitle = "Receipt";
		$scope.receiptMessage = "Would you like to send a receipt?";
		$scope.emailLabel = "Email";
	}

	$scope.formData = {};
	$scope.displaySecondaryEmailCheckbox = 0;
	$scope.formData.includeSecondaryEmails = 0;
	$scope.refundCustomerID = session.refund.refundHeader.billCustomer.customerID;

	$scope.locationID = typeof($localStorage.emvTerminal) === 'undefined' ? '' : typeof($localStorage.emvTerminal.locationID) === 'undefined' ? '' : $localStorage.emvTerminal.locationID;

	//Logic
	if(globalData.posInfo.hasSecondaryEmails && $scope.refundCustomerID){
		orderService.getAdditionalEmailsForContact($scope.refundCustomerID, function(response){
			if(response.getAdditionalEmailsForContact.additionalEmails.length > 0){
				$scope.displaySecondaryEmailCheckbox = 1;
				$scope.formData.includeSecondaryEmails = 1;
			}
			$scope.$emit('modalLoading', false);
		});
	} else {
		$scope.$emit('modalLoading', false);
	}

	//Listeners

	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.emailAddress($scope.formData.email, function(response){
			orderService.processRefund($scope.modalData, 1, $scope.formData.includeSecondaryEmails, session.posProfile.zeamsterProductTransactionID, $scope.locationID, function(response){
				$scope.openModal("v2/views/partials/modals/refundSuccess", $scope.modalData);
				$scope.openCashDrawer();
			});
		})
	}

	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.processRefund($scope.modalData,0, $scope.formData.includeSecondaryEmails, session.posProfile.zeamsterProductTransactionID, $scope.locationID, function(response){
			$scope.openModal("v2/views/partials/modals/refundSuccess", $scope.modalData);
			$scope.openCashDrawer();
		});
	}
	
	$scope.openCashDrawer = function(){
		if($scope.hasCashPayment()){
			globalFunctions.openCashDrawer(globalData.printerSettings.defaultCashDrawerMacAddress);
		}
	}
	
	$scope.hasCashPayment = function(){
		$scope.hasCash = false;
		$scope.refund.refundHeader.orderTenders.forEach(function(item){
			if(item.paymentType == "Cash"){
				$scope.hasCash = true;
			}
		});
		return $scope.hasCash;
	}

});

pos.storeManager.controller("refundSuccess", function(globalFunctions, globalData, $scope, $rootScope, $stateParams, orderService, $location, session){
	//Variables
	$scope.modalTitle = "Success";
	$scope.modalSubmitTitle = "Back To Orders";
	$scope.modalCancelTitle = "hide";
	$scope.orderID = $stateParams.orderID;
	$scope.modalData;
	$scope.refund = session.refund;

	if($scope.refund.refundHeader.totalAfterTipCents <= 0){
		$scope.refundSuccessMessage = "This exchange for " + $scope.refund.refundHeader.totalAfterTip + " has been processed";
		$scope.modalTitle = "Success";
	} else {
		$scope.refundSuccessMessage = "This refund for " + $scope.refund.refundHeader.totalAfterTip + " has been processed";	
	}
	
	//Logic
	$scope.$emit('modalLoading', false);


	//Listeners


	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$location.path("/storeManager/order/listOrders");
		$scope.closeModal();
	}

	$scope.print = function(){
		globalFunctions.printReceipt(globalData.printerSettings.defaultPrinterMacAddress, session.refund.refundHeader.refundOrderID, "printReceipt");
	}

});

pos.storeManager.controller("addItemToRefund", function(globalFunctions, globalData, $scope, $rootScope, orderService, inventoryService, session){
	//Variables
	$scope.modalSubmitTitle = "Apply";
	$scope.modalData;
	$scope.websiteName = globalData.posInfo.websiteName;
	$scope.defaultInventoryPoolID = session.posProfile.pickupInventoryPoolID;
	$scope.modalTitle = "Refund Item";
	
	//Listeners


	//Functions
	$scope.initRefundItem = function(){
		$scope.formData = {
			refundItem: {
				originalOrderItemID: $scope.modalData.orderItemID,
				price: $scope.productInRefund().salePrice ? $scope.productInRefund().salePrice : $scope.modalData.salePrice.substr(1),
				quantity: $scope.modalData.quantity,
				refundType: ($scope.productInRefund().refundType || 'Return'),
				inventoryPoolID: ($scope.productInRefund().inventoryPoolID || $scope.defaultInventoryPoolID)
			}
		};
	}
	
	// boundary: min or max (integer) - At what value should the action not go passed
	// operation: 'add' or 'subtract' (string) - Are we adding or subtracting 1 from quantity
	$scope.updateQuantity = function(boundary,operation){
		if ($scope.formData.refundItem.quantity !== boundary) {
			operation == 'add' ? $scope.formData.refundItem.quantity++ : $scope.formData.refundItem.quantity--;
		}
	}
	
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.addItemToRefund($scope.formData.refundItem, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}

	$scope.removeItemFromRefund = function(item){
		$scope.modalActionDefaults($scope.formData);
		orderService.removeItemFromRefund(item.orderItemID, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
	
	$scope.listInventoryPools = function(){
		inventoryService.listInventoryPools(function(response){
			$scope.inventoryPools = response.inventoryPools;
			$scope.currentInventoryPoolID = response.inventoryPools[0].inventoryPoolID
		})
	}
	
	$scope.productInRefund = function(){
		$scope.itemFound = {};
		var matchedResults = globalFunctions.findInObject(session.refund.orderItems,"productSKUID", $scope.modalData.productSKUID);
		matchedResults.forEach(function(item){
			if(item.refundType != 'Exchange'){
				$scope.itemFound = item;
			}
		})
		return $scope.itemFound;
	}
	
	//Logic
	$scope.listInventoryPools();
	$scope.$emit('modalLoading', false);
});

pos.storeManager.controller("addTenderToRefund", function(globalFunctions, globalData, $scope, $rootScope, orderService, session){
	//Variables
	$scope.modalSubmitTitle = "Process";
	$scope.modalData;
	$scope.websiteName = globalData.posInfo.websiteName;
	$scope.modalTitle = "Refund " + $scope.modalData.paymentType;

	//Logic
	$scope.$emit('modalLoading', false);

	//Listeners
	
	//Functions
	$scope.amountToRefund = function(){
		var refundDefaultAmount = $scope.modalData.amountTendered;
		var amountTendered = Number($scope.modalData.amountTendered.replace(/[^0-9\.]+/g,""));
		var amountDue = Number(session.refund.refundHeader.amountDue.replace(/[^0-9\.]+/g,""));

		if(amountDue < amountTendered){
			refundDefaultAmount = session.refund.refundHeader.amountDue.replace(/[{()}]/g, '').trim();
		} 
		return refundDefaultAmount;
	}
	
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.addTenderToRefund($scope.formData.originalOrderTenderID, $scope.formData.amountTendered, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$rootScope.$emit('updateRefundTender', false);
			
			if(response.nextModal.length){
				$scope.openModal(response.nextModal,{orderTenderID:$scope.formData.originalOrderTenderID,amountTendered:$scope.formData.amountTendered});
			} else{
				$scope.closeModal();
			}
		});
	}
});

pos.store.controller("editExchangeItem", function($scope, $rootScope, $filter, globalData, globalFunctions, orderService, session){
	// Variables
	$scope.modalTitle = "Edit Product";
	$scope.modalSubmitTitle = "Save";
	$scope.modalData;


	//Logic
	$scope.$emit('modalLoading', false);

	$scope.formData = {};
	if($scope.modalData.isPriceOverride == 1){
		$scope.formData.price = $scope.modalData.priceNumeric;
	}else{
		$scope.formData.price = "";
	}


	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.updateExchangeProduct($scope.formData, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}

	$scope.removeItem = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.removeExchangeItemFromRefund($scope.modalData, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
});

pos.storeManager.controller("editRefundHandling", function(globalFunctions, $scope, $rootScope, orderService){
	$scope.modalTitle = "Refund Handling";
	$scope.modalSubmitTitle = "Apply";
	$scope.hasBeenRefunded = $scope.modalData.refund.refundHeader.handlingTotal.length;

	//Logic
	$scope.$emit('modalLoading', false);


	//Listeners


	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.refundHandling($scope.formData.handlingRefund, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
	
	$scope.handlingAmountToRefund = function(){
		// If refund doesn't have any handling refunded yet, use original handling as default
		var refundHandling = globalFunctions.parseCFNumber($scope.modalData.refund.refundHeader.handlingTotal);
		var orderHandling = globalFunctions.parseCFNumber($scope.modalData.originalOrder.originalOrderHeader.handlingTotal);
		if(refundHandling != 0) {
			return "$" + (refundHandling * -1).toFixed(2);
		}
		return "$" + (orderHandling * 1).toFixed(2);
	}
});

pos.storeManager.controller("editRefundDeposit", function(globalFunctions, $scope, $rootScope, orderService){
	$scope.modalTitle = "Refund Deposit";
	$scope.modalSubmitTitle = "Apply";
	$scope.modalData = (globalFunctions.parseCFNumber($scope.modalData) * -1).toFixed(2);

	//Logic
	$scope.$emit('modalLoading', false);


	//Listeners


	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.refundDeposit($scope.formData.depositRefund, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
});

pos.storeManager.controller("editRefundCrv", function(globalFunctions, $scope, $rootScope, orderService){
	$scope.modalTitle = "Refund CRV";
	$scope.modalSubmitTitle = "Apply";
	$scope.modalData = (globalFunctions.parseCFNumber($scope.modalData) * -1).toFixed(2);

	//Logic
	$scope.$emit('modalLoading', false);


	//Listeners


	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.refundCrv($scope.formData.crvRefund, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
});

pos.storeManager.controller("editRefundShipping", function(globalFunctions, $scope, $rootScope, orderService){
	$scope.modalTitle = "Refund Shipping";
	$scope.modalSubmitTitle = "Apply";
	$scope.hasBeenRefunded = $scope.modalData.refund.refundHeader.shippingTotal.length;

	//Logic
	$scope.$emit('modalLoading', false);


	//Listeners


	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.refundShipping($scope.formData.shippingRefund, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
	
	$scope.shippingAmountToRefund = function(){
		// If refund doesn't have any shipping refunded yet, use original shipping as default
		var refundShipping = globalFunctions.parseCFNumber($scope.modalData.refund.refundHeader.refundShippingTotal);
		var orderShipping = globalFunctions.parseCFNumber($scope.modalData.originalOrder.originalOrderHeader.shippingTotal);
		if(refundShipping != 0) {
			return "$" + (refundShipping * -1).toFixed(2);
		}
		return "$" + (orderShipping * 1).toFixed(2);
	}
});

pos.store.controller("editExchangeAndRefundShipping", function($scope, $rootScope, globalData, globalFunctions, shippingService, orderService){
	//Variables	
	$scope.modalTitle = "Edit Shipping";
	$scope.modalData;

	//Logic
	$scope.$emit('modalLoading', false);
	
	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.refundShipping($scope.formData.shippingRefund, function(response){
			// Call exchange override after refund shipping to prevent the override from being reset
			if($scope.formData.exchangeShippingPrice != ''){
				shippingService.overrideExchangeShipping($scope.formData.exchangeShippingPrice, function(response){
					$rootScope.$emit("updateRefund", response.refund);
					$scope.closeModal();
				});
			}else{
				$rootScope.$emit("updateRefund", response.refund);
				$scope.closeModal();
			}
		});
	}

	$scope.removeShippingAddress = function(){
		$scope.modalActionDefaults($scope.formData);
		shippingService.removeShippingAddress(function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
	
	$scope.shippingAmountToRefund = function(){
		// If refund doesn't have any shipping refunded yet, use original shipping as default
		var refundShipping = globalFunctions.parseCFNumber($scope.modalData.refund.refundHeader.refundShippingTotal);
		var orderShipping = globalFunctions.parseCFNumber($scope.modalData.originalOrder.originalOrderHeader.shippingTotal);
		if(refundShipping != 0) {
			return "$" + (refundShipping * -1).toFixed(2);
		}
		return "$" + (orderShipping * 1).toFixed(2);
	}
});

pos.storeManager.controller("editRefundTip", function(globalFunctions, $scope, $rootScope, orderService){
	$scope.modalTitle = "Refund Tip";
	$scope.modalSubmitTitle = "Apply";
	$scope.hasBeenRefunded = $scope.modalData.refund.refundHeader.tip.trim() !== "$0.00";

	//Logic
	$scope.$emit('modalLoading', false);


	//Listeners


	//Functions
	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		orderService.refundTip($scope.formData.tipRefund, function(response){
			$rootScope.$emit("updateRefund", response.refund);
			$scope.closeModal();
		});
	}
	
	$scope.tipAmountToRefund = function(){
		// If refund doesn't have any tip refunded yet, use original tip as default
		var refundTip = globalFunctions.parseCFNumber($scope.modalData.refund.refundHeader.tip);
		var orderTip = globalFunctions.parseCFNumber($scope.modalData.originalOrder.originalOrderHeader.tip);
		if(refundTip != 0) {
			return "$" + (refundTip * -1).toFixed(2);
		}
		return "$" + (orderTip * 1).toFixed(2);
	}
});
