
pos.store.factory("clubMembershipService", function($http, session,globalData, globalFunctions){

	var addClubMembership = function(pickupLocationID,	shipCustomerID,	productSKU,	signupDay,	signupMonth,	signupYear,	cancelDay,	cancelMonth,	cancelYear,	clubCancellationReasonID,	onHoldStartDay,	onHoldStartMonth,	onHoldStartYear,	onHoldEndDay,	onHoldEndMonth,	onHoldEndYear,	productDiscount,	shippingDiscount,	salesAssociateID,	sourceCode,	notes,	retainClubPrivileges,	overrideShipping,	shippingStrategyID,	shippingTypeID,	isAGift,	giftMessage,	welcomePackageGiftCardCode,	welcomePackageCouponCode,	userChoice, creditCardID,	shipTo,	customerCreditCardID,	clubID, sendEmail, sendCancellationEmail, sendHoldEmail, callback){

		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.addClubMembership",{
		pickupLocationID:pickupLocationID,
		shipCustomerID:shipCustomerID,
		productSKU:productSKU,
		signupDay:signupDay,
		signupMonth:signupMonth,
		signupYear:signupYear,
		cancelDay:cancelDay,
		cancelMonth:cancelMonth,
		cancelYear:cancelYear,
		clubCancellationReasonID:clubCancellationReasonID,
		onHoldStartDay:onHoldStartDay,
		onHoldStartMonth:onHoldStartMonth,
		onHoldStartYear:onHoldStartYear,
		onHoldEndDay:onHoldEndDay,
		onHoldEndMonth:onHoldEndMonth,
		onHoldEndYear:onHoldEndYear,
		productDiscount:productDiscount,
		shippingDiscount:shippingDiscount,
		salesAssociateID:salesAssociateID,
		sourceCode:sourceCode,
		notes:notes,
		retainClubPrivileges:retainClubPrivileges,
		overrideShipping:overrideShipping,
		shippingStrategyID:shippingStrategyID,
		shippingTypeID:shippingTypeID,
		isAGift:isAGift,
		giftMessage:giftMessage,
		welcomePackageGiftCardCode:welcomePackageGiftCardCode,
		welcomePackageCouponCode:welcomePackageCouponCode,
		userChoice:userChoice,
		creditCardID:creditCardID,
		shipTo:shipTo,
		customerCreditCardID:customerCreditCardID,
		clubID:clubID,
		sendEmail:sendEmail,
		sendCancellationEmail:sendCancellationEmail,
		sendHoldEmail:sendHoldEmail,
		customerID:session.customer.customerID,
		posProfile:JSON.stringify(session.posProfile)},function(response){
			(callback)(response);
		},"POST", null, 0);
	}

	var editClubMembership = function(clubMembershipID, pickupLocationID,	shipCustomerID,	productSKU,	signupDay,	signupMonth,	signupYear,	cancelDay,	cancelMonth,	cancelYear,	clubCancellationReasonID,	onHoldStartDay,	onHoldStartMonth,	onHoldStartYear,	onHoldEndDay,	onHoldEndMonth,	onHoldEndYear,	productDiscount,	shippingDiscount,	salesAssociateID,	sourceCode,	notes,	retainClubPrivileges,	overrideShipping,	shippingStrategyID,	shippingTypeID,	isAGift,	giftMessage,	welcomePackageGiftCardCode,	welcomePackageCouponCode,	userChoice, creditCardID,	shipTo,	customerCreditCardID,	clubID, sendEmail, sendCancellationEmail, sendHoldEmail, callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.editClubMembership",{clubMembershipID:clubMembershipID,
			pickupLocationID:pickupLocationID,
			shipCustomerID:shipCustomerID,
			productSKU:productSKU,
			signupDay:signupDay,
			signupMonth:signupMonth,
			signupYear:signupYear,
			cancelDay:cancelDay,
			cancelMonth:cancelMonth,
			cancelYear:cancelYear,
			clubCancellationReasonID:clubCancellationReasonID,
			onHoldStartDay:onHoldStartDay,
			onHoldStartMonth:onHoldStartMonth,
			onHoldStartYear:onHoldStartYear,
			onHoldEndDay:onHoldEndDay,
			onHoldEndMonth:onHoldEndMonth,
			onHoldEndYear:onHoldEndYear,
			productDiscount:productDiscount,
			shippingDiscount:shippingDiscount,
			salesAssociateID:salesAssociateID ? salesAssociateID : "",
			sourceCode:sourceCode,
			notes:notes,
			retainClubPrivileges:retainClubPrivileges,
			overrideShipping:overrideShipping,
			shippingStrategyID:shippingStrategyID,
			shippingTypeID:shippingTypeID,
			isAGift:isAGift,
			giftMessage:giftMessage,
			welcomePackageGiftCardCode:welcomePackageGiftCardCode,
			welcomePackageCouponCode:welcomePackageCouponCode,
			userChoice:userChoice,
			creditCardID:creditCardID,
			shipTo:shipTo,
			customerCreditCardID:customerCreditCardID,
			clubID:clubID,
			sendEmail:sendEmail,
			sendCancellationEmail:sendCancellationEmail,
			sendHoldEmail: sendHoldEmail,
			customerID:session.customer.customerID,
			posProfile:JSON.stringify(session.posProfile)
		},function(response){
			(callback)(response);
		}, "POST", 15000);
	}

	var listClubMemberships = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.listClubMemberships", {page:page, maxRows:maxRows,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listClubs = function(page, maxRows, callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.listClubs", {page:page, maxRows:maxRows,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listShippingStrategies = function(callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.listShippingStrategies", {customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listShippingStrategies = function(callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.listShippingStrategies", {customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}


	var listShippingTypes = function(shippingStrategyID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.listShippingTypes", {shippingStrategyID:shippingStrategyID,customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listClubCancellationReasons = function(callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.listClubCancellationReasons",{customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var listPickupLocations = function(callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.listPickupLocations",{customerID:session.customer.customerID} ,function(response){
			(callback)(response);
		}, null, 15000);
	}

	var viewClub = function(clubID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.viewClub",{clubID:clubID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	var viewClubMembership = function(clubMembershipID, callback){
		globalFunctions.v65http("/customer/index.cfm?method=clubMemberships.viewClubMembership",{clubMembershipID:clubMembershipID,customerID:session.customer.customerID},function(response){
			(callback)(response);
		}, null, 15000);
	}

	return{
		addClubMembership:				addClubMembership,
		editClubMembership:				editClubMembership,
		listClubMemberships:			listClubMemberships,
		listClubs:						listClubs,
		listClubCancellationReasons:	listClubCancellationReasons,
		listShippingStrategies:			listShippingStrategies,
		listShippingTypes:				listShippingTypes,
		listPickupLocations:			listPickupLocations,
		viewClubMembership:				viewClubMembership,
		viewClub:						viewClub
	};
});