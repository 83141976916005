pos.customerManager.controller("products", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.products.listProducts";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("listProducts", function($scope, $rootScope, globalData, globalFunctions, customerProductService){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Products";
	$scope.products = {};

	//Functions
	$scope.listProducts = function(page){
		$scope.$emit('formLoading', true);
		customerProductService.listProducts(page, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.products = response.products;
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		globalFunctions.scrollTo('top');
		$scope.listProducts(page);
	}

	//Listeners

	//Logic
	$scope.listProducts(1);
});

