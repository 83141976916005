pos.customerManager.controller("contactTypes", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.contactTypes.listContactTypes";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("listContactTypes", function($scope, $rootScope, globalData, globalFunctions, contactTypeService, $sce){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Contact Types";
	$scope.contactTypes = {};

	//Functions
	$scope.listContactTypes = function(page){
		$scope.$emit('formLoading', true);
		contactTypeService.listContactTypes(page, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.contactTypes = response.contactTypes;
		});
	}

	$scope.editContactType = function(contactType){
		if(contactType.selected == "add" || contactType.selected == 1){
			contactType.selected = "remove";
		}else{
			contactType.selected = "add";
		}

		contactTypeService.editContactType(contactType.contactTypeID, contactType.selected, function(response){
			//Callback
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		globalFunctions.scrollTo('top');
		$scope.listContactTypes(page);
	}

	//Listeners

	//Logic
	$scope.listContactTypes(1);
});