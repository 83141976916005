
pos.store.factory("cartService", function($http, $rootScope, session, globalData, globalFunctions, cartArrayService){

	var init = function(customerID,callback){
		globalFunctions.v65http("/store/index.cfm?method=cart.init",{posProfile:session.posProfile,customerID:customerID},function(response){
			delete session.additionalOrderDataFormData;
			angular.copy(response.order, session.order); 
			angular.copy(response.coupons, session.coupons); 
			angular.copy(response.customer, session.customer); 
			$rootScope.isSplitPayment = 0;
			if(callback)(callback)(response);
		}, null, 60000)
	}

	var addToCart = function(product, callback){
		if (globalData.processingOrder){
			console.log('Prevent ADD to cart while processing');
			(callback)();
			return;
		}

		session.cartItems.push(product);
		cartArrayService.debounceAddToCartArray(callback);
	}
	
	var addScanToCart = function(scanCode, callback){
		if (globalData.processingOrder) {
			console.log('Prevent ADD to cart while processing');
			var response = {};
			response.order = session.order;
			(callback)(response);
			return;
		} else {
			globalFunctions.v65http("/store/index.cfm?method=cart.addScanToCart",{scanCode:scanCode,posProfile:session.posProfile, orderID:session.order.orderHeader.orderID},function(response){
				if(response.order){
					angular.copy(response.order, session.order); 
				}
				(callback)(response);
			}, null, null, 0);
		}
	}

	var addCouponToCart = function(couponCode, callback){
		globalFunctions.v65http("/store/index.cfm?method=cart.addCouponToCart",{couponCode:couponCode,posProfile:session.posProfile,orderID:session.order.orderHeader.orderID},function(response){
			angular.copy(response.order, session.order); 
			(callback)(response);
		}, null, 15000);
	}
	
	var addCouponToRefund = function(couponCode, callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.addCouponToRefund",{couponCode:couponCode,posProfile:session.posProfile,refundOrderID:session.refund.refundHeader.refundOrderID},function(response){
			angular.copy(response.refund, session.refund);
			(callback)(response);
		}, null, 15000);
	}

	var prepareCharge = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=cart.prepareCharge",{posProfile:session.posProfile,orderID:session.order.orderHeader.orderID},function(response){
			angular.copy(response.order, session.order);
			(callback)(response);
		}, null, null, 0);
	}

	var removeItem = function(item, callback){
		globalFunctions.v65http("/store/index.cfm?method=cart.removeItem",{orderID:session.order.orderHeader.orderID,productSKU:item.productSKU,posProfile:session.posProfile},function(response){
			angular.copy(response.order, session.order); 
			(callback)(response);
		}, null, 15000);
	}

	var removeAllCoupons = function(callback){
		globalFunctions.v65http("/store/index.cfm?method=cart.removeAllCoupons",{posProfile:session.posProfile, orderID:session.order.orderHeader.orderID},function(response){
			angular.copy(response.order, session.order); 
			(callback)(response);
		}, null, 15000);
	}
	
	var removeAllCouponsFromRefund = function(callback){
		globalFunctions.v65http("/orders/index.cfm?method=refund.removeAllCoupons",{posProfile:session.posProfile,refundOrderID:session.refund.refundHeader.refundOrderID},function(response){
			angular.copy(response.refund, session.refund);
			(callback)(response);
		}, null, 15000);
	}

	var updateProduct = function(formData, callback){
		globalFunctions.v65http("/store/index.cfm?method=cart.overridePrice",{productSKU:formData.productSKU,quantity:formData.quantity,price:formData.price,posProfile:session.posProfile,orderID:session.order.orderHeader.orderID}, function(response){
			angular.copy(response.order, session.order); 
			(callback)(response);
		}, null, 15000);
	}

	return{
		init: 						init,
		addToCart:					addToCart,
		addScanToCart:				addScanToCart,
		addCouponToCart:			addCouponToCart,
		addCouponToRefund:			addCouponToRefund,
		prepareCharge:				prepareCharge,
		removeItem:					removeItem,
		removeAllCoupons:			removeAllCoupons,
		removeAllCouponsFromRefund:	removeAllCouponsFromRefund,
		updateProduct:				updateProduct
	};
});