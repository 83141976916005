pos.customerManager.controller("dashboard", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.dashboard.viewDashboard";
	$scope.viewRoute = "customerManager.viewCustomer.dashboard.viewCustomer";

	$scope.viewClubMembershipRoute = "customerManager.viewCustomer.clubMemberships.viewClubMembership";
	$scope.viewTaskRoute = "customerManager.viewCustomer.tasks.viewTask";
	$scope.viewOrderRoute = "";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("viewDashboard", function($scope, $rootScope, session, globalData, globalFunctions, dashboardService, clubMembershipService, orderService, taskService, $stateParams, $controller, $state){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "Dashboard";
	$scope.customer = session.customer;
	$scope.clubMembershipsLoading = true;
	$scope.ordersLoading = true;
	$scope.tasksLoading = true;
	$scope.tasks = [];
	$scope.clubMemberships = [];
	$scope.orders = [];

	//Functions
	$scope.listClubs = function(){
		clubMembershipService.listClubMemberships(1, 10, function(response){
			$scope.clubMembershipsLoading = false;
			$scope.clubMemberships = response.clubMemberships;
		});
	}

	$scope.listOrders = function(){
		orderService.listOrders({customerID:$scope.customer.customerID, maxRows:10}, function(response){
			$scope.ordersLoading = false;
			$scope.orders = response.orders;
		});
	}

	$scope.listTasks = function(){
		taskService.listTasks(1, 10,function(response){
			$scope.tasksLoading = false;
			$scope.tasks = response.tasks;
		});
	}


	//Listeners

	//Logic
	$scope.listTasks();
	$scope.listClubs();
	$scope.listOrders();
	$controller('formController', {$scope: $scope}); //Inherits from formController

});


pos.customerManager.controller("editCustomerModal", function($scope, $rootScope, session, globalData, globalFunctions, dashboardService, $controller, $state){
	//Variables
	$scope.modalTitle = "Edit Billing Contact";
	$scope.modalSubmitTitle = "Save";
	$scope.posInfo = globalData.posInfo;
	$scope.modalData;
	$scope.customer = globalFunctions.isRefund() ? session.refund.refundHeader.billCustomer : session.customer;
	$scope.showNonTaxable=session.posProfile.nonTaxableSetting;
	$scope.formData = {};
	$scope.formData.secondaryEmails = [];
	
	if(globalData.posInfo.hasSecondaryEmails){
		$scope.addField=function(){
			if($scope.formData.secondaryEmails.length < 5){
				$scope.formData.secondaryEmails.push({value: '', id: ''});
			}
		}
		
		$scope.deleteField=function(value){
			for(var secondaryEmail in $scope.formData.secondaryEmails){
				if(value.$$hashKey === $scope.formData.secondaryEmails[secondaryEmail].$$hashKey){
					$scope.formData.secondaryEmails.splice(secondaryEmail, 1)
				}
			}
		}
	}	
	
	dashboardService.viewCustomer($scope.customer.customerID, function(response){
		$scope.$emit('modalLoading', false);
		$scope.customer = response.customer;
		$scope.formData = response.customer;
	})

	if($scope.modalData){
		$scope.toBeFilled = $scope.modalData.toBeFilled;
		$scope.$parent.alertMessage = $scope.modalData.alertMessage || "";
		$scope.$parent.alertLevel= $scope.modalData.alertLevel || "";
	}

	//Functions
	$scope.formAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.$emit('modalLoading', true);

		$scope.formData.firstName = $scope.formData.firstName || "";
		$scope.formData.lastName = $scope.formData.lastName || "";
		$scope.formData.phone = $scope.formData.phone || "";
		$scope.formData.smsNumber = $scope.formData.smsNumber || "";
		$scope.formData.company = $scope.formData.company || "";
		$scope.formData.birthDay = $scope.formData.birthDay || "";
		$scope.formData.birthMonth = $scope.formData.birthMonth || "";
		$scope.formData.birthYear = $scope.formData.birthYear || "";
		$scope.formData.email = $scope.formData.email || "";
		$scope.formData.secondaryEmails = $scope.formData.secondaryEmails || [];
		$scope.formData.address = $scope.formData.address || "";
		$scope.formData.address2 = $scope.formData.address2 || "";
		$scope.formData.city = $scope.formData.city || "";
		$scope.formData.countryCode = $scope.formData.countryCode || "";
		$scope.formData.stateCode = $scope.formData.stateCode || "";
		$scope.formData.zipCode = $scope.formData.zipCode || "";
		$scope.formData.username = $scope.formData.username || "";
		$scope.formData.password = $scope.formData.password || "";
		$scope.formData.FBProfileID = $scope.formData.FBProfileID || "";
		$scope.formData.twitterID = $scope.formData.TwitterID || "";
		$scope.formData.salesAssociateID = $scope.formData.salesAssociateID || "";
		$scope.formData.sourceCode = $scope.formData.sourceCode || "";
		$scope.formData.isNonTaxable = $scope.formData.isNonTaxable || "";

		dashboardService.editCustomer($scope.formData.firstName,
			$scope.formData.lastName,
			$scope.formData.phone,
			$scope.formData.smsNumber,
			$scope.formData.company,
			$scope.formData.birthDay,
			$scope.formData.birthMonth,
			$scope.formData.birthYear,
			$scope.formData.email,
			$scope.formData.secondaryEmails,
			$scope.formData.address,
			$scope.formData.address2,
			$scope.formData.city,
			$scope.formData.countryCode,
			$scope.formData.stateCode,
			$scope.formData.zipCode,
			$scope.formData.username,
			$scope.formData.password,
			$scope.formData.sourceCode,
			$scope.formData.isNonTaxable,
			$scope.formData.FBProfileID,
			$scope.formData.twitterID,
			$scope.formData.salesAssociateID,
			$scope.customer.customerID, 
			function(response){
				$scope.$emit('modalLoading', false);
				$scope.closeModal("editedCustomer", response);
			}
		);
	}
	//listeners

	//Logic
	$scope.$emit('modalLoading', true);
});