pos.controller("initiatePaymentTerminalSetup", function($timeout, $scope, $rootScope, session, globalData, globalFunctions, paymentTerminalService, $parse, $sce){
	//Variables
	$scope.modalActionDefaults();
	$scope.modalButtonLoading = false;
	
	$scope.$emit('modalLoading', false);
	$scope.modalTitle = "Setup Terminal";
	$scope.modalSubmitTitle = "Next";
	$scope.modalCancelTitle = "Back";
	$scope.posInfo = globalData.posInfo;
	$scope.terminalData = {};
	
	$scope.getPairingCode = function(){
		$scope.modalActionDefaults($scope.formData);
		paymentTerminalService.getPairingCode($scope.formData.nickname, function(response){
			$scope.terminalData.response = response.terminalData;
			$scope.terminalData.name = $scope.formData.nickname;
			$scope.openModal("v2/views/partials/modals/addPaymentTerminalStep2",$scope.terminalData);
		});
	}
	
	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/deviceSettings");
	};

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.$emit('modalLoading', true);
		$scope.getPairingCode();
	};
});

pos.controller("completeTerminalSetup", function($timeout, $scope, $rootScope, session, globalData, globalFunctions, paymentTerminalService, $parse, $sce, $localStorage){
	//Variables
	$scope.$emit('modalLoading', false);
	$scope.modalTitle = "Setup Terminal";
	$scope.modalSubmitTitle = "Save";
	$scope.modalCancelTitle = "Back";
	$scope.posInfo = globalData.posInfo;
	$scope.terminalData = {};
	$scope.storage = $localStorage;
	
	$scope.modalData;
	$scope.pairingCode = $scope.modalData.response.pairing_code;
	$scope.terminalData.response = $scope.modalData.response;
	
	$scope.getPairingCode = function(){
		$scope.modalActionDefaults($scope.modalData);
		paymentTerminalService.getPairingCode($scope.modalData.name, function(response){
			$scope.terminalData.response = response.terminalData;
			$scope.pairingCode = response.terminalData.pairing_code;
			$scope.$emit('modalLoading', false);
			$scope.modalButtonLoading = false;
		});
	}
	
	$scope.addNewPaymentTerminal = function(){
		$scope.modalActionDefaults($scope.modalData);
		paymentTerminalService.addPaymentTerminal($scope.terminalData.response, function(response){
			// Auto-save payment terminal
			$scope.storage.paymentTerminal={};
			$scope.storage.paymentTerminal.nickName=$scope.terminalData.response.name;
			$scope.storage.paymentTerminal.paymentTerminalKey=$scope.terminalData.response.key;
			$scope.storage.paymentTerminal.paymentTerminalID=response.paymentTerminalID;
			
			$scope.openModal("v2/views/partials/modals/deviceSettings",{paymentTerminalKey:$scope.terminalData.response.key});
			globalFunctions.alertMessage("modal","success","Terminal Has Been Added");
		});
	}
	
	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/addPaymentTerminalStep1");
	};

	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.$emit('modalLoading', true);
		$scope.addNewPaymentTerminal();
	};

});

pos.controller("editPaymentTerminal", function($timeout, $scope, $rootScope, $localStorage, session, globalData, globalFunctions, paymentTerminalService, $parse){
	//Variables
	$scope.$emit('modalLoading', false);
	$scope.modalData;
	$scope.modalTitle = "Edit Payment Terminal";
	$scope.modalSubmitTitle = "Save";
	$scope.modalCancelTitle = "Back";
	$scope.storage = $localStorage;


	//Logic

	//Functions
	
	$scope.closeModal = function(){
		$scope.modalActionDefaults($scope.formData);
		$scope.openModal("v2/views/partials/modals/deviceSettings");
	};

	$scope.deletePaymentTerminal = function(){
		paymentTerminalService.deletePaymentTerminal($scope.formData, function(){
			delete $scope.$storage.paymentTerminal;
			$scope.storage.paymentTerminal = {};
			$scope.openModal("v2/views/partials/modals/deviceSettings");
		});
	};


	$scope.modalAction = function(){
		$scope.modalActionDefaults($scope.formData);
		paymentTerminalService.editPaymentTerminal($scope.formData, function(response){
			$scope.openModal("v2/views/partials/modals/deviceSettings");
		});
	};

});