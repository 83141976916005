pos.customerManager.controller("emails", function($scope, $rootScope, globalData, globalFunctions, $location, $stateParams, $timeout){
	//Variables
	$scope.listRoute = "customerManager.viewCustomer.emails.listEmails";
	$scope.viewRoute = "customerManager.viewCustomer.emails.viewEmail";

	//Functions
	//Listeners
	//Logic
});

pos.customerManager.controller("listEmails", function($scope, $rootScope, globalData, globalFunctions, emailService, $sce){
	//Variables
	$scope.$emit('formLoading', false);
	$rootScope.currentManager.subSectionTitle = "List Emails";
	$scope.emails = {};

	//Functions
	$scope.listEmails = function(page){
		$scope.$emit('formLoading', true);
		emailService.listEmails(page, 100,function(response){
			$scope.$emit('formLoading', false);
			$scope.emails = response.emails;
		});
	}

	$scope.goToPage = function(page){
		$scope.$emit('formLoading', true);
		globalFunctions.scrollTo('top');
		$scope.listEmails(page);
	}

	//Listeners

	//Logic
	$scope.listEmails(1);
});

pos.customerManager.controller("viewEmail", function($scope, $rootScope, globalData, globalFunctions, emailService, $stateParams, $controller, $state, $sce){
	//Variables
	$scope.$emit('formLoading', true);
	$rootScope.currentManager.subSectionTitle = "View Email";
	$scope.email = {};

	//Functions
	$scope.resendEmail = function(){
		$scope.$emit('formLoading', true);
		emailService.resendEmail($stateParams.emailDocumentSentID, function(){
			$scope.$emit('formLoading', false);
			$state.go($scope.listRoute);
		});
	}

	//Listeners

	//Logic
	$controller('formController', {$scope: $scope}); //Inherits from formController

	emailService.viewEmail($stateParams.emailDocumentSentID, function(response){
		$scope.$emit('formLoading', false);
		$scope.email = response.email;
		$scope.formData = response.email;
	});
});
